import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChild,
    faCity,
    faGlobeEurope,
    faIdBadge,
    faMailBulk,
    faMapMarkerAlt,
    faPhone,
    faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import LoggedComponent, {mapStateToProps} from "../../Services/ConnexionService";
import FilAriane from "../Header_Footer/FilAriane";
import globalStyles from "../../Styles/Style.module.css";
import formStyles from '../../Styles/FormStyle.module.css';
import {connect} from "react-redux";
import Bandeau from "../Header_Footer/Bandeau";
import titleAriane from "../../Helpers/TitleAriane.json";

class EditAddress extends LoggedComponent {

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            id: props.id,
            lastName: '',
            firstName: '',
            name: '',
            type: '',
            address: '',
            address2: '',
            postalCode: '',
            city: '',
            country: '',
            phone: '',
            errors : {
                mail: null,
                phone: false,
                cannotUseMail: false
            },
            countries: undefined
        };
        this.isLoading = true;
        this.loadingCountries = true;
    }

    getCountries() {
        let request = new Request(process.env.REACT_APP_API_URL + '/client/countries', {method:'GET', credentials:'include'});
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({countries: data.countries})
            this.loadingCountries = false;
            this.forceUpdate();
        })
    }

    doesMustLog = () => {
        this.mustLog = 1
    }

    edit = () => {
        let data = new FormData();
        data.append('json', JSON.stringify({...this.state, email: this.state.mail, userId: this.state.id}));
        let request = new Request(process.env.REACT_APP_API_URL + '/client/user/address/edit/' + this.state.id, {method:'POST', body: data, credentials:'include'});
        this.fetchJsonOrError(request, (data) => {
            if(data["edited"]){
                this.setState({shouldNavigate: true, navigateTo: '/address'});
            }
        })

    }


    renderChild() {

        if(this.loadingCountries) this.getCountries()


        if(this.isLoading){
            for (let i in this.state.user.addresses) {
                let address = this.state.user.addresses[i];
                if (address.id === this.state.id) {
                    this.setState({
                        name: address.name,
                        type: address.type,
                        firstName: address.firstName,
                        lastName: address.lastName,
                        address: address.streetAddress,
                        address2: address.streetAddressMore,
                        city: address.city,
                        postalCode: address.postalCode,
                        country: address.country,
                        phone: address.phone ? address.phone : ''
                    });
                }
            }
            this.isLoading = false;
            this.forceUpdate();
        }


        const formValid = () => {
            let infoShipping = (this.state.lastName.length > 0) && (this.state.firstName.length > 0) && (this.state.address.length > 0) && (this.state.city.length > 0) && (this.state.country.length > 0) && (!this.state.errors.phone);
            return infoShipping;
        }

        return (
            <div>
                <Bandeau title={titleAriane["editAddress"]["title"].replace("_name_", this.state.name)}/>
                <FilAriane
                    links={[
                        [
                            titleAriane["editAddress"]["ariane"][0],
                            "/"
                        ],
                        [
                            titleAriane["editAddress"]["ariane"][1],
                            "/address"
                        ],
                        [
                            titleAriane["editAddress"]["ariane"][2],
                            ""
                        ]
                    ]}
                />

                <div className={globalStyles.globalContainer}>
                    <div style={{flex: 1}}>
                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroup}>
                                <label>
                                    Nom de l'adresse*
                                    <FontAwesomeIcon
                                        className={formStyles.iconHelp}
                                        icon={faQuestionCircle}
                                        tabIndex={-1}
                                        data-tip="Afin de mieux t'y retrouver, tu peux donner un nom à cette adresse."
                                    />
                                </label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faMapMarkerAlt}/>
                                    </div>
                                    <input type="text" required className={formStyles.input}
                                           placeholder="Nom de l'adresse" value={this.state.name}
                                           onChange={(event) => this.setState({name: event.target.value})}
                                    />

                                </div>
                            </div>
                        </div>

                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroup}>
                                <label>
                                    Nom*
                                    <FontAwesomeIcon
                                        className={formStyles.iconHelp}
                                        icon={faQuestionCircle}
                                        data-tip="A qui sera adressé le colis ?"
                                        tabIndex={-1}
                                    />
                                </label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faIdBadge}/>
                                    </div>
                                    <input type="text" required className={formStyles.input}
                                           placeholder="Nom du destinataire" value={this.state.lastName}
                                           onChange={(event) => this.setState({lastName: event.target.value})}/>

                                </div>
                            </div>

                            <div className={formStyles.inputGroup}>
                                <label>
                                    Prénom*
                                    <FontAwesomeIcon
                                        className={formStyles.iconHelp}
                                        icon={faQuestionCircle}
                                        data-tip="A qui sera adressé le colis ?"
                                        tabIndex={-1}
                                    />
                                </label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faChild}/>
                                    </div>
                                    <input type="text" required className={formStyles.input}
                                           placeholder="Prénom du destinataire" value={this.state.firstName}
                                           onChange={(event) => this.setState({firstName: event.target.value})}/>
                                </div>
                            </div>
                        </div>

                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroup}>
                                <label>
                                    Adresse*
                                    <FontAwesomeIcon
                                        className={formStyles.iconHelp}
                                        icon={faQuestionCircle}
                                        data-tip="Où sera livré le colis ?"
                                        tabIndex={-1}
                                    />
                                </label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faMapMarkerAlt}/>
                                    </div>
                                    <input type="text" required className={formStyles.input}
                                           placeholder="Adresse de livraison" value={this.state.address}
                                           onChange={(event) => this.setState({address: event.target.value})}/>
                                </div>
                            </div>

                            <div className={formStyles.inputGroup}>
                                <label>
                                    Complément d'adresse
                                    <FontAwesomeIcon className={formStyles.iconHelp} icon={faQuestionCircle}
                                                     data-tip="Cela permettera de faciliter la livraison."
                                                     tabIndex={-1}/>
                                </label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faMapMarkerAlt}/>
                                    </div>
                                    <input type="text" required className={formStyles.input}
                                           placeholder="Étage, Bâtiment .." value={this.state.address2}
                                           onChange={(event) => this.setState({address2: event.target.value})}/>
                                </div>
                            </div>
                        </div>

                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroup}>
                                <label>
                                    Code postal
                                </label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faMailBulk}/>
                                    </div>
                                    <input type="text" maxLength={5} required className={formStyles.input}
                                           placeholder="00000" value={this.state.postalCode}
                                           onChange={(event) => this.setState({postalCode: event.target.value})}/>
                                </div>
                            </div>

                            <div className={formStyles.inputGroup}>
                                <label>Ville*</label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faCity}/>
                                    </div>
                                    <input type="text" required className={formStyles.input} placeholder="Ville"
                                           value={this.state.city}
                                           onChange={(event) => this.setState({city: event.target.value})}/>
                                </div>
                            </div>
                        </div>

                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroup}>
                                <label>
                                    Pays
                                    {this.state.type === 0 &&
                                        <FontAwesomeIcon className={formStyles.iconHelp} icon={faQuestionCircle}
                                                         data-tip={"Nous ne livrons que dans les pays suivants : " + this.state.countries?.map(country => country.label)?.join(',')}
                                                         tabIndex={-1}/>
                                    }
                                </label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faGlobeEurope}/>
                                    </div>
                                    {this.state.type !== 0 ?
                                        <input type="text" required className={formStyles.input}
                                               placeholder="Pays" value={this.state.country}
                                               onChange={(event) => this.setState({country: event.target.value})}/>
                                        :
                                        <select value={this.state.country} className={formStyles.input} onChange={(event) => this.setState({country: event.target.value})}>
                                            {
                                                this.state.countries?.map(country =>
                                                    <option value={country.label}>
                                                        {country.label}
                                                    </option>
                                                )
                                            }
                                        </select>
                                    }
                                </div>
                            </div>

                            <div className={formStyles.inputGroup}>
                                {this.state.type === 0 &&
                                    <div>
                                        <label>
                                            Numéro de téléphone
                                            <FontAwesomeIcon className={formStyles.iconHelp} icon={faQuestionCircle} tabIndex={-1}
                                                             data-tip="Cela permettera de faciliter la livraison."/>
                                        </label>
                                        <div className={formStyles.inputField}>
                                            <div className={formStyles.inputIcon}>
                                                <FontAwesomeIcon icon={faPhone}/>
                                            </div>
                                            <input
                                                type="tel"
                                                required
                                                className={formStyles.input}
                                                placeholder="0600000000"
                                                value={this.state.phone}
                                                pattern={
                                                    this.state.countries?.map(country =>
                                                        country.phoneRegex
                                                    )?.join('|')
                                                }
                                            />
                                        </div>

                                        {this.state.errors.phone &&
                                            <span className={formStyles.textDanger}>Le numéro de téléphone doit être valide.</span>
                                        }
                                    </div>
                                }

                            </div>
                        </div>

                        <div className={formStyles.btnContainer} style={{marginTop: "20px"}}>
                            <button type="button" className={formValid() ? globalStyles.defaultBtn : formStyles.disabledBtn} onClick={() => this.edit()} disabled={!formValid()}>
                                Enregistrer
                            </button>
                        </div>

                    </div>
                </div>
                <ReactTooltip multiline={true}/>
            </div>
        );
    };

}

export default connect(mapStateToProps)(EditAddress);
