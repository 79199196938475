import React from "react"
import styles from "./ListPoints.module.css"
import CardTemplateListPoints from "./CardTemplateListPoints";

class ListPoints extends React.Component {

    constructor(props) {
        super(props);
        this.ref = {}
        this.listRef = React.createRef();
        this.state = {
            lastSelectedIndex: -1,
        };
    }

    formateString(string){
        const arr = string.split(" ");
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
        }
        return arr.join(" ")
    }

    scrollToSelected = (index) => {
        const container = this.listRef.current;
        if (container) {
            const selectedElement = container.children[index];
            if (selectedElement) {
                const elementHeight = selectedElement.clientHeight;
                const scrollPosition = elementHeight * index;
                container.scrollTo({
                    top: scrollPosition - (container.clientHeight / 2) + (elementHeight / 2),
                    behavior: 'smooth'
                });
            }
        }
    };
    
    componentDidUpdate() {
        const currentlySelected = this.props.listpoints.findIndex(point => point.selected);
        if (currentlySelected !== this.state.lastSelectedIndex && currentlySelected !== -1) {
            this.scrollToSelected(currentlySelected);
            this.setState({ lastSelectedIndex: currentlySelected });
        }
    }

    displayListPoints() {
        let points = [];
        for(let i in this.props.listpoints){
            points.push(
                <div
                    key={i}
                    style={{
                        width: "calc(100% - 10px)",
                        display: "flex",
                        flexDirection: "row",
                        marginRight: "10px"
                    }}
                >
                    <CardTemplateListPoints
                        title={this.props.listpoints[i].name}
                        address={this.props.listpoints[i].address}
                        city={this.props.listpoints[i].zipcode + " " + this.props.listpoints[i].city}
                        country={this.props.listpoints[i].country}
                        carrier={this.props.listpoints[i].carrier}
                        schedule={this.props.listpoints[i].schedule}
                        selected={this.props.listpoints[i].selected}
                        code={this.props.listpoints[i].code}
                        select={this.props.select.bind(this)}
                        beforeSelect={(parseInt(i) + 1) < this.props.listpoints.length && this.props.listpoints[parseInt(i) + 1].selected}
                    />
                </div>
            );
        }
        return points
    }

    render() {
        if(this.props.listpoints !== undefined){
            return(
                <div
                    ref={this.listRef}
                    className={styles.listpoints}
                    style={{
                        flex: 1,
                        maxHeight: "500px",
                        overflowY: "scroll",
                        marginRight: window.innerWidth > 1020 ? "15px" : undefined,
                    }}>
                    {this.displayListPoints()}
                </div>
            )

        }
        return (
            <div>
            </div>
        );
    }
}

export default ListPoints;
