import React, {useState} from "react";
import {Navigate} from "react-router-dom";
import Empty from "../Empty";
import Cookies from 'universal-cookie';

const cookieOptions = {maxAge: `${process.env.REACT_APP_COOKIE_STANDARD_MAX_AGE}`, path: '/', domain: `${process.env.REACT_APP_COOKIE_DOMAIN}`}
const cookies = new Cookies(null, cookieOptions);

function Logout (props) {

    let [canGo, setGo] = useState(false)

    let request = new Request(process.env.REACT_APP_API_URL + '/client/logout', {credentials: 'include'})
    fetch(request).then((response) => {
        setGo(true)
        if(cookies.get('connected')){
            cookies.remove('connected');
        }
        if(cookies.get('basketQuantity')){
            cookies.remove('basketQuantity');
        }
        localStorage.removeItem('fromValidateBasket');
        
    })

    if(canGo) {
        return (
            <Navigate to={"/login"}/>
        )
    }
    return (
        <Empty/>
    )
}

export default Logout;
