import React from "react";
import globalStyles from '../../../Styles/Style.module.css';
import styles from "./ListAddress.module.css";
import CardTemplateAddress from "../../Card/CardTemplateAddress";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {encode} from "../../../Services/ObfuscatorService";


function ListAddress(props) {

    const displayAddresses = (nbAddresses, type) => {
        let addresses = []
        let typeAddresses = []
        for (let c in props.state.user.addresses) {
            let address = props.state.user.addresses[c]
            if (address.type === type) {
                if(address.default){
                    typeAddresses.unshift(address)
                } else {
                    typeAddresses.push(address)
                }
            }
        }
        let missing = nbAddresses - (typeAddresses.length % nbAddresses);
        let currentLine = 0;
        for (let c in typeAddresses) {
            if (c >= nbAddresses*currentLine + nbAddresses){
                addresses.push(<div key={'first'+c} className={styles.break}/>)
                currentLine++;
            }

            let address = typeAddresses[c]

            addresses.push(
                <div key={c} className={styles.childCard} style={{
                    marginLeft: c%nbAddresses === 0 || window.innerWidth < 1020  ? 0 : "10px",
                    marginRight: c%nbAddresses === nbAddresses - 1 || window.innerWidth < 1020 ? "0" : "10px",
                }}>
                    <CardTemplateAddress
                        id={address.id}
                        name={address.name}
                        firstName={address.firstName}
                        lastName={address.lastName}
                        streetAddress={address.streetAddress}
                        streetAddressMore={address.streetAddressMore}
                        postalCode={address.postalCode}
                        city={address.city}
                        country={address.country}
                        phone={address.phone}
                        default={address.default}
                        setDefault={setDefault.bind(this)}
                        delete={deleteAddress.bind(this)}
                        choose={props.choose}
                        links={props.choose ? undefined : [["Modifier", "link", "/address/" + encode(address.id) + "/edit"]]}
                        chooseFtn={props.chooseFtn ? props.chooseFtn.bind(this) : undefined}
                        choosen={props.choose ? props.state.address.id === address.id : undefined}
                        color={props.color ? props.color : "#f3f6f8"}
                    />
                </div>
            )

        }


        if(missing === nbAddresses || nbAddresses === 1){
            addresses.push(<div key="last" className={styles.break}/>)
            missing = nbAddresses;
        }

        addresses.push(
            <div key="add" className={styles.childCard} style={{
                marginLeft: missing === nbAddresses ? "0" : "10px",
                marginRight: missing === 1 ? "0" : "10px",
            }}>
                <div className={globalStyles.card} style={{padding: "20px", flex: 1, backgroundColor: "#FFFFFF", display: "flex", flexDirection: "column"}}>
                    <h3 style={{margin: "10px 0"}}>
                        <FontAwesomeIcon icon={faPlus} style={{marginRight: "10px"}}/>
                        Nouvelle adresse
                    </h3>

                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", flex: 1, marginTop: "50px", marginBottom: "50px"}}>
                        {props.add[0] === "btn" &&
                            <button className={globalStyles.defaultBtn}  onClick={() => props.add[1]()}>
                                Ajouter
                            </button>
                        }
                        {props.add[0] === "link" &&
                            <a className={globalStyles.defaultBtn}  href={props.add[1]}>
                                Ajouter
                            </a>
                        }

                    </div>
                </div>
            </div>
        )

        missing--;

        for(let i = 0; i < missing; i++){
            addresses.push(
                <div key={'miss'+i} style={{flex: 1, display: "flex",  flexDirection: "row", margin: "10px"}}>
                </div>
            )
        }
        return addresses
    }

    const setDefault = (id) => {
        let request = new Request(process.env.REACT_APP_API_URL + '/client/user/address/default/' + id, {method:'POST', credentials:'include'});
        props.fetchJsonOrError(request, (data) => {
            if(data["edited"]){
                window.location.reload();
            }
        })
    }

    const deleteAddress = (id) => {
        let request = new Request(process.env.REACT_APP_API_URL + '/client/user/address/delete/' + id, {method:'POST', credentials:'include'});
        props.fetchJsonOrError(request, (data) => {
            if(data["deleted"]){
                window.location.reload();
            }
        })
    }

    return (
        <div style={{width: "100%"}}>
            <h2 className={styles.title} style={{margin: window.innerWidth > 1020 ? undefined : "10px"}}>
                {props.iconTitle &&
                    <FontAwesomeIcon icon={props.iconTitle} style={{marginRight: "15px"}}/>
                }
                {props.title}
            </h2>

            <div className={styles.cardContainer} style={{flexWrap: "wrap", margin: "auto"}}>
                {window.innerWidth > 1020 ?
                    displayAddresses(props.desktopNumber, props.type) :
                    displayAddresses(props.mobileNumber, props.type)
                }
            </div>
        </div>
    );
}

export default ListAddress;
