import React from "react"
import LoggedComponent, { mapStateToProps } from "../../Services/ConnexionService";
import globalStyles from '../../Styles/Style.module.css';
import {
    faBoxOpen, faCity, faGlobeEurope, faMailBulk, faMapMarkerAlt,
    faStore, faTimes,
    faTruck
} from "@fortawesome/free-solid-svg-icons";
import FilAriane from "../Header_Footer/FilAriane";
import { connect } from "react-redux";
import Bandeau from "../Header_Footer/Bandeau";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { encode } from "../../Services/ObfuscatorService";
import CardMiniChildCommitment from "./CardMiniChildCommitment";
import CardChoosenBox from "./CardChoosenBox";
import formStyles from "../../Styles/FormStyle.module.css";
import styles from "../Shopping/Delivery/Delivery.module.css";
import ListAddress from "../Utils/Address/ListAddress";
import Modal from "react-modal";
import AddAddressDelivery from "../Shopping/Delivery/AddAdressDelivery";
import ListPoints from "../Shopping/Delivery/ListPoints";
import MapPoints from "../Shopping/Delivery/MapPoints";
import carriers from "../../Helpers/carriers.json";

const titleAriane = require("../../Helpers/TitleAriane.json");

class CommitmentChoose extends LoggedComponent {

    constructor(props) {
        super(props);
        this.toSend = {
            articles: {},
            delivery: {},
        }
        this.state = {
            order: {},
            postalCode: null,
            city: null,
            country: null,
            address: {},
            listpoints: [],
            selectedPoint: undefined,
            countries: undefined
        }
        this.loadingOrder = true;
        this.loading = true;
        this.loadingPts = true;
        this.reloadRelay = true;
        this.isOpenDelivery = false;
        this.isOpenModalAdd = false;
        this.loadingCountries = true;
    }

    doesMustLog = () => {
        this.mustLog = 1
    }

    getCountries() {
        let request = new Request(process.env.REACT_APP_API_URL + '/client/countries', { method: 'GET', credentials: 'include' });
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({ countries: data.countries })
            this.loadingCountries = false;
            this.forceUpdate();
        })
    }

    getOrderInfos() {
        let request = new Request(process.env.REACT_APP_API_URL + '/client/commitment/order/' + this.props.id, { method: 'GET', credentials: 'include' });
        this.fetchJsonOrError(request, (data) => {
            if (data.delivery.type === 1) {
                this.loadingOrder = false;
            } else {
                this.loading = false;
            }
            this.setState({ order: data, country: data.country });
        })
    }

    submitAndRefresh() {
        if (Object.keys(this.toSend.delivery).length > 0 || Object.keys(this.toSend.articles).length > 0) {
            let data = new FormData();
            data.append('json', JSON.stringify(this.toSend))
            let request = new Request(process.env.REACT_APP_API_URL + '/client/commitment/choose/' + this.props.id, { method: 'POST', body: data, credentials: 'include' });
            this.fetchJsonOrError(request, (data) => {
                if (data.success) {
                    this.addFlash("success", "Ton choix a bien été pris en compte");
                    this.hasdisplayedFlash = false;
                    this.toSend = {
                        articles: {},
                        delivery: {},
                    }
                    this.getOrderInfos();
                    this.hydrateDisplayFlash();
                    this.forceUpdate();
                }
            })
        }
    }


    getListPoints() {
        if (this.state.order.relay) {
            if (Object.keys(this.state.order.relay.carriers).length > 0 && this.state.city && this.state.postalCode && this.state.country) {
                let data = new FormData();
                data.append('json', JSON.stringify({ carriers: this.state.order.relay.carriers }));
                let request = new Request(process.env.REACT_APP_API_URL + '/client/delivery/listpoints/' + this.state.city + "/" + this.state.postalCode + "/" + this.state.country, { method: 'POST', body: data, credentials: 'include' });
                fetch(request).then((response) => {
                    return response.json()
                }).then((data) => {
                    let points = []
                    var coordsRef = [0, 0];
                    if (data.carrier instanceof Array) {
                        coordsRef = [0, 0];
                        let count = 0;
                        for (let carrier in data.carrier) {
                            if (data.carrier[carrier].points.point) {
                                coordsRef[0] += parseFloat(data.carrier[carrier].points.point[0].latitude);
                                coordsRef[1] += parseFloat(data.carrier[carrier].points.point[0].longitude);
                                count++;
                            }
                        }

                        coordsRef[0] /= count;
                        coordsRef[1] /= count;

                        for (let carrier in data.carrier) {
                            let carrier_code = data.carrier[carrier].operator;
                            if (data.carrier[carrier].points.point) {
                                for (let i = 0; i < data.carrier[carrier].points.point.length; i++) {
                                    points.push(
                                        {
                                            name: data.carrier[carrier].points.point[i].name,
                                            carrier: carriers[carrier_code].img,
                                            address: data.carrier[carrier].points.point[i].address,
                                            zipcode: data.carrier[carrier].points.point[i].zipcode,
                                            city: data.carrier[carrier].points.point[i].city,
                                            country: data.carrier[carrier].points.point[i].country,
                                            schedule: data.carrier[carrier].points.point[i].schedule,
                                            code: data.carrier[carrier].points.point[i].code,
                                            latitude: data.carrier[carrier].points.point[i].latitude,
                                            longitude: data.carrier[carrier].points.point[i].longitude,
                                            selected: false
                                        }
                                    )
                                }
                            }
                        }
                    } else {
                        let carrier_code = data.carrier.operator;
                        coordsRef = [data.carrier.points.point[0].latitude, data.carrier.points.point[0].longitude];
                        for (let i = 0; i < data.carrier.points.point.length; i++) {
                            points.push(
                                {
                                    name: data.carrier.points.point[i].name,
                                    carrier: carriers[carrier_code].img,
                                    address: data.carrier.points.point[i].address,
                                    zipcode: data.carrier.points.point[i].zipcode,
                                    city: data.carrier.points.point[i].city,
                                    country: data.carrier.points.point[i].country,
                                    schedule: data.carrier.points.point[i].schedule,
                                    code: data.carrier.points.point[i].code,
                                    latitude: data.carrier.points.point[i].latitude,
                                    longitude: data.carrier.points.point[i].longitude,
                                    selected: false
                                }
                            )
                        }
                    }
                    points.sort(function (a, b) {
                        let distA = (a.latitude - coordsRef[0]) ** 2 + (a.longitude - coordsRef[1]) ** 2;
                        let distB = (b.latitude - coordsRef[0]) ** 2 + (b.longitude - coordsRef[1]) ** 2;
                        return distA - distB;
                    })
                    this.setState({ listpoints: points });
                    this.loading = false;
                    this.reloadRelay = false;
                    this.forceUpdate();
                })
            } else {
                this.loading = false;
                this.reloadRelay = false;
                this.forceUpdate();
            }
        }

    }

    send = () => {
    }

    setBox(oaid, reference) {
        this.toSend.articles[oaid] = reference

        this.submitAndRefresh()
    }


    setAddress = (id, firstName, lastName, streetAddress, streetAddressMore, postalCode, city, country, phone) => {
        let addressCall = {
            id: id,
            firstName: firstName,
            lastName: lastName,
            streetAddress: streetAddress,
            streetAddressMore: streetAddressMore,
            postalCode: postalCode,
            city: city,
            country: country,
            phone: phone
        }
        this.setState({
            address: addressCall,
        })
        this.toSend.delivery = addressCall;
    }

    setRelay = (newListpoint) => {
        let addressRelay = {
            relayName: newListpoint.name,
            streetAddress: newListpoint.address,
            postalCode: newListpoint.zipcode,
            city: newListpoint.city,
            country: newListpoint.country,
            relayPoint: newListpoint.code
        }
        this.setState({
            address: addressRelay,
        })
        this.toSend.delivery = addressRelay;
        this.forceUpdate();
    }

    selected = (code) => {
        let newListpoint = this.state.listpoints;
        let newSelectedPoint = {};
        for (let i in newListpoint) {
            newListpoint[i].selected = (newListpoint[i].code === code);
            if (newListpoint[i].code === code) {
                newSelectedPoint = newListpoint[i]
            }
        }
        this.setState({
            listpoints: newListpoint,
            selectedPoint: newSelectedPoint
        });
        this.setRelay(newSelectedPoint);
    }

    setBoxInfos(ref, name) { }

    openDelivery() {
        this.isOpenDelivery = true;
        this.forceUpdate()
    }

    closeDelivery = () => {
        this.isOpenDelivery = false;
        this.forceUpdate();
        this.submitAndRefresh()
    }

    openModalAdd() {
        this.isOpenModalAdd = true;
        this.forceUpdate()
    }

    closeModalAdd() {
        this.isOpenModalAdd = false;
        this.forceUpdate()
    }

    displayArticles() {
        let result = []
        for (let i in this.state.order.articles) {
            let article = this.state.order.articles[i].article
            let username = this.state.order.articles[i].userFirstName
            let commitment = this.state.order.articles[i].fromCommitment

            result.push(
                <div style={{ display: "flex", flexDirection: window.innerWidth > 960 ? "row" : "column" }}>
                    <CardMiniChildCommitment
                        name={username}
                        color={commitment.color}
                        commitment={commitment.name}
                        style={{
                            flex: 1,
                            height: "auto",
                            margin: 10,
                            marginLeft: window.innerWidth > 960 ? "10px" : 0,
                            marginRight: window.innerWidth > 960 ? "10px" : 0,
                            marginTop: window.innerWidth > 960 ? "10px" : "20px",
                        }}
                    />
                    <CardChoosenBox
                        icon={faBoxOpen}
                        title={article.name}
                        theme={article.theme}
                        themeColor={article.themeColor}
                        themeBaseColor={article.themeBaseColor}
                        secondaryTheme={article.secondaryTheme}
                        secondaryThemeColor={article.secondaryThemeColor}
                        secondaryThemeBaseColor={article.secondaryThemeBaseColor}
                        age={article.age}
                        img={article.media[0]}
                        infos={[article.description]}
                        stock={article.stock}
                        quantity={1}
                        style={{
                            flex: 3,
                            height: "auto",
                            margin: 10,
                            marginLeft: window.innerWidth > 960 ? "10px" : 0,
                            marginRight: window.innerWidth > 960 ? "10px" : 0,
                        }}
                        needChoose={commitment.needChooseBox}
                        oaid={this.state.order.articles[i].id}
                        setBox={this.setBox.bind(this)}
                        setBoxInfos={this.setBoxInfos.bind(this)}
                        dispatch={this.props.dispatch.bind(this)}
                    />
                </div>
            )
        }
        return result
    }

    displayDelivery = () => {
        let icon = [
            <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: "15px" }} />,
            <FontAwesomeIcon icon={faStore} style={{ marginRight: "15px" }} />,
            <FontAwesomeIcon icon={faTruck} style={{ marginRight: "15px" }} />,
        ][this.state.order.delivery.type];
        let name = ["Retrait sur rendez-vous", "En point relais", "A domicile"][this.state.order.delivery.type];

        return (
            <div
                className={globalStyles.card}
                style={{
                    margin: "10px",
                    marginLeft: window.innerWidth > 960 ? "10px" : 0,
                    marginRight: window.innerWidth > 960 ? "10px" : 0,
                    padding: 30
                }}
            >
                <h2 style={{ margin: "0 0 15px 0" }}>{icon} {name}</h2>
                {this.state.order.delivery.type !== 0 &&
                    <div>
                        <p style={{ margin: 0, marginLeft: 10 }}>{this.state.order.delivery.firstName} {this.state.order.delivery.lastName}</p>
                        {this.state.order.delivery.type === 1 &&
                            <p style={{
                                margin: 0,
                                marginLeft: 10
                            }}><b>{this.state.order.delivery.relayName}</b></p>
                        }
                        <p style={{ margin: 0, marginLeft: 10 }}>{this.state.order.delivery.address}{this.state.order.delivery.addressMore ? (this.state.order.delivery.addressMore !== "" && "; " + this.state.order.delivery.addressMore) : ""}</p>
                        <p style={{ margin: 0, marginLeft: 10 }}>{this.state.order.delivery.postCode} {this.state.order.delivery.city}, {this.state.order.delivery.country}</p>


                        <div style={{ flex: 1 }}>
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                            }}>
                                <button className={globalStyles.defaultLink} style={{ marginTop: "15px" }} onClick={this.openDelivery.bind(this)}>
                                    Changer
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }

    renderChild() {
        if (this.loadingCountries) this.getCountries()

            return (
                <div>
                    <Bandeau title={titleAriane["commitmentChoose"]["title"].replace("_number_", this.state.order.initialOrder.identifier)} />

                    <FilAriane
                        links={[
                            [
                                titleAriane["commitmentChoose"]["ariane"][0],
                                "/"
                            ],
                            [
                                titleAriane["commitmentChoose"]["ariane"][1],
                                "/commands"
                            ],
                            [
                                titleAriane["commitmentChoose"]["ariane"][2].replace("_number_", this.state.order.initialOrder.identifier),
                                "/command/" + encode(this.state.order.initialOrder.id) + "/view"
                            ],
                            [
                                titleAriane["commitmentChoose"]["ariane"][3],
                                ""
                            ]
                        ]}
                    />

                    {this.loading ? (
                        <>
                            {!this.loadingOrder ?? this.getOrderInfos()}
                            {!this.loadingOrder ?? this.getListPoints()}
                            <div className="loader"><div></div></div>
                        </>)
                        : <>

                            <div className={globalStyles.globalContainer} style={{ flexDirection: "column", alignItems: "unset", padding: window.innerWidth > 960 ? "unset" : "10px" }}>
                                {this.displayArticles()}

                                {this.displayDelivery()}
                            </div>

                            <Modal
                                isOpen={this.isOpenDelivery}
                                contentLabel="Adresse de livraison"
                                bodyOpenClassName={"noScroll"}
                                style={{
                                    content: {
                                        top: '50%',
                                        left: '50%',
                                        right: 'auto',
                                        bottom: 'auto',
                                        transform: 'translate(-50%, -50%)',
                                        zIndex: 500,
                                        width: window.innerWidth > 1020 ? "70%" : "calc(100% - 60px)",
                                        padding: window.innerWidth > 1020 ? "40px" : "20px",
                                        paddingTop: "20px",
                                        maxHeight: window.innerWidth > 960 ? (window.innerHeight - 180) + "px" : (window.innerHeight - 80) + "px",
                                        borderRadius: "15px"
                                    },
                                    overlay: {
                                        zIndex: 1500,
                                        backgroundColor: "rgba(0, 0, 0, 0.8)"
                                    }
                                }}
                            >
                                <button
                                    style={{
                                        position: "absolute",
                                        top: "20px",
                                        right: "20px",
                                        background: "none",
                                        border: "none",
                                        fontSize: "20px",
                                        cursor: "pointer"
                                    }}
                                    onClick={() => { this.closeDelivery() }}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>

                                {this.state.order.delivery.type === 2 && // HOME
                                    <div>
                                        <div className={styles.cardContainer}>
                                            <div
                                                className={globalStyles.card}
                                                style={{
                                                    padding: "20px 30px 30px",
                                                    flex: 1,
                                                    backgroundColor: "#f3f6f8",
                                                    boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                                    borderRadius: "5px",
                                                    display: "flex",
                                                    height: "100%",
                                                    boxSizing: "border-box",
                                                    flexDirection: "column"
                                                }}
                                            >
                                                <ListAddress
                                                    title={"Tes adresses"}
                                                    desktopNumber={3}
                                                    mobileNumber={1}
                                                    type={0}
                                                    state={this.state}
                                                    fetchJsonOrError={this.fetchJsonOrError}
                                                    add={["btn", this.openModalAdd.bind(this)]}
                                                    choose={true}
                                                    chooseFtn={this.setAddress.bind(this)}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                }

                                {this.state.order.delivery.type === 1 && // RELAIS
                                    <div className={styles.cardContainer}>
                                        <div
                                            className={globalStyles.card}
                                            style={{
                                                padding: "30px",
                                                flex: 1,
                                                backgroundColor: "#f3f6f8",
                                                boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                                borderRadius: "5px",
                                                display: "flex",
                                                height: "100%",
                                                boxSizing: "border-box",
                                                flexDirection: "column"
                                            }}
                                        >
                                            <div style={{ flex: 5 }}>

                                                <div style={{ flexDirection: "column", marginTop: "15px", marginBottom: "15px" }}>

                                                    <div className={formStyles.inputLine} style={{ marginBottom: "10px" }}>
                                                        <div className={formStyles.inputGroup} style={{ marginLeft: 0, marginRight: window.innerWidth < 1020 ? 0 : undefined }}>
                                                            <label>
                                                                Code postal*
                                                            </label>
                                                            <div className={formStyles.inputField}>
                                                                <div className={formStyles.inputIcon}>
                                                                    <FontAwesomeIcon icon={faMailBulk} />
                                                                </div>
                                                                <input type="text" required className={formStyles.input}
                                                                    placeholder="00000" value={this.state.postalCode}
                                                                    onChange={(event) => this.setState({ postalCode: event.target.value })}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div
                                                            className={formStyles.inputGroup}
                                                            style={{
                                                                marginLeft: window.innerWidth < 1020 ? 0 : undefined,
                                                                marginRight: window.innerWidth < 1020 ? 0 : undefined
                                                            }}
                                                        >
                                                            <label>Ville*</label>
                                                            <div className={formStyles.inputField}>
                                                                <div className={formStyles.inputIcon}>
                                                                    <FontAwesomeIcon icon={faCity} />
                                                                </div>
                                                                <input type="text" required className={formStyles.input}
                                                                    placeholder="Ville"
                                                                    value={this.state.city}
                                                                    onChange={(event) => this.setState({ city: event.target.value })} />
                                                            </div>
                                                        </div>

                                                        <div
                                                            className={formStyles.inputGroup}
                                                            style={{
                                                                marginLeft: window.innerWidth < 1020 ? 0 : undefined,
                                                                marginRight: window.innerWidth < 1020 ? 0 : undefined
                                                            }}
                                                        >
                                                            <label>Pays*</label>
                                                            <div className={formStyles.inputField}>
                                                                <div className={formStyles.inputIcon}>
                                                                    <FontAwesomeIcon icon={faGlobeEurope} />
                                                                </div>
                                                                <input value={this.state.country} className={formStyles.input} disabled />
                                                            </div>
                                                        </div>


                                                        <div style={{
                                                            display: "flex",
                                                            alignItems: "flex-end",
                                                            justifyContent: "flex-end",
                                                            paddingBottom: "10px"
                                                        }}>
                                                            <button
                                                                className={this.state.postalCode && this.state.city && this.state.country ? globalStyles.defaultBtn : formStyles.disabledBtn}
                                                                disabled={!(this.state.postalCode && this.state.city && this.state.country)}
                                                                style={{ padding: "10px 20px" }}
                                                                onClick={() => {
                                                                    this.reloadRelay = true;
                                                                    this.forceUpdate();
                                                                    this.getListPoints();
                                                                }}>
                                                                Chercher
                                                            </button>
                                                        </div>
                                                    </div>

                                                    {Object.keys(this.state.listpoints).length > 0 &&
                                                        (!this.reloadRelay &&
                                                            <div style={{ display: "flex", flexDirection: window.innerWidth > 1020 ? "row" : "column" }}>
                                                                <ListPoints
                                                                    listpoints={this.state.listpoints}
                                                                    select={this.selected.bind(this)}
                                                                />
                                                                <div
                                                                    style={{
                                                                        flex: window.innerWidth > 1020 ? 1 : undefined,
                                                                        height: "500px",
                                                                        marginTop: window.innerWidth < 1020 ? "10px" : undefined,
                                                                    }}>
                                                                    <MapPoints listpoints={this.state.listpoints}
                                                                        selected={this.selected.bind(this)} />
                                                                </div>
                                                            </div>
                                                        )
                                                    }

                                                    {Object.keys(this.state.listpoints).length === 0 &&
                                                        <div>
                                                            Aucun point relais disponible
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }


                                <div className={formStyles.btnContainer}>
                                    <button
                                        className={globalStyles.defaultBtn}
                                        onClick={this.closeDelivery}
                                    >
                                        Valider
                                    </button>
                                </div>
                            </Modal>


                            <Modal
                                isOpen={this.isOpenModalAdd}
                                contentLabel="Adresse de livraison"
                                bodyOpenClassName={"noScroll"}
                                style={{
                                    content: {
                                        top: '50%',
                                        left: '50%',
                                        right: 'auto',
                                        bottom: 'auto',
                                        transform: 'translate(-50%, -50%)',
                                        zIndex: 100,
                                        width: "70%",
                                        padding: "40px",
                                        paddingTop: "20px",
                                        maxHeight: (window.innerHeight - 180) + "px",
                                        borderRadius: "15px"
                                    },
                                    overlay: {
                                        zIndex: 1500,
                                        backgroundColor: "rgba(0, 0, 0, 0.8)"
                                    }
                                }}
                            >
                                <AddAddressDelivery
                                    fetchJsonOrError={this.fetchJsonOrError}
                                />
                                <button
                                    style={{
                                        position: "absolute",
                                        top: "20px",
                                        right: "20px",
                                        background: "none",
                                        border: "none",
                                        fontSize: "20px",
                                        cursor: "pointer"
                                    }}
                                    onClick={() => this.closeModalAdd()}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </Modal>

                        </>
                    }
                </div>
            );
    }
}

export default connect(mapStateToProps)(CommitmentChoose);
