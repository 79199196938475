import React from "react"
import Modal from "react-modal";
import globalStyles from "../../Styles/Style.module.css";
import styles from "../Profile/Profile.module.css";
import stylesBasket from "../Shopping/Basket/ResumeBasket.module.css";
import CardTemplateBox from "./CardTemplateBox";
import formStyles from "../../Styles/FormStyle.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFlask, faSearch} from "@fortawesome/free-solid-svg-icons";


class ChooseBoxCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            theme: null,
            name: ""
        };
        this.themes = []
        this.boxes = []
        this.isLoading = true;
        this.oKDep = false;
        this.depAdd = false;
        this.hasDepModal = false;
        this.choosenIdent = null;
    }

    reload = () => {
        let data = new FormData();
        data.append('json', JSON.stringify(this.state));
        let request = new Request(process.env.REACT_APP_API_URL + '/client/box/search', {method:'POST', body: data, credentials:'include'});
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            this.boxes = data.boxes;
            this.themes = data.themes;
            this.isLoading = false;
            this.forceUpdate();
        })
    }

    themeOptions = () => {
        let options = []
        let length = this.themes.length
        for (let i = 0; i < length; i++) {
            options.push(
                <option key={'theme'+i} value={this.themes[i].id}>{this.themes[i].name}</option>
            )
        }
        return options
    }

    choose = (ident) => {
        let box = this.boxes[ident]
        if(box.hasDependencies && !this.oKDep) {
            this.choosenIdent = ident;
            this.hasDepModal = true;
            this.forceUpdate();
            return
        }
        this.props.setBoxInfos(box.reference, box.name)
        this.props.setBox(this.props.oaid, box.reference)
        this.props.close()
        if(box.hasDependencies && this.depAdd) {
            for(let i in box.dependencies) {
                this.props.dispatch({
                    type: "ADD_ITEM",
                    reference: box.dependencies[i].reference
                })
            }
        }
        if(this.props.fetchJsonOrError && this.depAdd) {
            let data = new FormData();
            let toSend = {
                articles: {},
                delivery: {}
            };
            toSend.articles[this.props.childOAid] = box.reference
            data.append('json', JSON.stringify(toSend))
            let request = new Request(process.env.REACT_APP_API_URL + '/client/commitment/choose/' + this.props.orderId, {method:'POST', body: data, credentials:'include'});
            this.props.fetchJsonOrError(request, (data) => {
                if(data.success) {
                    this.addFlash("success", "Ce choix a bien été pris en compte");
                    this.hasdisplayedFlash = false;
                    this.hydrateDisplayFlash();
                    this.forceUpdate();
                }
            })
        }
    }

    boxDepDontSelect = () => {
        this.hasDepModal = false;
        this.oKDep = false;
        this.choosenIdent = null;
        this.forceUpdate()
    }

    boxDepSelectAdd = () => {
        this.oKDep = true;
        this.hasDepModal = false;
        this.depAdd = true;
        this.choose(this.choosenIdent);
    }

    boxDepSelectDontAdd = () => {
        this.oKDep = true;
        this.hasDepModal = false;
        this.depAdd = false;
        this.choose(this.choosenIdent);
    }

    displayBoxDeps = () => {
        if(this.choosenIdent) {
            let res = [];
            let box = this.boxes[this.choosenIdent];
            for(let i in box.dependencies) {
                res.push(<li key={'dep'+i}>{box.dependencies[i].name}</li>);
            }
            return res;
        }
        return []
    }

    displayBoxes = (nbBox) => {
        let boxesDisp = []
        let total = this.boxes.length;
        let missing = nbBox - (total % nbBox);
        let currentLine = 0;
        for (let i = 0; i < total; i++) {
            if (i >= nbBox*currentLine + nbBox){
                boxesDisp.push(<div key={'firstbox'+i} className={styles.break}/>)
                currentLine++;
            }
            boxesDisp.push(
                <div key={'box'+i} className={styles.childCard} style={{
                    marginLeft: i%nbBox === 0 ? "0" : "10px",
                    marginRight: i%nbBox === nbBox - 1 ? "0" : "10px",
                }}>
                    <CardTemplateBox
                        box={this.boxes[i]}
                        i={i}
                        chooseAction={this.choose.bind(this)}
                    />
                </div>
            )
        }

        if(missing === nbBox) missing = 0;

        for(let i = 0; i < missing; i++){
            boxesDisp.push(
                <div key={'missingbox'+i} style={{flex: 1, display: "flex",  flexDirection: "row", margin: "5px"}}>
                </div>
            )
        }
        return boxesDisp
    }


    render () {

        if(this.isLoading) {
            this.reload()
        }

        return (
            <div style={{flex: 1, flexDirection: "column", display: "flex"}}>
                <div
                    className={globalStyles.card}
                    style={{
                        padding: "30px",
                        backgroundColor: "#f3f6f8",
                        boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                        borderRadius: "5px",
                        display: "flex",
                        boxSizing: "border-box",
                        flexDirection: "column",
                        marginTop: "10px"
                    }}
                >
                    <div style={{flex: 5}}>
                        <h3 style={{margin: "0 0 15px 0"}}>
                            Rechercher une box
                        </h3>

                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroup} style={{flex: 5, margin: window.innerWidth < 1020 ? "auto 0" : undefined}}>
                                <label>Nom de la box</label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </div>
                                    <input type="text" required className={formStyles.input} placeholder="Nom de la box" value={this.state.name} onChange={(event) => this.setState({name: event.target.value})}/>
                                </div>
                            </div>

                            <div className={formStyles.inputGroup} style={{flex: 5, margin: window.innerWidth < 1020 ? "10px 0" : undefined}}>
                                <label>Thème</label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faFlask}/>
                                    </div>
                                    <select
                                        value={this.state.theme ?? ""}
                                        className={formStyles.input}
                                        style={{
                                            padding: "3px",
                                            marginLeft: "10px",
                                            borderRadius: "0.25rem",
                                        }}
                                        onChange={(event) => this.setState({theme: event.target.value})}
                                    >
                                        <option value={""}>Tous les thèmes</option>
                                        {this.themeOptions()}
                                    </select>
                                </div>
                            </div>
                            <div className={formStyles.inputGroup} style={{alignSelf: "flex-end"}}>
                                <button className={globalStyles.defaultBtn} onClick={() => {this.isLoading=true; this.forceUpdate();}}>
                                    Chercher
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${styles.cardContainer} ${stylesBasket.scroll}`} style={{flexWrap: "wrap", margin: 0, marginTop: "10px", overflowY: "auto", flexDirection: "row" }}>
                    {window.innerWidth > 1020 ?
                        this.displayBoxes(2) :
                        this.displayBoxes(1)
                    }
                </div>

                <Modal
                    isOpen={this.hasDepModal}
                    bodyOpenClassName={"noScroll"}
                    contentLabel="Matériel supplémentaire"
                    style={{
                        content : {
                            margin: "auto",
                            zIndex: 50000,
                            width: window.innerWidth > 1020 ? "50%" : "calc(100% - 60px)",
                            padding: window.innerWidth > 1020 ? "40px" : "20px",
                            justifyContent: "center",
                            overflowY: "hidden",
                            display: "flex",
                            inset: 0,
                            borderRadius: "15px",
                            flexDirection: "column",
                            position: "relative"
                        },
                        overlay : {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            zIndex: 1500,
                            backgroundColor: "rgba(0, 0, 0, 0.8)"
                        }
                    }}
                >
                    <h2>Cette box nécessite du matériel supplementaire !</h2>

                    Cette box nécessite le matériel supplémentaire suivant :
                    <ul>
                        {this.displayBoxDeps()}
                    </ul>

                    Que veux-tu faire ?

                    <div style={{display: "flex", alignItems: "center", marginTop: "20px", flexDirection: window.innerWidth > 960 ? "row" : "column", justifyContent: "center"}}>
                        <button
                            className={globalStyles.defaultBtn}
                            onClick={() => this.boxDepSelectAdd()}
                            style={{
                                margin: window.innerWidth > 960 ? null : "5px"
                            }}
                        >
                            Ajouter au panier
                        </button>
                        <button
                            className={globalStyles.secondaryBtn}
                            onClick={() => this.boxDepDontSelect()}
                            style={{margin: "5px"}}
                        >
                            Choisir une autre box
                        </button>
                        <button
                            className={globalStyles.secondaryBtn}
                            onClick={() => this.boxDepSelectDontAdd()}
                            style={{
                                margin: window.innerWidth > 960 ? null : "5px"
                            }}
                        >
                            J'ai déjà ce matériel
                        </button>
                    </div>
                </Modal>

            </div>
        );
    }
}

export default ChooseBoxCard;
