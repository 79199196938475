import React from "react";
import LoggedComponent, {
    mapStateToProps
} from "../../../../Services/ConnexionService";
import { connect } from "react-redux";
import globalStyles from "../../../../Styles/Style.module.css";
import FilAriane from "../../../Header_Footer/FilAriane";
import Bandeau from "../../../Header_Footer/Bandeau";
import AddItemTemplate from "./AddItemTemplate";
import Cookies from "universal-cookie";
import style from "./UnloggedValidateBasket.module.css";
import { faTruck, faMapMarkerAlt, faStore, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import formStyles from "../../../../Styles/FormStyle.module.css";
import ReactTooltip from 'react-tooltip';
import Step from "../step";
import titleAriane from "../../../../Helpers/TitleAriane.json";
import AtoRikaTristes from "../../../../assets/mascottes/ato_rika_tristes.svg";

const cookieOptions = { maxAge: `${process.env.REACT_APP_COOKIE_BASKET_MAX_AGE}`, path: '/', domain: `${process.env.REACT_APP_COOKIE_DOMAIN}` }
const cookies = new Cookies(null, cookieOptions);

class UnloggedValidateBasket extends LoggedComponent {
    constructor(props) {
        super(props);
        this.hasAdded = false;
        this.hasResponded = false;
        this.itemsAdded = [];
        this.qts = {};
        this.delivery = {};
        this.deliveryLoaded = false;
        this.svgRef = React.createRef();
        this.popUpOpen = false;
        this.selectedCountry = "France";
        this.deliveryOption = "";
        this.countries = [];
        this.deliveryOptions = [];
        this.filteredOptions = [];
        this.tabDeliveryOptions = ["Domicile", "Point Relais"];
        this.priceDetail = null;
    }

    componentDidMount() {
        this.addItem();
        this.getCountries();
        localStorage.setItem('fromValidateBasket', 'true');
    }

    getCountries() {
        let request = new Request(process.env.REACT_APP_API_URL + '/client/countries', { method: 'GET', credentials: 'include' });
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            data.countries.forEach(country => {
                if (!this.countries.includes(country.label)) {
                    this.countries.push(country.label);
                }
            });

        })
    }

    async getDeliveryOption() {
        var choosenArticle = null;

        // Calcul du poids total
        for (let i = 0; i < this.itemsAdded.length; i++) {
            let article = this.itemsAdded[i];
            if (article && article.choosen) {
                choosenArticle = {
                    reference: article.choosen.reference, // Référence de l'article de test
                    quantity: 1, // Quantité de l'article de test
                    weight: article.choosen.weight, // Poids de l'article de test
                    isCommitment: false, // Indiquer si l'article est un engagement
                    commitmentDuration: null, // Durée de l'engagement pour l'article de test
                };
            }
        }

        // Préparation des données au format requis par la route
        const jsonData = {
            articles: [
                ...this.itemsAdded.map((article) => ({
                    reference: article.reference,
                    quantity: this.qts[article.reference] ?? 1,
                    weight: article.weight || 0,
                    isCommitment: article.isCommitment || false,
                    commitmentDuration:
                        article.duration && article.isCommitment ? article.duration : null,
                })),
                ...(Object.keys(choosenArticle || {}).length > 0
                    ? [choosenArticle]
                    : []), // Ajouter l'article de test à la fin du tableau s'il est valide
            ],
            countryHome: this.selectedCountry, // Le pays pour la livraison à domicile
            countryRelay: this.selectedCountry, // Le même pays pour le relais, si différent ajuster
        };

        try {
            let response = await fetch(process.env.REACT_APP_API_URL + "/client/delivery/estimate", {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(jsonData), // Transformation de l'objet en JSON
            });

            if (response.status === 200) {
                const data = await response.json();
                this.deliveryOptions = data.deliveryOptions;
                this.forceUpdate();
            }
        } catch (error) {
            error.log(
                "Erreur lors de la récupération des options de livraison",
                error
            );
        }
    }

    handleCountryChange = (event) => {
        this.selectedCountry = event.target.value;
        this.getDeliveryOption();
        this.forceUpdate(); // Force le rendu pour mettre à jour l'affichage
    };

    addItem = () => {
        const basketData = cookies.get("basketQuantity", cookieOptions);

        if (!this.hasAdded && !this.state.shouldNavigate && basketData) {
            this.hasAdded = true;
            let refs = Object.keys(basketData);

            this.qts = basketData;
            let jsonD = new FormData();
            jsonD.append("json", JSON.stringify(refs));
            let request = new Request(process.env.REACT_APP_API_URL + "/client/basket/items", {
                method: "POST",
                credentials: "include",
                body: jsonD,
            });
            this.fetchJsonOrError(request, (json) => {
                if (json.type === "OK") {
                    this.hasResponded = true;
                    this.itemsAdded = json.added;
                    this.forceUpdate();
                    this.getDeliveryOption();
                } else if (json.type === "NONE") {
                    this.hasResponded = true;
                    this.itemsAdded = [];
                    this.forceUpdate();
                    this.getDeliveryOption();
                } else {
                    this.goError();
                }
            });
        }
        else {
            this.hasResponded = true;
        }
    };

    displayItems() {

        return this.itemsAdded.map((article) => {
            const infos = [];
            if (article.isCommitment) {
                infos.push("Durée : " + article.duration + " mois");
            }
            infos.push(article.description);

            return (
                <AddItemTemplate
                    key={article.reference}
                    name={article.name}
                    media={article.media.length > 0 ? article.media[0] : undefined}
                    infos={infos}
                    priceTtc={article.priceTtc / 100}
                    unitPriceTtc={article.unitPriceTtc}
                    quantity={this.qts[article?.choosen?.reference ? article.reference+'-'+article.choosen.reference : article.reference]}
                    theme={article.theme}
                    secondaryTheme={article.secondaryTheme}
                    stock={article.stock}
                    isComm={article.isCommitment}
                    choosen={article.choosen}
                    reference={article.reference}
                />
            );
        });
    }

    setIsAddItem() {
        this.isAddItem = false;
    }

    doesMustLog = () => {
        this.mustLog = 0;
    };

    calculateTotalPriceArticle() {
        var finaleRefs = Object.keys(this.qts).reduce((acc, ref) => {
            let cleanedRef = ref.split("-")[0];
            acc[cleanedRef] = this.qts[ref];
            return acc;
        }, {});

        return this.itemsAdded.reduce((total, item) => {
            // Calculer le prix total pour cet article
            const itemTotal = (item.priceTtc / 100) * finaleRefs[item.reference];
            return total + itemTotal;
        }, 0);
    }

    formatPrice(price) {
        // Formater le prix avec deux décimales
        const formattedPrice = new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(price);

        // Diviser la chaîne pour séparer l'euro et les centimes
        const [euros, centimes] = formattedPrice.replace("€", "").split(",");

        return (
            <span className={style.euroPrice}>
                {euros}
                <span className={style.centimePrice}>€{centimes}</span>
            </span>
        );
    }

    priceDisplay(optionType) {
        const option = this.deliveryOptions[optionType];
        if (!option || !option.details) {
            return "-";
        }

        const details = option.details;


        const gratuit = (
            <span className={globalStyles.badge} style={{ backgroundColor: "#6fa939" }}>
                Offert
            </span>
        );

        if (Object.keys(details).length > 1) {
            const priceDetails = Object.entries(details)
                .map(([priceKey, quantity]) => {
                    const price = Number(priceKey) / 100;
                    const formattedPrice = `${price.toFixed(2).replace(".", ",")}€`;
                    return { price, quantity, formattedPrice };
                })
                .reverse(); //Mon detail est inversé donc je le reverse

            // Vérifie si tous les prix sont à zéro
            if (priceDetails.every(({ price }) => price === 0)) {
                return gratuit;
            }

            // Description des détails de prix
            const priceDetail = priceDetails.flatMap(({ quantity, formattedPrice }, index) => {
                const result = [];

                if (index === 0) {
                    // Première ligne : toujours 1x pour le premier mois
                    result.push(`Pour le premier mois : 1 x ${formattedPrice}`);

                    // Ajout d'une ligne pour le reste du premier item, si quantity > 1
                    if (quantity > 1) {
                        result.push(`Pour les ${quantity - 1} mois suivants : ${quantity - 1} x ${formattedPrice}`);
                    }
                } else {
                    // Pour les lignes suivantes
                    result.push(`Pour les ${quantity} mois suivants : ${quantity} x ${formattedPrice}`);
                }

                return result;
            }).join("<br/>");

            const total = priceDetails.reduce((sum, { price, quantity }) => sum + (price * quantity), 0);

            const tooltipId = `tooltip-${optionType}`;

            return (
                <div>
                    <span>{this.formatPrice(total)}</span>
                    <FontAwesomeIcon
                        className={formStyles.iconHelp}
                        icon={faQuestionCircle}
                        tabIndex={-1}
                        data-tip={priceDetail}
                        data-for={tooltipId}
                    />
                    <ReactTooltip id={tooltipId} multiline={true} place="top" effect="solid" />
                </div>
            );
        } else if (Object.keys(details).length === 1) {
            const [priceKey] = Object.keys(details);
            const price = Number(priceKey) / 100;
            const formattedPrice = `${price.toFixed(2).replace(".", ",")}€`;
            const duration = details[priceKey];
            let total = price * duration;

            if (priceKey === "0") {
                return gratuit;
            }

            if (duration === 1) {
                return (
                    <div>
                        <span>{formattedPrice}</span>
                    </div>
                );
            } else if (duration > 1) {
                const extraQuantity = duration - 1;
                const priceDetail = `1x ${formattedPrice}, puis ${extraQuantity}x ${formattedPrice}`;
                const tooltipId = `tooltip-${optionType}`;

                return (
                    <div>
                        <span>{this.formatPrice(total)}</span>
                        <FontAwesomeIcon
                            className={formStyles.iconHelp}
                            icon={faQuestionCircle}
                            tabIndex={-1}
                            data-tip={priceDetail}
                            data-for={tooltipId}
                        />
                        <ReactTooltip id={tooltipId} multiline={true} place="top" effect="solid" />
                    </div>
                );
            }

            return formattedPrice;
        }

        return "-";
    }



    getNombreArticle() {
        const totalArticles = this.itemsAdded.reduce((total, article) => {
            return total + (this.qts[article?.choosen?.reference ? article.reference+'-'+article.choosen.reference : article.reference] || 0);
        }, 0);

        return `${totalArticles} article${totalArticles > 1 ? 's' : ''}`;
    }

    validate = () => {
        this.setState({ shouldNavigate: true, navigateTo: '/basket' });
    }

    renderChild() {
        let redirect = new URLSearchParams(window.location.search).get("_redirect");

        return (
            <div>
                <Bandeau title={titleAriane["unloggedBasket"]["title"]} />
                <FilAriane
                    links={[
                        [
                            titleAriane["unloggedBasket"]["ariane"][0],
                            redirect
                        ],
                        [
                            titleAriane["unloggedBasket"]["ariane"][1]
                        ]
                    ]}
                />
                {!this.hasResponded ? (
                    <div className="loader">
                        <div></div>
                    </div>
                ) : (
                    !this.hasAdded || this.itemsAdded.length === 0 ? (
                        <div className={globalStyles.emptyBasket}>
                            <div>
                                <img src={AtoRikaTristes} alt="" />
                            </div>
                            <div>
                                <h2>Hum, ton panier est vide !</h2>
                                <div>
                                    Ton panier est là pour servir.<br />Remplis-le pour faire plaisir à Ato et Rika...
                                </div>
                                <a
                                    href={process.env.REACT_APP_SHOP_URL}
                                    className={globalStyles.defaultBtn}
                                    style={{ border: "1px solid #571088" }}
                                >
                                    Aller à la boutique
                                </a>
                            </div>
                        </div>
                    ) :
                        <>
                            <div className={globalStyles.headerStep}>
                                <div className={globalStyles.headernbArticlePrice}>
                                    <div className={globalStyles.headerStepPrice}>
                                        Sous total : {this.formatPrice(this.calculateTotalPriceArticle())}
                                    </div>
                                </div>
                                <button onClick={this.validate} className={globalStyles.defaultBtn}>
                                    Passer la commande
                                </button>
                            </div>
                            <div
                                className={globalStyles.globalContainer}
                                style={{
                                    flexDirection: "column",
                                    alignItems: "unset",
                                    padding: window.innerWidth > 960 ? "unset" : "10px",
                                    paddingTop: "10px",
                                }}
                            >

                                <div className={globalStyles.containerStepAndBasket}>
                                    <Step text="Aperçu du panier" />
                                    <div className={globalStyles.basketContainer}>
                                        <div className={style.basketItems}>
                                            {this.displayItems()}
                                        </div>
                                        <div>
                                            <div className={style.containerPriceLivraison}>
                                                <div
                                                    className={`${globalStyles.card} ${style.cardPrice}`}
                                                    style={{
                                                        padding: "30px",
                                                        flex: "0.7 1 0%",
                                                        backgroundColor: "#f3f6f8",
                                                        boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                                        borderRadius: "5px",
                                                        boxSizing: "border-box",
                                                        flexDirection: "column",
                                                        display: "flex",
                                                        alignItems: "flex-end",
                                                        marginBottom: "10px",
                                                        marginTop: "10px",
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <div className={style.nbArticle}>
                                                        {this.getNombreArticle()}
                                                    </div>
                                                    <h2 className={style.containerPrice}>
                                                        <div className={style.titleFraiLivraison}>Sous total : </div>
                                                        <div className={style.price}>
                                                            <div>
                                                                {this.formatPrice(this.calculateTotalPriceArticle())}
                                                            </div>
                                                        </div>
                                                    </h2>
                                                </div>
                                                <div
                                                    className={`${globalStyles.card} ${style.containerPrix}`}
                                                    style={{
                                                        padding: "30px",
                                                        flex: 1,
                                                        backgroundColor: "#f3f6f8",
                                                        boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                                        borderRadius: "5px",
                                                        boxSizing: "border-box",
                                                        flexDirection: "row",
                                                        display: "flex",
                                                        justifyContent:
                                                            window.innerWidth > 1020 ? "flex-end" : "center",
                                                        marginBottom: "10px",
                                                        marginTop: "10px",
                                                    }}
                                                >
                                                    <div>
                                                        <div className={style.titleFraiLivraison}>
                                                            <h3 style={{ margin: "0px 0px 15px" }}>Estimation de la livraison</h3>
                                                            <div>
                                                                <select
                                                                    className={style.customSelect}
                                                                    value={this.selectedCountry}
                                                                    onChange={this.handleCountryChange}
                                                                >
                                                                    <option value="" disabled>
                                                                        Choisis un pays
                                                                    </option>
                                                                    {this.countries.map((country) => (
                                                                        <option key={country} value={country}>
                                                                            {country}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className={style.sousTexte}>
                                                            Selon le mode de livraison choisi, voilà une estimation des frais qui seront appliqués pour ce panier.
                                                        </div>
                                                    </div>

                                                    <div className={style.priceLivraison}>
                                                        <div className={style.retrait}>
                                                            <div className={style.containerTextIcon}>
                                                                <FontAwesomeIcon icon={faMapMarkerAlt} />
                                                                Retrait en magasin
                                                            </div>
                                                            <span className={globalStyles.badge}
                                                                style={{ backgroundColor: "#6fa939" }}>
                                                                Offert
                                                            </span>
                                                        </div>
                                                        <div className={style.retrait}>
                                                            <div className={style.containerTextIcon}>
                                                                <FontAwesomeIcon icon={faStore} />
                                                                Livraison en point relais
                                                            </div>
                                                            <div>
                                                                {this.priceDisplay("relay")}
                                                            </div>

                                                        </div>
                                                        <div className={style.retrait}>
                                                            <div className={style.containerTextIcon}>
                                                                <FontAwesomeIcon icon={faTruck} />
                                                                Livraison à domicile
                                                            </div>
                                                            <div>
                                                                {this.priceDisplay("home")}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Step state="grey" number="2" text="Inscrirption / Connexion" btnNext={true} onValidate={this.validate} />
                                <Step state="grey" number="3" text="Contenu de la commande" />
                                <Step state="grey" number="3" text="Mode de livraison" image="livraison" />
                                <Step state="grey" number="3" text="Paiement" image="paiement" />
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                    className={globalStyles.btnBottomPage}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flex: 1,
                                            justifyContent: "flex-start",
                                            alignItems: "flex-start",
                                            textAlign: "left",
                                            marginTop: "10px",
                                            marginRight: window.innerWidth < 1020 ? "5px" : undefined,
                                        }}
                                    >
                                        <a href={redirect??process.env.REACT_APP_SHOP_URL} className={globalStyles.secondaryBtn}>
                                            Retour à la boutique
                                        </a>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flex: 1,
                                            justifyContent: "flex-end",
                                            alignItems: "flex-end",
                                            textAlign: "left",
                                            marginTop: "10px",
                                            marginLeft: window.innerWidth < 1020 ? "5px" : undefined,
                                        }}
                                    >
                                        <button
                                            onClick={this.validate}
                                            className={globalStyles.defaultBtn}
                                            style={{ border: "1px solid #571088" }}
                                        >
                                            Passer la commande
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                )}
            </div>
        );
    }
}

export default connect(mapStateToProps)(UnloggedValidateBasket);
