import React from "react"
import LoggedComponent, { mapStateToProps } from "../../Services/ConnexionService";
import globalStyles from '../../Styles/Style.module.css';
import FilAriane from "../Header_Footer/FilAriane";
import { connect } from "react-redux";
import Bandeau from "../Header_Footer/Bandeau";
import titleAriane from "../../Helpers/TitleAriane.json";
import CardPictures from "./CardPictures";

class Pictures extends LoggedComponent {

    constructor(props) {
        super(props);
        this.state = {
            pictures: undefined
        }
        this.loading = true;
    }

    doesMustLog = () => {
        this.mustLog = 1
    }

    getPictures() {
        let request = new Request(process.env.REACT_APP_API_URL + '/client/stats/pictures', { method: 'GET', credentials: 'include' });
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({ pictures: data });
            this.loading = false;
            this.forceUpdate();
        })
    }

    displayPictures() {
        let pictures = [];
        for (let i = 0; i < this.state.pictures.length; i++) {
            pictures.push(<h2 id={"user" + this.state.pictures[i].id} style={{ alignSelf: "flex-start" }}>{this.state.pictures[i].user}</h2>);
            pictures.push(
                <CardPictures
                    pictures={this.state.pictures[i].pictures}
                />
            )
            pictures.push(<br />)
        }
        return pictures;
    }


    renderChild() {

        return (
            <div>
                <Bandeau title={titleAriane["pictures"]["title"]} />

                <FilAriane
                    links={[
                        [
                            titleAriane["pictures"]["ariane"][0],
                            "/"
                        ],
                        [
                            titleAriane["pictures"]["ariane"][1],
                            "/stats"
                        ],
                        [
                            titleAriane["pictures"]["ariane"][2],
                            "/pictures"
                        ]
                    ]}
                />

                {!this.state.pictures ? (
                    <>
                        {this.getPictures()}
                        <div className="loader"><div></div></div>
                    </>)
                    :

                    <div className={globalStyles.globalContainer} style={{ flexDirection: "column", padding: window.innerWidth > 960 ? "unset" : "10px" }}>
                        {this.displayPictures()}
                    </div>
                }
            </div>
        );
    }
}

export default connect(mapStateToProps)(Pictures);