import styles from './Empty.module.css';
import React, { Component } from "react"
import Header from "./Header_Footer/Header";
import FilAriane from "./Header_Footer/FilAriane";
import Footer from "./Header_Footer/Footer";
import Bandeau from "./Header_Footer/Bandeau";

class Empty extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

        return (
            <div className={styles.div_100}>
                <Header empty={true} />
                <Bandeau title="" />
                <FilAriane />
                <div className="loader"><div></div></div>
                <Footer />
            </div>
        );
    }
}

export default Empty;
