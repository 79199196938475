import React, {useState} from "react";
import globalStyles from '../../Styles/Style.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Modal from "react-modal";
import ChooseBoxCard from "./ChooseBoxCard";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import ThemeBadge from "../Utils/ThemeBadge";

function CardChoosenBox(props) {

    let [isOpen, setIsOpen] = useState(false);

    let displayInfos = (infos) => {
        let informations = []
        for(let i in infos){
            informations.push(
                <div style={{marginTop: "10px"}}>
                    {infos[i]}
                </div>
            )
        }
        return informations
    }

    let displayStock = (stock, qt) => {
        if(stock < qt) {
            return (
                <span className={globalStyles.badge} style={{backgroundColor:"#52BAEC"}}>
                    En précommande
                </span>
            )
        }
        if(stock < 10) {
            return (
                <span className={globalStyles.badge} style={{backgroundColor:"#f39200"}}>
                    Moins de 10 en stock
                </span>
            )
        }
        return (
            <span className={globalStyles.badge} style={{backgroundColor:"#6fa939"}}>
                En stock
            </span>
        )
    }

    return (
        <div style={props.style}>
            <div style={{display: "flex", flexDirection: window.innerWidth > 1020 ? "row" : "column"}}>
                <div
                    className={globalStyles.card}
                    style={{
                        padding: "30px",
                        backgroundColor: "white",
                        boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                        borderRadius: "5px",
                        display: "flex",
                        boxSizing: "border-box",
                        flexDirection: "column",
                        flex: 2,
                    }}
                >
                    <div style={{flex: 5}}>
                        <h2 style={{margin: "0 0 15px 0"}}>
                            <FontAwesomeIcon icon={props.icon} style={{marginRight: "15px"}}/>
                            {props.title}
                        </h2>


                        <div style={{marginBottom: "20px"}}>
                            <ThemeBadge
                                theme={props.theme}
                                bgColor={props.themeColor}
                                baseColor={props.themeBaseColor}
                            />

                            { props.secondaryTheme &&
                                <ThemeBadge
                                    theme={props.secondaryTheme}
                                    bgColor={props.secondaryThemeColor}
                                    baseColor={props.secondaryThemeBaseColor}
                                    style={{marginLeft: "5px"}}
                                />
                            }
                        </div>

                        <div style={{flexDirection: window.innerWidth > 1020 ? "row" : "column", display: "flex"}}>
                            {props.img &&
                            <div style={{flex: 1}}>
                                <img
                                    alt={props.title}
                                    src={process.env.REACT_APP_CDN_URL + '/uploads/article/' + props.img}
                                    style={{width: window.innerWidth > 1020 ? "calc(100% - 2rem)" : "50%", margin: "1rem"}}
                                />
                            </div>
                            }
                            <div style={{flex: 2, marginLeft: "15px"}}>
                                {displayInfos(props.infos)}

                                <div style={{margin: "5px 0 10px"}}>
                                    Age : {props.age} +
                                </div>

                                <div style={{marginTop: "10px"}}>
                                    {displayStock(props.stock, props.quantity)}
                                </div>

                            </div>
                        </div>
                    </div>

                    { props.needChoose &&
                        <div style={{flex: 1}}>
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                            }}>
                                <button className={globalStyles.defaultLink} style={{marginTop: "15px"}} onClick={() => {setIsOpen(true)}}>
                                    Choisir
                                </button>
                            </div>
                        </div>
                    }

                </div>

                <Modal
                    isOpen={isOpen}
                    bodyOpenClassName={"noScroll"}
                    contentLabel="Choisir la prochaine box"
                    style={{
                        content : {
                            margin: "40px auto",
                            zIndex: 500,
                            width: window.innerWidth > 1020 ? "70%" : "calc(100% - 60px)",
                            padding: window.innerWidth > 1020 ? "40px" : "20px",
                            maxHeight: window.innerWidth > 960 ? (window.innerHeight - 180) + "px" : (window.innerHeight - 80) + "px",
                            overflowY: "hidden",
                            display: "flex",
                            inset: 0,
                            borderRadius: "15px"
                        },
                        overlay : {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            zIndex: 1500,
                            backgroundColor: "rgba(0, 0, 0, 0.8)"
                        }
                    }}
                >
                    <ChooseBoxCard
                        setBox={props.setBox}
                        setBoxInfos={props.setBoxInfos}
                        oaid={props.oaid}
                        close={() => {setIsOpen(false)}}
                        dispatch={props.dispatch}
                    />
                    <button
                        style={{
                            position: "absolute",
                            top: "20px",
                            right: "20px",
                            background: "none",
                            border: "none",
                            fontSize: "20px",
                            cursor: "pointer"
                        }}
                        onClick={() => {setIsOpen(false)}}
                    >
                        <FontAwesomeIcon icon={faTimes}/>
                    </button>
                </Modal>

            </div>
        </div>
    );
}

export default CardChoosenBox;
