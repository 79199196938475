import React from "react";
import DisplayPrice from "../../Utils/DisplayPrice";

function Basketitem(props) {

    const displayInfos = (infos) => {
        let informations = []
        let display = false
        for (let i in infos) {
            display = display || (infos[i] && infos[i] !=='' && infos[i] != null)
            informations.push(
                <div key={'info' + i} style={{fontSize: ".95rem"}}>
                    {infos[i]}
                </div>
            )
        }
        if (display && window.innerWidth < 1020) {
            return <div style={{margin: "10px 0", padding: "10px", background: "rgb(233, 236, 238)"}}>{informations}</div>
        }
        else {
            return informations
        }
    }

    return (
        <>
        {window.innerWidth < 768 && <hr style={{ border: "solid 0.1px rgb(236, 232, 232)" }} />}
        <div style={{ marginBottom: "20px", marginTop: "20px", display: "flex", flexDirection: window.innerWidth > 1020 ? "row" : "column" }}>

            {props.img &&
                <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                    <img alt={props.title} src={process.env.REACT_APP_CDN_URL + "/uploads/article/" + props.img} style={{ width: window.innerWidth > 1020 ? "80%" : "50%" }} />
                </div>
            }

            <div style={{ flex: 4, flexDirection: "column", marginLeft: props.img && window.innerWidth > 1020 ? "20px" : undefined, marginTop: window.innerWidth > 1020 ? undefined : "10px" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: 2 }}>
                        <div style={{ margin: "0", fontWeight: "500", fontSize: "1.1rem", marginBottom: "10px" }}>
                            {props.title}
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {props.unitPrice &&
                        <div>{props.quantity && props.type !== "commitment" ? props.quantity + " x " : ""}<DisplayPrice price={props.unitPrice}/> {props.type === "commitment" && "/ mois"}</div>
                    }
                    {displayInfos(props.infos)}
                </div>

            </div>
            <div style={{ flex: 1, textAlign: "right", fontWeight: "600", fontSize: "1.1rem" }}>
                <DisplayPrice price={props.price} />
            </div>
        </div>
        </>
    );
}

export default Basketitem;