import React from "react"
import LoggedComponent, { mapStateToProps } from "../../Services/ConnexionService";
import globalStyles from '../../Styles/Style.module.css';
import FilAriane from "../Header_Footer/FilAriane";
import { connect } from "react-redux";
import Bandeau from "../Header_Footer/Bandeau";
import titleAriane from "../../Helpers/TitleAriane.json";
import CardBox from "./CardBox";
import styles from "./Box.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

class Box extends LoggedComponent {

    constructor(props) {
        super(props);
        this.state = {
            boxes: undefined
        }
        this.loading = true;
    }


    doesMustLog = () => {
        this.mustLog = 1
    }

    getBoxes() {
        let request = new Request(process.env.REACT_APP_API_URL + '/client/user/box', { method: 'GET', credentials: 'include' });
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({ boxes: data });
            this.loading = false;
            this.forceUpdate();
        })
    }

    displayUserBoxes = (nbBox) => {
        let userBoxes = [];
        for (let i in this.state.boxes) {
            userBoxes.push(<h2 style={{ alignSelf: "flex-start" }} id={"user" + this.state.boxes[i].id}>{this.state.boxes[i].user}</h2>)
            userBoxes.push(<div className={styles.cardContainer} style={{ flexWrap: window.innerWidth > 960 ? "wrap" : undefined, margin: "auto -10px" }}>{this.displayBox(i, nbBox)}</div>)
            userBoxes.push(<br />)
        }
        return userBoxes;
    }


    displayBox = (index, nbBox) => {
        let boxes = [];
        for (let c in this.state.boxes[index].box) {
            let links = [];
            if (this.state.boxes[index].box[c].activities) {
                for (let i = 0; i < this.state.boxes[index].box[c].activities.length; i++) {
                    links.push([
                        this.state.boxes[index].box[c].activities[i].name,
                        process.env.REACT_APP_APP_URL + "/activity/find/" + this.state.boxes[index].box[c].activities[i].uuid
                    ])
                }
            }
            boxes.push(
                <div className={globalStyles.childCard} style={{ margin: 0 }}>
                    <CardBox
                        media={this.state.boxes[index].box[c].media[0]}
                        title={this.state.boxes[index].box[c].name}
                        theme={this.state.boxes[index].box[c].theme}
                        secondaryTheme={this.state.boxes[index].box[c].secondaryTheme}
                        themeColor={this.state.boxes[index].box[c].themeColor}
                        themeBaseColor={this.state.boxes[index].box[c].themeBaseColor}
                        secondaryThemeColor={this.state.boxes[index].box[c].secondaryThemeColor}
                        secondaryThemeBaseColor={this.state.boxes[index].box[c].secondaryThemeBaseColor}
                        infos={[
                            this.state.boxes[index].box[c].description
                        ]}
                        links={links}
                    />
                </div>
            )
        }

        if (this.state.boxes[index].activities.length > 0) {
            let activities = [];
            activities.push(
                <span style={{ fontSize: "1rem", textAlign: "right", margin: "2px 0 5px", fontWeight: 500 }}>
                    Accéder aux tutoriels <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginLeft: "5px" }} />
                </span>
            )
            for (let i = 0; i < this.state.boxes[index].activities.length; i++) {
                activities.push(
                    <a
                        className={globalStyles.defaultLink}
                        href={process.env.REACT_APP_APP_URL + "/activity/find/" + this.state.boxes[index].activities[i].uuid}
                        target={"_blank"}
                        rel={"noopener noreferrer"}
                        style={{ fontSize: "1rem", textAlign: "right", margin: "2px 0" }}
                    >
                        {this.state.boxes[index].activities[i].name}
                    </a>
                )
            }
            boxes.push(
                <div className={globalStyles.childCard} style={{ margin: 0 }}>
                    <div
                        className={globalStyles.card}
                        style={{
                            padding: "30px",
                            flex: 1,
                            backgroundColor: "#f3f6f8",
                            boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                            borderRadius: "5px",
                            display: "flex",
                            height: "100%",
                            boxSizing: "border-box",
                            flexDirection: "column"
                        }}
                    >
                        <div style={{ display: "flex", flexDirection: window.innerWidth > 565 ? "row" : "column" }}>
                            <div style={{ flex: 2 }}>
                                <div>
                                    <h3 style={{ margin: "0 0 15px 0" }}>
                                        Activités
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                flex: 1,
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start"
                            }}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", textAlign: "left", marginTop: "10px" }}>
                                {activities}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return boxes
    }

    renderChild() {

        return (
            <div>
                <Bandeau title={titleAriane["box"]["title"]} />
                <FilAriane
                    links={[
                        [
                            titleAriane["box"]["ariane"][0],
                            "/"
                        ],
                        [
                            titleAriane["box"]["ariane"][1],
                            "/box"
                        ]
                    ]}
                />

                {this.loading ?
                    <>
                        {this.getBoxes()}
                        <div className="loader"><div></div></div>
                    </> :
                    <div className={globalStyles.globalContainer} style={{ flexDirection: "column", padding: window.innerWidth > 960 ? "unset" : "10px" }}>
                        {this.displayUserBoxes(window.innerWidth > 1020 ? 2 : 1)}
                    </div>
                }
            </div>
        );
    }
}

export default connect(mapStateToProps)(Box);
