import React from "react";
import globalStyles from '../../Styles/Style.module.css';
import styles from "./CardStats.module.css"
import moment from "moment";

function CardStats(props) {

    const displayPictures = (nbPictures) => {
        let pictures = []
        for (let i = 0; i < props.pictures.length; i++) {
            if (i % nbPictures === 0) {
                pictures.push(<div className={globalStyles.break}/>);
            }
            pictures.push(
                <div style={{flex: 1, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginBottom: nbPictures > 1 ? 20 : undefined}}>
                    <div style={{maxWidth: 200, borderRadius: 10, aspectRatio: 1, overflow: "hidden", position: "relative"}}>
                        <span
                            style={{
                                top: 0,
                                position: "absolute",
                                backgroundColor: "rgb(0,0,0,0.8)",
                                color: "white",
                                padding: "5px 15px",
                                fontWeight: 500,
                                fontSize: "0.8rem",
                                borderBottomRightRadius: "10px"
                            }}>
                            {moment(props.pictures[i].date.date).format("DD/MM/YYYY")}
                        </span>
                        <img
                            alt=""
                            style={{height: "100%", width: "100%"}}
                            src={process.env.REACT_APP_CDN_URL + '/uploads/users/photo/' + props.pictures[i].media}
                        />
                    </div>

                    <p className={styles.badgeName}>{props.pictures[i].activity}</p>
                </div>
            )
        }

        if(nbPictures > 3){
            for (let i = 0; i < 3 - props.pictures.length; i++) {
                pictures.push(
                    <div style={{flex: 1}}/>
                )
            }
        }

        if(props.pictures.length % nbPictures !== 0){
            for(let i = 0; i < nbPictures - (props.pictures.length % nbPictures); i++){
                pictures.push(
                    <div style={{flex: 1}}>
                    </div>
                )
            }
        }



        return pictures
    }



    return (
        <div
            className={globalStyles.card}
            style={{
                padding: "30px",
                flex: 1,
                backgroundColor: props.color ? props.color : "#f3f6f8",
                boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                borderRadius: "5px",
                display: "flex",
                height: "100%",
                boxSizing: "border-box",
                flexDirection: "column",
                width: "100%"
            }}
        >

            {props.pictures.length !== 0 ?

                <div>
                    <div style={{display: "flex", flexWrap: "wrap"}}>
                        {displayPictures(window.innerWidth > 1020 ? 3 : 1)}
                    </div>
                </div>

                :

                <p style={{margin: 0}}>Pas de photos pour l'instant ...</p>
            }

        </div>
    );
}

export default CardStats;