import React from 'react';
import styles from './Shipping.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChild,
    faIdBadge,
    faMapMarkerAlt,
    faCity,
    faGlobeEurope,
    faPhone, faQuestionCircle,
    faMailBulk
} from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import ReactTooltip from "react-tooltip";
import globalStyles from '../../../Styles/Style.module.css';
import FilAriane from "../../Header_Footer/FilAriane";
import formStyles from '../../../Styles/FormStyle.module.css';
import Bandeau from "../../Header_Footer/Bandeau";
import titleAriane from "../../../Helpers/TitleAriane.json";

class Shipping extends React.Component {

    constructor(props) {
        super(props);
        if(Object.keys(props.data).length === 0) {
            this.state = {
                nameShipping: props.nameChild,
                firstNameShipping: props.firstNameChild,
                addressShipping: '',
                address2Shipping: '',
                postalCodeShipping: '',
                cityShipping: '',
                countryShipping: 'France',
                phoneShipping: '',
                nameBilling: props.nameParent,
                firstNameBilling: props.firstNameParent,
                sameAddress: true,
                addressBilling: '',
                postalCodeBilling: '',
                cityBilling: '',
                countryBilling: '',
                errors: {
                    phone: false
                },
                countries: undefined
            };

            this.loadingCountries = true;
        } else {
            this.state = props.data;
        }

    }

    getCountries() {
        let request = new Request(process.env.REACT_APP_API_URL + '/client/countries', {method:'GET', credentials:'include'});
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({countries: data.countries})
            this.loadingCountries = false;
            this.forceUpdate();
        })
    }

    render() {

        const {isSubmit, previous, ignore} = this.props;

        const formValid = () => {
            let infoShipping = (this.state.nameShipping.length > 0) && (this.state.firstNameShipping.length > 0) && (this.state.addressShipping.length > 0) && (this.state.cityShipping.length > 0) && (this.state.countryShipping.length > 0) && (!this.state.errors.phone);
            let infoBilling = (this.state.nameBilling.length > 0) && (this.state.firstNameBilling.length > 0);
            if(!this.state.sameAddress){
                infoBilling = infoBilling && (this.state.addressBilling.length > 0) && (this.state.cityBilling.length > 0) && (this.state.countryBilling.length > 0);
            }
            return (infoShipping && infoBilling);
        }

        if(this.loadingCountries) this.getCountries()

        return (
            <div>

                <Bandeau title={titleAriane["shipping"]["title"]}/>
                <FilAriane
                    links={[
                        [
                            titleAriane["shipping"]["ariane"][0],
                            "/register"
                        ],
                        [
                            titleAriane["shipping"]["ariane"][1],
                            "btn"
                        ],
                        [
                            titleAriane["shipping"]["ariane"][2],
                            ""
                        ],
                    ]}
                    function={previous.bind(this)}
                    state={this.state}
                />

                <div className={globalStyles.globalContainer}>


                    <div style={{flex: 1}}>

                        <div style={{textAlign: "right", marginTop: "10px"}}>
                            <button type="button" className={globalStyles.defaultLink} onClick={() => ignore()}>
                                Ignorer cette étape
                            </button>
                        </div>

                        <h2 style={{marginTop: "5px", marginLeft : window.innerWidth > 1020 ? undefined : "10px"}}>Livraison</h2>

                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroup}>
                                <label>
                                    Nom*
                                    <FontAwesomeIcon
                                        className={formStyles.iconHelp}
                                        icon={faQuestionCircle}
                                        data-tip="A qui sera adressé le colis ?"
                                        tabIndex={-1}
                                    />
                                </label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faIdBadge}/>
                                    </div>
                                    <input type="text" required className={formStyles.input} placeholder="Nom du destinataire" value={this.state.nameShipping} onChange={(event) => this.setState({nameShipping: event.target.value.toUpperCase()})}/>

                                </div>
                            </div>

                            <div className={formStyles.inputGroup}>
                                <label>
                                    Prénom*
                                    <FontAwesomeIcon
                                        className={formStyles.iconHelp}
                                        icon={faQuestionCircle}
                                        data-tip="A qui sera adressé le colis ?"
                                        tabIndex={-1}
                                    />
                                </label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faChild}/>
                                    </div>
                                    <input type="text" required className={formStyles.input} placeholder="Prénom du destinataire" value={this.state.firstNameShipping} onChange={(event) => this.setState({firstNameShipping: event.target.value})}/>
                                </div>
                            </div>
                        </div>

                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroup}>
                                <label>
                                    Adresse*
                                    <FontAwesomeIcon
                                        className={formStyles.iconHelp}
                                        icon={faQuestionCircle}
                                        data-tip="Où sera livré le colis ?"
                                        tabIndex={-1}
                                    />
                                </label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faMapMarkerAlt}/>
                                    </div>
                                    <input type="text" required className={formStyles.input} placeholder="Adresse de livraison" value={this.state.addressShipping} onChange={(event) => this.setState({addressShipping: event.target.value})}/>
                                </div>
                            </div>

                            <div className={formStyles.inputGroup}>
                                <label>
                                    Complément d'adresse
                                    <FontAwesomeIcon
                                        className={formStyles.iconHelp}
                                        icon={faQuestionCircle}
                                        data-tip="Cela permettera de faciliter la livraison."
                                        tabIndex={-1}
                                    />
                                </label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faMapMarkerAlt}/>
                                    </div>
                                    <input type="text" required className={formStyles.input} placeholder="Étage, Bâtiment .." value={this.state.address2Shipping} onChange={(event) => this.setState({address2Shipping: event.target.value})}/>
                                </div>
                            </div>
                        </div>

                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroup}>
                                <label>
                                    Code postal*
                                </label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faMailBulk}/>
                                    </div>
                                    <input type="text" maxLength={5} required className={formStyles.input} placeholder="00000" value={this.state.postalCodeShipping} onChange={(event) => this.setState({postalCodeShipping: event.target.value})}/>
                                </div>
                            </div>

                            <div className={formStyles.inputGroup}>
                                <label>Ville*</label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faCity}/>
                                    </div>
                                    <input type="text" required className={formStyles.input} placeholder="Ville" value={this.state.cityShipping} onChange={(event) => this.setState({cityShipping: event.target.value})}/>
                                </div>
                            </div>
                        </div>

                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroup}>
                                <label>
                                    Pays
                                    <FontAwesomeIcon className={formStyles.iconHelp} icon={faQuestionCircle} tabIndex={-1} data-tip={"Nous ne livrons que dans les pays suivants : " + this.state.countries?.map(country => country.label)?.join(',')}/>
                                </label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faGlobeEurope}/>
                                    </div>
                                    <select value={this.state.countryShipping} className={formStyles.input} onChange={(event) => this.setState({countryShipping: event.target.value})}>
                                        {
                                            this.state.countries?.map(country =>
                                                <option value={country.label}>
                                                    {country.label}
                                                </option>
                                            )
                                        }
                                    </select>
                                </div>
                                <div>
                                    <a
                                        href={"https://atorika.tawk.help/article/est-il-possible-de-se-faire-livrer-hors-de-france-m%c3%a9tropolitaine"}
                                        className={globalStyles.defaultLink}
                                        style={{marginTop: "10px",
                                            color: "#777",
                                            display: "block",
                                            fontWeight: "400",
                                            fontSize: "0.9em",
                                            textDecoration: "underline",
                                        }}
                                        target={"_blank"} rel={"noopener noreferrer"}
                                    >
                                        Comment se faire livrer dans un autre pays ?
                                    </a>
                                </div>
                            </div>

                            <div className={formStyles.inputGroup}>
                                <label>
                                    Numéro de téléphone
                                    <FontAwesomeIcon className={formStyles.iconHelp} icon={faQuestionCircle} tabIndex={-1} data-tip="Cela permettera de faciliter la livraison."/>
                                </label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faPhone}/>
                                    </div>
                                    <input
                                        type="tel"
                                        required
                                        className={formStyles.input}
                                        placeholder="0600000000"
                                        value={this.state.phoneShipping}
                                        pattern={
                                            this.state.countries?.map(country =>
                                                country.phoneRegex
                                            )?.join('|')
                                        }
                                        onChange={(event) => this.setState({phoneShipping: event.target.value, errors: {...this.state.errors, phone: (event.target.validity.patternMismatch && event.target.value !== '')}})}
                                    />
                                </div>
                                {this.state.errors.phone &&
                                    <span className={styles.textDanger}>Le numéro de téléphone doit être valide.</span>
                                }
                            </div>
                        </div>

                        <h2 style={{marginLeft : window.innerWidth > 1020 ? undefined : "10px"}}>Facturation</h2>

                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroup}>
                                <label>
                                    Nom*
                                    <FontAwesomeIcon
                                        className={formStyles.iconHelp}
                                        icon={faQuestionCircle}
                                        tabIndex={-1}
                                        data-tip="A quel nom sera la facture ?"
                                    />
                                </label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faIdBadge}/>
                                    </div>
                                    <input type="text" required className={formStyles.input} placeholder="Nom" value={this.state.nameBilling} onChange={(event) => this.setState({nameBilling: event.target.value.toUpperCase()})}/>
                                </div>
                            </div>

                            <div className={formStyles.inputGroup}>
                                <label>
                                    Prénom*
                                    <FontAwesomeIcon
                                        className={formStyles.iconHelp}
                                        icon={faQuestionCircle}
                                        tabIndex={-1}
                                        data-tip="A quel nom sera la facture ?"
                                    />
                                </label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faChild}/>
                                    </div>
                                    <input type="text" required className={formStyles.input} placeholder="Prénom" value={this.state.firstNameBilling} onChange={(event) => this.setState({firstNameBilling: event.target.value})}/>
                                </div>
                            </div>
                        </div>

                        <div className={formStyles.inputLineSame} style={{alignItems: 'center'}}>
                            <input
                                className={formStyles.checkbox}
                                type={"checkbox"}
                                checked={this.state.sameAddress}
                                onChange={(event) => this.setState({sameAddress: event.target.checked})}
                            />
                            <label>Même adresse que celle de livraison</label>
                        </div>

                        {!this.state.sameAddress &&
                        <div>
                            <div className={formStyles.inputLine}>
                                <div className={formStyles.inputGroup}>
                                    <label>Adresse*</label>
                                    <div className={formStyles.inputField}>
                                        <div className={formStyles.inputIcon} id={styles.addressIcon}>
                                            <FontAwesomeIcon icon={faMapMarkerAlt}/>
                                        </div>
                                        <input
                                            type="text"
                                            required
                                            className={formStyles.input}
                                            placeholder="Adresse de facturation"
                                            value={this.state.addressBilling}
                                            onChange={(event) => this.setState({addressBilling: event.target.value})}
                                        />
                                    </div>
                                </div>

                                <div className={formStyles.inputGroup}>
                                    <label>
                                        Code postal*
                                    </label>
                                    <div className={formStyles.inputField}>
                                        <div className={formStyles.inputIcon}>
                                            <FontAwesomeIcon icon={faMailBulk}/>
                                        </div>
                                        <input type="text" maxLength={5} required className={formStyles.input} placeholder="00000" value={this.state.postalCodeBilling} onChange={(event) => this.setState({postalCodeBilling: event.target.value})}/>
                                    </div>
                                </div>
                            </div>

                            <div className={formStyles.inputLine}>
                                <div className={formStyles.inputGroup}>
                                    <label>Ville*</label>
                                    <div className={formStyles.inputField}>
                                        <div className={formStyles.inputIcon}>
                                            <FontAwesomeIcon icon={faCity}/>
                                        </div>
                                        <input type="text" required className={formStyles.input} placeholder="Ville"
                                               value={this.state.cityBilling}
                                               onChange={(event) => this.setState({cityBilling: event.target.value})}/>
                                    </div>
                                </div>

                                <div className={formStyles.inputGroup}>
                                    <label>Pays*</label>
                                    <div className={formStyles.inputField}>
                                        <div className={formStyles.inputIcon}>
                                            <FontAwesomeIcon icon={faGlobeEurope}/>
                                        </div>
                                        <input type="text" required className={formStyles.input}
                                               placeholder="Pays" value={this.state.countryBilling}
                                               onChange={(event) => this.setState({countryBilling: event.target.value})}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }

                        <div className={formStyles.btnContainer} style={{marginTop: "30px"}}>
                            <div style={{display: "flex", justifyContent: "flex-start", flex: 1}}>
                                <button className={globalStyles.defaultLink} onClick={() => previous(this.state)}>
                                    ‹ Étape précedente
                                </button>
                            </div>
                            <div style={{display: "flex", justifyContent: "flex-end", flex: 1}}>
                                <button
                                    type="button"
                                    className={formValid() ? globalStyles.defaultBtn : formStyles.disabledBtn}
                                    onClick={() => isSubmit(this.state)}
                                    disabled={!formValid()}
                                >
                                    Continuer
                                </button>
                            </div>
                        </div>




                    </div>




                </div>
                <ReactTooltip multiline={true}/>
            </div>
        );
    }

}

export default Shipping;
