import React from "react";

function CardTemplateContent(props) {

    return (
        <div style={{marginBottom: "20px", marginTop: "20px", display: "flex", flexDirection: "row"}}>

            {props.img &&
                <div style={{flex: 1}}>
                    <img alt={props.title} src={process.env.REACT_APP_CDN_URL + "/uploads/article/" + props.img} style={{maxWidth: "100%", maxHeight: "100%"}}/>
                </div>
            }

            <div style={{flex: 3, flexDirection: "column", marginLeft: props.img ? "20px" : undefined}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{flex: 2, marginBottom: "10px"}}>
                        <h3 style={{margin: "0"}}>
                            {props.title}
                        </h3>
                    </div>
                    <div style={{marginBottom: "10px"}}>{props.description}</div>
                    <div>Quantité : {props.quantity}</div>
                </div>
            </div>

        </div>
    );
}

export default CardTemplateContent;