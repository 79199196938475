import cb from "../../assets/footer/pay/cb.png"
import mastercard from "../../assets/footer/pay/mastercard.png"
import visa from "../../assets/footer/pay/visa.png"
import relaiscolis from "../../assets/footer/delivery/relaiscolis.png"
import colissimo from "../../assets/footer/delivery/colissimo.png"
import mondialrelay from "../../assets/footer/delivery/mondialrelay.png"
import logo from "../../assets/footer/logo.png"
import france from "../../assets/footer/Flag_of_France.svg"
import styles from './Footer.module.css';
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFacebookF,
    faInstagram,
    faLinkedinIn,
    faXTwitter,
    faYoutube
} from "@fortawesome/free-brands-svg-icons";
import { faCloud, faStar, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons"
import { faStar as faStarStroke } from "@fortawesome/free-regular-svg-icons"
import Cookies from 'universal-cookie';
import globalStyles from "../../Styles/Style.module.css"

const cookieOptions = { maxAge: `${process.env.REACT_APP_COOKIE_STANDARD_MAX_AGE}`, path: '/', domain: `${process.env.REACT_APP_COOKIE_DOMAIN}` }
const cookies = new Cookies(null, cookieOptions);

function Footer() {

    var n = 0;
    const [note, setNote] = useState(4.5);
    const [carbone, setCarbone] = useState(0.1);

    useEffect(() => {
        let isMounted = true;
        const notecarbone = cookies.get('notecarbone')
        if (!notecarbone) {
            let request = new Request(process.env.REACT_APP_API_URL + '/vitrine/note', { method: 'GET', credentials: 'include' });
            fetch(request).then((response) => {
                return response.json()
            }).then((data) => {
                if (isMounted) {
                    setCarbone(data.carbone)
                    setNote(data.note)
                }
                cookies.set('notecarbone', JSON.stringify(data), cookieOptions);
            })
        }
        else {
            if (isMounted) {
                setCarbone(notecarbone.carbone)
                setNote(notecarbone.note)
            }
        }
        return () => { isMounted = false };
    }, [])

    const getNote = () => {
        var dataNote = note ? note : 4.5
        const stars = [];
        for (let i = 0; i < 5; i++) {
            if (dataNote > i + 0.5) {
                stars.push(<FontAwesomeIcon key={n++} icon={faStar} size={'2x'} color={'#ffd43b'} />)
            } else if (dataNote > i) {
                stars.push(<FontAwesomeIcon key={n++} icon={faStarHalfAlt} size={'2x'} color={'#ffd43b'} />)
            } else {
                stars.push(<FontAwesomeIcon key={n++} icon={faStarStroke} size={'2x'} color={'#ffd43b'} />)
            }
        }
        return stars
    }

    return (
        <div>
            <div className={styles.topFooter}>
                <div>
                    <div className={styles.childTopFooter}>
                        <div className={styles.france} style={{ backgroundImage: `url(${france})` }}>
                        </div>

                        <div>
                            Fabriqué en France
                        </div>
                    </div>

                    <div className={styles.childTopFooter}>
                        <div>
                            <svg fill="#000000" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#000000" style={{ width: '50px', overflow: 'unset' }}>
                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path d="M25 12h-1v-3.816c0-4.589-3.32-8.184-8.037-8.184-4.736 0-7.963 3.671-7.963 8.184v3.816h-1c-2.206 0-4 1.794-4 4v12c0 2.206 1.794 4 4 4h18c2.206 0 4-1.794 4-4v-12c0-2.206-1.794-4-4-4zM10 8.184c0-3.409 2.33-6.184 5.963-6.184 3.596 0 6.037 2.716 6.037 6.184v3.816h-12v-3.816zM27 28c0 1.102-0.898 2-2 2h-18c-1.103 0-2-0.898-2-2v-12c0-1.102 0.897-2 2-2h18c1.102 0 2 0.898 2 2v12zM16 18c-1.104 0-2 0.895-2 2 0 0.738 0.405 1.376 1 1.723v3.277c0 0.552 0.448 1 1 1s1-0.448 1-1v-3.277c0.595-0.346 1-0.985 1-1.723 0-1.105-0.895-2-2-2z">
                                    </path>
                                </g>
                            </svg>
                        </div>

                        <div>
                            Paiement 100% sécurisé
                        </div>
                    </div>

                    <div className={styles.childTopFooter}>

                        <div>

                            {getNote()}

                        </div>

                        <div>
                            Avis clients
                        </div>
                    </div>
                </div>
            </div>
            <div style={{
                background: "linear-gradient(150deg, #073d50 40%, #175060)",
                position: "relative",
                padding: "30px 0px 20px 0px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column"
            }}>
                <div className={styles.logoContainer}>
                    <img src={logo} alt={"Logo d'Atorika"} width={200} height={65} />
                </div>

                <div className={styles.fullContainer}>

                    <div className={styles.divider} />
                    <div className={styles.payAndDelivery}>
                        <div className={styles.container}>
                            <div style={{
                                flex: 1
                            }}>
                                <div className={styles.title}>
                                    Nos paiements sécurisés
                                </div>
                                <div className={globalStyles.imageItemContainer}>
                                    <div className={globalStyles.imageContainer}>
                                        <img src={cb} alt={'Carte bancaire'} />
                                    </div>
                                    <div className={globalStyles.imageContainer}>
                                        <img src={mastercard} alt={'MasterCard'} />
                                    </div>
                                    <div className={globalStyles.imageContainer}>
                                        <img src={visa} alt={'Visa'} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.container}>
                            <div style={{
                                flex: 1
                            }}>
                                <div className={styles.title}>
                                    Nos livreurs partenaires
                                </div>
                                <div className={globalStyles.imageItemContainer}>
                                    <div className={globalStyles.imageContainer}>
                                        <img src={relaiscolis} alt={'Relais Colis'} />
                                    </div>
                                    <div className={globalStyles.imageContainer}>
                                        <img src={colissimo} alt={'Colissimo'} />
                                    </div>
                                    <div className={globalStyles.imageContainer}>
                                        <img src={mondialrelay} alt={'Mondial Relay'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            <div className={styles.legalNetworkFooter}>
                <div className={styles.legalNetwork}>

                    <div className={styles.legal}>
                        <div className={styles.itemLegal}>
                            <a href={process.env.REACT_APP_SHOP_URL + "/legal"} className={styles.linkLegal}>
                                Mentions légales
                            </a>
                        </div>

                        <div className={styles.itemLegal}>
                            <a href={process.env.REACT_APP_SHOP_URL + "/policy"} className={styles.linkLegal}>
                                Politique de confidentialité
                            </a>
                        </div>


                        <div className={styles.itemLegal}>
                            <a href={process.env.REACT_APP_SHOP_URL + "/terms"} className={styles.linkLegal}>
                                CGUV
                            </a>
                        </div>

                    </div>

                    <div className={styles.network}>
                        <a href={"https://www.facebook.com/atorika.fr/"}>
                            <FontAwesomeIcon icon={faFacebookF} className={styles.icon} />
                        </a>
                        <a href={"https://www.instagram.com/atorika.fr/"}>
                            <FontAwesomeIcon icon={faInstagram} className={styles.icon} />
                        </a>
                        <a href={"https://fr.linkedin.com/company/atorika"}>
                            <FontAwesomeIcon icon={faLinkedinIn} className={styles.icon} />
                        </a>
                        <a href={"https://twitter.com/atorika_fr"}>
                            <FontAwesomeIcon icon={faXTwitter} className={styles.icon} />
                        </a>
                        <a href={"https://www.youtube.com/channel/UCULoOLNbsQcG9aZGrBEM1ag"}>
                            <FontAwesomeIcon icon={faYoutube} className={styles.icon} />
                        </a>
                    </div>

                </div>

                <div className={styles.divider} />

                <div className={styles.footerContainer}>

                    <div className={styles.footer}>
                        © 2020-2023 Atorika ® - Tous droits réservés
                    </div>


                    <div className={styles.co2score}>
                        <FontAwesomeIcon icon={faCloud} />
                        1 vue = {carbone ? carbone : "0.2"}g de CO2 émis
                    </div>

                    <div className={styles.footer} style={{ textAlign: "right" }}>
                        Réalisé sous le 🌞 de Provence
                    </div>

                </div>
            </div>
        </div >
    );
}

export default Footer;
