import React from 'react';
import fr from 'date-fns/locale/fr';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChild,
    faVenus,
    faIdBadge, faInfo,
    faMars,
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import globalStyles from '../../../Styles/Style.module.css';
import formStyles from '../../../Styles/FormStyle.module.css';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {Stack} from "@mui/material";
import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
//import "react-datepicker/dist/react-datepicker.css";

class AddChildBasket extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            lastName: '',
            firstName: '',
            birth: '',
            gender: 'F',
        };
        this.isLoading = true;
    }

    getAge = (date) => {
        if(date){
            let today = new Date();
            let birthDate = new Date(date);
            let age = today.getFullYear() - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        }
        return NaN;
    }

    create = () => {
        let data = new FormData();
        data.append('json', JSON.stringify({...this.state}));
        let request = new Request(process.env.REACT_APP_API_URL + '/client/addchild/order/' + this.props.oaid, {method:'POST', body: data, credentials:'include'});
        this.props.fetchJsonOrError(request, (data) => {
            if(data.created){
                window.location.reload()
            }
        })
    }

    render() {

        const formValid = () => {
            return (this.state.lastName.length > 0) && (this.state.firstName.length > 0) && (this.state.birth !== '') && (!isNaN(this.getAge(this.state.birth)) && this.getAge(this.state.birth) < 100);
        }

        return (
            <div>
                <div>

                    <h2>{this.props.title}</h2>

                    <div style={{flex: 1}}>

                        <div className={globalStyles.bulle}>
                            <span className={globalStyles.iconBulle}>
                                <FontAwesomeIcon icon={faInfo}/>
                            </span>
                            <span className={globalStyles.iconText}>
                                En créant un nouveau profil, tu pourras suivre les progrès de la personne que tu ajoutes via ses propres points d'expériences et badges.
                                Si tu renseignes une adresse email, ou un nom d'utilisateur et un mot de passe, cette personne pourra se connecter sur ce site et sur l'application mobile de manière totalement autonome.
                            </span>
                        </div>

                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroup}>
                                <label>Nom*</label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faIdBadge}/>
                                    </div>
                                    <input type="text" required className={formStyles.input} placeholder="Nom" value={this.state.lastName} onChange={(event) => this.setState({lastName: event.target.value.toUpperCase()})}/>
                                </div>
                            </div>

                            <div className={formStyles.inputGroup}>
                                <label>Prénom*</label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faChild}/>
                                    </div>
                                    <input type="text" required className={formStyles.input} placeholder="Prénom" value={this.state.firstName} onChange={(event) => this.setState({firstName: event.target.value})}/>
                                </div>
                            </div>
                        </div>

                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroupBirthGender}>
                                <div className={formStyles.inputGroupGender}>
                                    <label>Sexe*</label>
                                    <div className={formStyles.inputContent}>
                                        <div className={formStyles.gender}>
                                            <button
                                                type="button"
                                                onClick={() => this.setState({gender: "F"})}
                                                className={this.state.gender === "F" ? `${formStyles.btnSelected} ${formStyles.btnFemale}` : `${formStyles.btnNotSelected} ${formStyles.btnFemale}`}
                                            >
                                                <FontAwesomeIcon icon={faVenus}/>
                                            </button>
                                            <button type="button" onClick={() => this.setState({gender: "M"})}  className={this.state.gender === "M" ? `${formStyles.btnSelected} ${formStyles.btnMale}` : `${formStyles.btnNotSelected} ${formStyles.btnMale}`}>
                                                <FontAwesomeIcon icon={faMars}/>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className={formStyles.inputGroupBirth}>
                                    <div style={{marginBottom: "5px"}}>
                                        <label>Date de naissance*</label>
                                        {!isNaN(this.getAge(this.state.birth)) &&
                                            (this.getAge(this.state.birth) > 100 ?
                                                    <span style={{fontSize: "12px", color: "red", marginLeft: "5px"}}> {this.getAge(this.state.birth)} ans</span>
                                                    :
                                                    <span style={{fontSize: "12px", marginLeft: "5px"}}> {this.getAge(this.state.birth)} ans</span>
                                            )
                                        }
                                    </div>

                                    <div className={formStyles.inputField} style={{display: "inline", border: "none"}}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={fr}>
                                            <Stack spacing={3}>
                                                <DatePicker
                                                    label="Date de naissance"
                                                    value={this.state.birth}
                                                    openTo="year"
                                                    views={['year', 'month', 'day']}
                                                    onChange={(date) => {
                                                        if(date){
                                                            date.setHours(12);
                                                        }
                                                        this.setState({birth: date});
                                                    }}
                                                    enableFuture={false}
                                                    inputFormat={"dd/MM/yyyy"}

                                                    renderInput={(params) => <TextField size={"small"} {...params} helperText={null} />}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            </div>


                            <div className={formStyles.inputGroup}>

                            </div>
                        </div>

                        <div className={formStyles.btnContainer}>
                            <button type="button" className={formValid() ? globalStyles.defaultBtn : formStyles.disabledBtn} onClick={() => this.create()} disabled={!formValid()}>
                                Enregistrer
                            </button>
                        </div>
                    </div>
                </div>
                <ReactTooltip multiline={true}/>
            </div>
        );
    };

}

export default AddChildBasket;
