import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import Empty from "../components/Empty";
import Header from "../components/Header_Footer/Header";
import Footer from "../components/Header_Footer/Footer";
import Flash from "../components/Flash/Flash"
import Cookies from 'universal-cookie';

const cookieOptions = { maxAge: `${process.env.REACT_APP_COOKIE_STANDARD_MAX_AGE}`, path: '/', domain: `${process.env.REACT_APP_COOKIE_DOMAIN}` }
const cookies = new Cookies(null, cookieOptions);

export const mapStateToProps = (state) => state

export async function checkIfConnected () {
    let req = new Request(process.env.REACT_APP_API_URL + '/client/user/me', {credentials:'include'})
    let resp = await fetch(req)
    if (resp.status === 200) {
        let user_data = await resp.json()

        if (Object.keys(user_data).length > 0) {
        cookies.set('connected', user_data.user.firstName);
        return {
            conn: true,
            user : user_data.user,
            children : user_data.childs,
            basket: user_data.basket
        }
        }
    }

    if (cookies.get('connected')) {
        cookies.remove('connected');
    }
    return { conn: false }
}

export function addFlash(dispatch, type, content) {
    dispatch({
        type: "ADD_FLASH",
        flashType: type,
        flashContent: content
    })
}

export default class LoggedComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                birthDate: {},
                addresses: []
            },
            children: [],
            basketNb: 0,
            shouldNavigate: false,
            navigateTo: '/',
            isOK: false,
        }
        this.mustSecure = true
        this.mustLog = 0
        this.isConnected = false
        this.hasdisplayedFlash = false
        this.flashtoDisplay = []
        this.isAddItem = false
        this.doRender = false
    }

    addFlash(type, content) {
        addFlash(this.props.dispatch.bind(this), type, content)
    }

    setIsAddItem() {
        this.isAddItem = false
    }

    hydrateDisplayFlash() {
        if (!this.hasdisplayedFlash) {
            this.hasdisplayedFlash = true
            let res = []
            let flash, id
            let ids = this.props.flash.ids
            for (const i in ids) {
                id = ids[i]
                flash = this.props.flash.flash['flash' + id]
                res.push(<Flash type={flash.type} content={flash.content} flashKey={id} />)
                this.props.dispatch({
                    type: "REMOVE",
                    id: id
                })
            }
            this.flashtoDisplay = res
        }
    }

    shoulGodAddItem() {
        this.setIsAddItem()
        if (!this.isAddItem) {
            if (this.props.item.ids.length > 0) {
                this.setState({ shouldNavigate: true, navigateTo: '/basket/additem' })
            }
        }
    }

    security() {
        this.doesMustLog()
        checkIfConnected().then((result) => {
            this.isConnected = result.conn
            if (!result.conn && this.mustLog === 1) {
                this.setState({ shouldNavigate: true, navigateTo: '/login' })
            }
            if (result.conn && this.mustLog === -1) {
                this.setState({ shouldNavigate: true, navigateTo: '/' })
            }
            if (result.conn && this.mustLog === 1) {
                this.setState({ user: result.user, children: result.children, basketNb: result.basket, isOK: true })
            }
            if (this.mustLog === 0 || (this.mustLog === -1 && !result.conn)) {
                this.setState({ isOK: true })
            }
        })
    }

    fetchJsonOrError(request, callBack, customError = null) {
        fetch(request).then((response) => {
            if (response.status === 200) {
                return response.json()
            } else if (customError) {
                customError()
            } else {
                this.goError()
            }
        }).then(callBack)
    }

    goError() {
        this.setState({ shouldNavigate: true, navigateTo: '/error' })
    }

    navigate() {
        if (this.state.navigateTo.startsWith('http')) {
            window.location.replace(this.state.navigateTo)
            return (<Empty />)
        }
        return (
            <Navigate to={this.state.navigateTo} />
        )
    }

    setDoRender = () => {
        this.doRender = true
        this.forceUpdate()
    }

    render() {
        if (this.mustSecure) {
            this.security()
            this.mustSecure = false
        }
        if (this.state.shouldNavigate) {
            return this.navigate();
        }
        if (!this.state.isOK) {
            return <Empty />
        }
        if (this.isConnected) {
            this.shoulGodAddItem()
        }
        if (!this.doRender) {
            this.renderChild()
            this.hydrateDisplayFlash()
            setTimeout(this.setDoRender.bind(this), 500)
        }
        return (
            <div>
                <Header
                    user={this.state.user}
                    connected={this.isConnected}
                    hasBox={this.state.user.hasBox}
                    hasCommitment={this.state.user.hasCommitment}
                    basket={this.state.basketNb}
                />
                {this.flashtoDisplay}
                {this.renderChild()}
                <Footer />
            </div>
        )
    }
}
