import React from 'react';
import FilAriane from "../../Header_Footer/FilAriane";
import globalStyles from "../../../Styles/Style.module.css";
import formStyles from "../../../Styles/FormStyle.module.css";
import styles from "./Welcome.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import Bandeau from "../../Header_Footer/Bandeau";
import titleAriane from "../../../Helpers/TitleAriane.json";

class Welcome  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mail: props.mail ? props.mail : '',
            sendMail: false
        }
        this.isSubmit = props.isSubmit;
        this.errors = {
            mail: undefined,
            cannotUseMail: false,
        }
    }


    goNext = () => {
        let data = new FormData();
        data.append('json', JSON.stringify({email: this.state.mail, optin: this.state.sendMail}));
        let request = new Request(process.env.REACT_APP_API_URL + '/client/prospect/new', {method:'POST', body: data, credentials:'include'});
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            if(data.canUseMail) {
                this.isSubmit(this.state.mail)
            } else {
                this.errors.cannotUseMail = true
                this.forceUpdate()
            }
        })
    }


    render() {

        const emailRegex = /\S+@\S+\.\S+/;

        const validateEmail = (email) => {
            this.errors.mail = !emailRegex.test(email);
        };

        return (
            <div>
                <Bandeau title={titleAriane["welcome"]["title"]}/>
                <FilAriane
                    links={[
                        [
                            titleAriane["welcome"]["ariane"][0],
                            ""
                        ]
                    ]}
                />
                <div className={styles.welcomeContainer}>
                    <div style={{flex: 1, flexDirection: "row", display: 'flex'}}>
                        <div className={styles.leftContainer}>
                        </div>
                        <div className={styles.rightContainer}>
                            <h2>
                                Profite dès maintenant de nos jeux éducatifs et de tous les services Atorika !
                            </h2>

                            Nous t'enverrons des informations sur tes commandes, le suivi de tes colis et ta progression.


                            <div className={formStyles.inputLine}>
                                <div className={`${formStyles.inputGroup} ${styles.inputGroup}`}>
                                    <label>
                                        E-mail*
                                    </label>
                                    <div className={formStyles.inputField} style={this.errors.mail ? {borderColor: 'red'} : {}}>
                                        <div className={formStyles.inputIcon}>
                                            <FontAwesomeIcon icon={faEnvelope}/>
                                        </div>
                                        <input
                                            type="text"
                                            required
                                            className={formStyles.input}
                                            placeholder="prenom.nom@exemple.fr"
                                            value={this.state.mail}
                                            onChange={(event) => {
                                                let mail = event.target.value;
                                                let cleanMail = mail.replace(/\s/g, "");
                                                cleanMail = cleanMail.toLowerCase();
                                                this.setState({mail: cleanMail});
                                                validateEmail(cleanMail)}
                                            }
                                        />
                                    </div>
                                </div>
                            </div>

                            {this.errors.cannotUseMail &&
                                <div className={formStyles.textDanger}>
                                    Cette adresse e-mail est déjà utilisée par un utilisateur.
                                </div>
                            }

                            <div className={formStyles.inputLineSame} style={{alignItems: 'flex-start'}}>
                                <label>
                                    <input
                                        className={`${formStyles.checkbox} ${styles.checkbox}`}
                                        style={{marginTop: "3px"}}
                                        type={"checkbox"}
                                        checked={this.state.sendMail}
                                        onChange={(event) => this.setState({sendMail: event.target.checked})}
                                    />
                                    Je souhaite recevoir des nouvelles, des conseils et des offres par e-mail.
                                </label>
                            </div>

                            <div className={formStyles.btnContainer} style={{marginTop: "15px", marginBottom: "15px"}}>
                                <button
                                    type="button"
                                    className={!this.errors.mail && this.state.mail.length > 0 ? globalStyles.defaultBtn : formStyles.disabledBtn}
                                    onClick={this.goNext}
                                    disabled={this.errors.mail || this.state.mail.length === 0}
                                >
                                    Continuer
                                </button>
                            </div>


                            <p style={{textAlign: 'center'}}>
                                En cliquant sur "Continuer", tu confirmes avoir lu et accepté nos <a href={process.env.REACT_APP_SHOP_URL + "/terms"} className={globalStyles.defaultLink}>
                                conditions générales d'utilisation et de vente</a> et notre <a href={ process.env.REACT_APP_SHOP_URL + "/policy"} className={globalStyles.defaultLink}>politique de confidentialité</a>.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        );
    };

}

export default Welcome;
