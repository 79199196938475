import React from "react"
import LoggedComponent, { mapStateToProps } from "../../Services/ConnexionService";
import { connect } from "react-redux";
import globalStyles from "../../Styles/Style.module.css";
import FilAriane from "../Header_Footer/FilAriane";
import Bandeau from "../Header_Footer/Bandeau";
import styles from "../Profile/Profile.module.css";
import CardTemplateCommand from "./CardTemplateCommand";
import moment from "moment";
import titleAriane from "../../Helpers/TitleAriane.json";
import { encode } from "../../Services/ObfuscatorService";
import formStyles from "../../Styles/FormStyle.module.css";


class Commands extends LoggedComponent {

    constructor(props) {
        super(props);
        this.state = {
            commands: []
        }
        this.loading = true;
    }

    doesMustLog = () => {
        this.mustLog = 1
    }

    getCommands() {
        let request = new Request(process.env.REACT_APP_API_URL + '/client/order/commands', { method: 'GET', credentials: 'include' });
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({ commands: data });
            this.loading = false;
            this.forceUpdate();
        })
    }

    displayCommands = (nbCommands) => {
        let commands = []
        let total = this.state.commands.length;

        let missing = (nbCommands - total % nbCommands) % nbCommands;
        let currentLine = 0;
        for (let i = 0; i < total; i++) {
            if (i >= nbCommands * currentLine + nbCommands) {
                commands.push(<div className={styles.break} />)
                currentLine++;
            }

            commands.push(
                <div className={styles.childCard} style={{
                    marginLeft: i % nbCommands === 0 ? "0" : "10px",
                    marginRight: i % nbCommands === nbCommands - 1 ? "0" : "10px",
                }}>
                    <CardTemplateCommand
                        identifier={this.state.commands[i].identifier}
                        state={this.state.commands[i].state}
                        stateNb={this.state.commands[i].stateNb}
                        color={this.state.commands[i].stateNb === 1 || this.state.commands[i].stateNb === 2 ? "white" : undefined}
                        price={this.state.commands[i].totalTtc / 100}
                        infos={[
                            moment(this.state.commands[i].dateValidated.date).format("DD/MM/YYYY"),
                            this.state.commands[i].number === 1 ? this.state.commands[i].number + " article" : this.state.commands[i].number + " articles",
                            this.state.commands[i].delivery.typeName
                        ]}
                        links={[["Afficher les détails", "/command/" + encode(this.state.commands[i].id) + "/view"]]}
                    />
                </div>
            )
        }



        for (let i = 0; i < missing; i++) {
            commands.push(
                <div style={{ flex: 1, display: "flex", flexDirection: "row", margin: "5px" }}>
                </div>
            )
        }
        return commands
    }


    renderChild() {
        return (
            <div>
                <Bandeau title={titleAriane["commands"]["title"]} />
                <FilAriane
                    links={[
                        [
                            titleAriane["commands"]["ariane"][0],
                            "/"
                        ],
                        [
                            titleAriane["commands"]["ariane"][1],
                            ""
                        ]
                    ]}
                />

                {this.loading ? (
                    <>
                        {this.getCommands()}
                        <div className="loader"><div></div></div>
                    </>)
                    :
                    <div className={globalStyles.globalContainer}>


                        {this.state.commands.length > 0 ?
                            <div className={styles.cardContainer} style={{ flexWrap: "wrap", margin: window.innerWidth > 960 ? "auto -10px" : "auto 0px", padding: window.innerWidth > 960 ? "unset" : "10px" }}>
                                {window.innerWidth > 960 ?
                                    this.displayCommands(3) :
                                    this.displayCommands(1)
                                }
                            </div>
                            :
                            <div style={{ flex: 1 }}>
                                <div
                                    className={globalStyles.card}
                                    style={{
                                        padding: "30px",
                                        flex: 1,
                                        backgroundColor: "#f3f6f8",
                                        boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                        borderRadius: "5px",
                                        display: "flex",
                                        height: "100%",
                                        boxSizing: "border-box",
                                        flexDirection: "row",
                                        marginLeft: "10px",
                                        marginRight: "-10px",
                                        marginBottom: "20px",
                                        marginTop: "10px"
                                    }}
                                >
                                    Tu n'as encore rien commander pour l'instant.
                                </div>

                                <div className={formStyles.btnContainer}>
                                    <a
                                        className={globalStyles.defaultBtn}
                                        href={process.env.REACT_APP_SHOP_URL}
                                    >
                                        Découvrir les produits Atorika
                                    </a>
                                </div>

                            </div>
                        }



                    </div>
                }
            </div>
        );

    }
}

export default connect(mapStateToProps)(Commands);
