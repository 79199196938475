import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBox, faLock} from "@fortawesome/free-solid-svg-icons";
import LoggedComponent, {mapStateToProps} from "../../../Services/ConnexionService";
import FilAriane from "../../Header_Footer/FilAriane";
import globalStyles from "../../../Styles/Style.module.css";
import formStyles from '../../../Styles/FormStyle.module.css';
import {connect} from "react-redux";
import Bandeau from "../../Header_Footer/Bandeau";
import styles from "./Code.module.css";
import titleAriane from "../../../Helpers/TitleAriane.json";


class ActivationCode extends LoggedComponent {

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            code: '',
            reference: '',
            userFor: null,
            error: false,
            errorMsg : ''
        };
        this.users = []
    }

    doesMustLog = () => {
        this.mustLog = 1
    }

    submit = () => {
        let data = {
            reference: this.state.reference,
            code : this.state.code,
        }
        let jsonD = new FormData()
        jsonD.append("json", JSON.stringify(data))
        let request = new Request(process.env.REACT_APP_API_URL + '/client/user/box/activation/' + this.state.user.id, {method:'POST', body: jsonD, credentials:'include'})
        this.fetchJsonOrError(request, (data) => {
            switch (data.type) {
                case 'OK':
                    this.addFlash('success', 'La box a bien été activée !')
                    this.setState({shouldNavigate: true, navigateTo:'/'})
                    break
                case 'NOBOX':
                    this.setState({...this.state, error: true, errorMsg : "Nous n'avons pas trouvé cette box"})
                    break
                default:
                    this.setState({...this.state, error: true, errorMsg : "Nous n'avons pas trouvé ce code"})
            }
        })
    }

    renderChild () {

        return (
            <div>
                <Bandeau title={titleAriane["activateBox"]["title"]}/>
                <FilAriane
                    links={[
                        [
                            titleAriane["activateBox"]["ariane"][0],
                            "/"
                        ],
                        [
                            titleAriane["activateBox"]["ariane"][1],
                            "/box"
                        ],
                        [
                            titleAriane["activateBox"]["ariane"][2],
                            ""
                        ],
                    ]}
                />

                <div className={globalStyles.globalContainer}>
                    <div style={{flex: 1}}>

                        <div style={{margin: window.innerWidth < 1020 ? "0 10px" : undefined}}>
                            <h2>Tu as acheté une box hors ligne ou l'as reçue en cadeau ?</h2>

                            Pour accéder aux tutoriels des activités liées à cette box, tu dois tout d'abord l'activer.<br/>
                            Pour cela, remplis les informations ci-dessous.
                        </div>

                        <div className={formStyles.inputLine} style={{margin: "20px 0"}}>
                            <div className={formStyles.inputGroup}>
                                <label>Référence de la box*</label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon} style={{flex: 2}}>
                                        <FontAwesomeIcon icon={faBox}/>
                                    </div>
                                    <input
                                        type={"text"}
                                        required
                                        className={formStyles.input}
                                        placeholder="Référence de la box"
                                        value={this.state.reference}
                                        onChange={(event) => {
                                            this.setState({reference: event.target.value});
                                        }}
                                    />
                                </div>
                            </div>

                            <div className={formStyles.inputGroup}>
                                <label>Code d'activation*</label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon} style={{flex: 2}}>
                                        <FontAwesomeIcon icon={faLock}/>
                                    </div>
                                    <input
                                        type={"text"}
                                        required
                                        className={formStyles.input}
                                        placeholder="Code d'activation"
                                        value={this.state.code}
                                        onChange={(event) => {
                                            this.setState({code: event.target.value.toUpperCase()});
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div style={{textAlign: "right", marginRight: window.innerWidth < 1020 ? "10px" : undefined}}>
                             <a
                                 className={globalStyles.defaultLink}
                                 href={process.env.REACT_APP_HELP_URL + "/article/ou-trouver-les-informations-pour-activer-une-box"}
                                 target={"_blank"} rel={"noopener noreferrer"}
                             >Où trouver ces informations ? </a>
                        </div>

                        {this.state.error &&
                            <div className={styles.error}>
                                {this.state.errorMsg}
                            </div>
                        }

                        <div className={formStyles.btnContainer} style={{marginTop: "30px"}}>
                            <button type="button" className={globalStyles.defaultBtn} onClick={this.submit}>
                                Activer
                            </button>
                        </div>


                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(ActivationCode);
