import React, { useState, useEffect } from "react";
import globalStyles from "../../../Styles/Style.module.css";
import formStyles from "../../../Styles/FormStyle.module.css";
import cb from "../../../assets/footer/pay/cb.png"
import mastercard from "../../../assets/footer/pay/mastercard.png"
import visa from "../../../assets/footer/pay/visa.png"
import relaiscolis from "../../../assets/footer/delivery/relaiscolis.png"
import colissimo from "../../../assets/footer/delivery/colissimo.png"
import mondialrelay from "../../../assets/footer/delivery/mondialrelay.png"
import ReactTooltip from "react-tooltip"

function Step(props) {

    const [isMobile, setIsMobile] = useState(true);

    useEffect(() => {
        setIsMobile(window.innerWidth <= 768)
    }, []);

    const getReste = (totalArticles) => {
        return props.dataBasket.slice(0,3).reduce(function (s, a) {
            return s - a.qty;
        }, totalArticles);
    }

 
    return (
        <div className={globalStyles.containerOneStep}>
            <div className={` ${globalStyles.stepCircleText}`}>
                    <div className={globalStyles.stepText}>
                        <div
                            className={`${globalStyles.card} ${globalStyles.titleStep} ${props.btnNext || props.btnPrevUrl ? globalStyles.containerWithBtn : ""}`}
                            style={{
                                flex: "0.7 1 0%",
                                backgroundColor: "#f3f6f8",
                                boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                borderRadius: "5px",
                                boxSizing: "border-box",
                                flexDirection: "row",
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "10px",
                                marginTop: "10px",
                            }}
                        >
                            <div className={globalStyles.containerTextImage}>
                                <div className={globalStyles.containerTitleIcon}>
                                    <div className={globalStyles.oneStepText}>{props.text}</div>
                                    {(!isMobile || ( !props.btnNext && !props.btnPrevUrl)) && (
                                        props.image === "paiement" ? (
                                            <div className={`${globalStyles.imageItemContainer} ${props.state === "grey" ? globalStyles.greyImage : ""}`}>
                                                <div className={globalStyles.imageContainer}>
                                                    <img src={cb} alt="Carte bancaire" />
                                                </div>
                                                <div className={globalStyles.imageContainer}>
                                                    <img src={mastercard} alt="MasterCard" />
                                                </div>
                                                <div className={globalStyles.imageContainer}>
                                                    <img src={visa} alt="Visa" />
                                                </div>
                                            </div>
                                        ) : props.image === "livraison" ? (
                                            <div className={`${globalStyles.imageItemContainer} ${props.state === "grey" ? globalStyles.greyImage : ""}`}>
                                                <div className={globalStyles.imageContainer}>
                                                    <img src={relaiscolis} alt="Relais Colis" />
                                                </div>
                                                <div className={globalStyles.imageContainer}>
                                                    <img src={colissimo} alt="Colissimo" />
                                                </div>
                                                <div className={globalStyles.imageContainer}>
                                                    <img src={mondialrelay} alt="Mondial Relay" />
                                                </div>
                                            </div>
                                        ) : null
                                    )}

                                </div>

                                {props.dataBasket && props.dataBasket.length > 0 && !isMobile && (
                                    <div className={globalStyles.containerImage}>
                                        {props.dataBasket.length === 1 ? (
                                            // Cas où il n'y a qu'un seul article (affichage du media + name)
                                            <div className={globalStyles.singleItem}>
                                                <img className={globalStyles.contenuImage} src={process.env.REACT_APP_CDN_URL + '/uploads/article/' + props.dataBasket[0].media} alt="Product" />
                                                <span className={globalStyles.articleName}>{props.dataBasket[0].name}</span>
                                            </div>
                                        ) : props.dataBasket.length <= 3 ? (
                                            // Cas où il y a 2 ou 3 articles (affichage des médias seulement)
                                            <div className={globalStyles.singleItem}>
                                                {props.dataBasket.map((item, index) => (
                                                    <div key={'articl'+index} style={{display: "inline-block", position: "relative", marginRight: "5px"}}>
                                                    <img className={globalStyles.contenuImage} key={index} src={process.env.REACT_APP_CDN_URL + '/uploads/article/' + item.media} alt={`Product ${index}`} />
                                                    {item.qty>1 && <span className={globalStyles.articleQty}>{item.qty}</span>}
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            // Cas où il y a plus de 3 articles (affichage des 3 premiers médias + texte supplémentaire)
                                            <div className={globalStyles.singleItem}>
                                                {props.dataBasket.slice(0, 3).map((item, index) => (
                                                    <div style={{display: "inline-block", position: "relative", marginRight: "5px"}}>
                                                    <img className={globalStyles.contenuImage} key={index} src={process.env.REACT_APP_CDN_URL + '/uploads/article/' + item.media} alt={`Product ${index}`} />
                                                    {item.qty>1 && <span className={globalStyles.articleQty}>{item.qty}</span>}
                                                    </div>
                                                ))}
                                                <span className={globalStyles.articleName}>
                                                    {`+ ${getReste(props.totalArticles)} article${getReste(props.totalArticles) > 1 ? "s" : ""} en plus`}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div className={globalStyles.containerImage}>
                                    {props.dataDelivery && !isMobile ?
                                        <div style={{ flex: 4, flexDirection: "column", fontWeight: "normal", fontSize: ".8rem" }}>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                <div style={{ flex: 2 }}>
                                                    <div style={{ margin: "0", color: "black", fontWeight: "500" }}>
                                                        {props.dataDelivery.typeName}
                                                    </div>
                                                </div>
                                            </div>

                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <div style={{ color: "black" }}>
                                                    {props.dataDelivery.firstName + " " + props.dataDelivery.lastName}
                                                </div>
                                                {props.dataDelivery.type === 1 &&
                                                    <div style={{ color: "black" }}>
                                                        {props.dataDelivery.relayName}
                                                    </div>
                                                }
                                                {props.dataDelivery.type === 2 &&
                                                    <div style={{ color: "black" }}>
                                                        {props.dataDelivery.postCode + " " + props.dataDelivery.city + " - " + props.dataDelivery.country}
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        : ""}
                                </div>

                            </div>

                            {props.btnNext && (
                                <div className={globalStyles.tooltipContainer}>
                                    <button
                                        onClick={(e) => {
                                            if (props.onValidate) props.onValidate();
                                        }}
                                        disabled={(props.disabledBtn ? "disabled" : "")}
                                        className={`${props.disabledBtn ? formStyles.disabledBtn : globalStyles.defaultBtn} ${globalStyles.littleBtn}`}
                                        style={{ marginRight: "3rem" }}
                                        data-tip={props.disabledBtn ? props.errorMessage : ""}
                                    >
                                        {props.loadingNextStep ? <div className={globalStyles.littleLoaderNextStep}></div> : <div className={globalStyles.nextStepText}>Continuer</div>}
                                    </button>
                                </div>
                            )}
                            {props.btnPrevUrl && (
                                <a
                                    href={props.btnPrevUrl}
                                    className={`${globalStyles.defaultLink} ${globalStyles.littleDefaultLink}`}
                                >
                                    {props.btnPrevText ? props.btnPrevText : "étape précédente"}
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            <div
                className={`${globalStyles.stepLine} 
                        ${props.state === "green" ? globalStyles.stepLineGreen : 
                            props.state === "grey" ? globalStyles.stepLineGrey : 
                            props.state === "last" ? globalStyles.last : ""} 
                        `}
            >
                <div className={globalStyles.stepCircle}>
                    <div className={`${props.state === "green"
                        ? globalStyles.greenDots
                        : props.state === "grey"
                            ? globalStyles.greyDots
                            : globalStyles.purpleDots
                        }`}></div>
                </div>

                
            </div>
            {props.btnNext && <ReactTooltip  multiline={true} place="top" effect="solid" />}
        </div>
    );
}

export default Step;
