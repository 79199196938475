import React, { Component } from 'react';
import {
    Routes,
    Route,
} from "react-router-dom";
import Registration from './components/Registration/Registration';
import Login from "./components/Login_Logout/Login";
import Home from "./components/Home/Home";
import NotFound from "./components/NotFound";
import Logout from "./components/Login_Logout/Logout";
import LoginLongOTP from "./components/Login_Logout/LoginLongOTP"
import Empty from "./components/Empty";
import GetIdProfileEdit from "./components/Profile/GetIdProfileEdit";
import GetIdPwdReset from "./components/Profile/GetIdPwdReset";
import AddChild from "./components/Profile/AddChild";
import ActivationCode from "./components/Box/Activation/ActivationCode";
import Addresses from "./components/Profile/Addresses";
import GetIdAddressEdit from "./components/Profile/GetIdAddressEdit";
import AddAddress from "./components/Profile/AddAddress";
import AddItem from "./components/Shopping/Basket/AddItem/AddItem";
import ResumeBasket from "./components/Shopping/Basket/ResumeBasket";
import Commands from "./components/Command/Commands";
import AddItemReference from './components/Shopping/Basket/AddItem/AddItemReference'
import GetIdDelivery from "./components/Shopping/Delivery/GetIdDelivery";
import GetIdCommand from "./components/Command/GetIdCommand";
import GetIdFinish from "./components/Shopping/GetIdFinish";
import GetIdTracking from "./components/Command/GetIdTracking";
import GetIdPayment from "./components/Shopping/Payment/GetIdPayment";
import UnloggedAddItem from "./components/Shopping/Basket/AddItem/UnloggedAddItem";
import GetIdCommitmentChoose from "./components/Commitment/GetIdCommitmentChoose"
import Box from "./components/Box/Box";
import Commitment from "./components/Commitment/List/Commitment";
import GetIdUserCommitment from "./components/Commitment/List/GetIdUserCommitment";
import GetMailRegistration from "./components/Registration/GetMailRegistration";
import Stats from "./components/Stats/Stats";
import GetIdProfileDelete from "./components/Profile/GetIdProfileDelete";
import Pictures from "./components/Stats/Pictures";
import UnloggedValidateBasket from './components/Shopping/Basket/AddItem/UnloggedValidateBasket';


class App extends Component {
    render() {
        return (
            <Routes>
                <Route path="/login" element={<Login/>}/>
                <Route path="/login/otp/:token" element={<LoginLongOTP/>}/>

                <Route path="/register" element={<Registration/>}/>
                <Route path="/register/:mail" element={<GetMailRegistration/>}/>

                {/*<Route path="/profile" element={<Profile/>}/>*/}
                <Route path="/resetpwd/:id" element={<GetIdPwdReset/>}/>
                <Route path="/edit/:id" element={<GetIdProfileEdit/>}/>
                <Route path="/delete/:id" element={<GetIdProfileDelete/>}/>
                <Route path="/addchild" element={<AddChild/>}/>
                <Route path="/address" element={<Addresses/>}/>
                <Route path="/address/new" element={<AddAddress/>}/>
                <Route path="/address/:id/edit" element={<GetIdAddressEdit/>}/>
                <Route path="/activation" element={<ActivationCode/>}/>

                <Route path="/commands" element={<Commands/>}/>
                <Route path="/command/:id/view" element={<GetIdCommand/>}/>
                <Route path="/:id/tracking" element={<GetIdTracking/>}/>

                <Route path="/commitment" element={<Commitment/>}/>
                <Route path="/commitment/choose/:id" element={<GetIdCommitmentChoose/>}/>
                <Route path="/commitment/:id" element={<GetIdUserCommitment/>}/>

                <Route path="/additem/:reference" element={<AddItemReference/>}/>
                <Route path="/basket/additem" element={<AddItem/>}/>
                <Route path="/additem" element={<UnloggedAddItem/>}/>
                <Route path="/validatebasket" element={<UnloggedValidateBasket/>}/>
                <Route path="/basket" element={<ResumeBasket/>}/>
                <Route path="/delivery/:id" element={<GetIdDelivery/>}/>
                <Route path="/payment/:id" element={<GetIdPayment/>}/>
                <Route path="/finish/:id" element={<GetIdFinish/>}/>

                <Route path="/box" element={<Box/>}/>

                <Route path="/stats" element={<Stats/>}/>
                <Route path="/pictures" element={<Pictures/>}/>

                <Route path="/logout" element={<Logout/>}/>
                <Route path="/none" element={<Empty/>}/>
                <Route path="/" element={<Home/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        );
    }
}

export default App;
