import React from 'react';
import PersonnalInformation from "./Step2/PersonnalInformation";
import Shipping from "./Step3/Shipping";
import {Navigate} from "react-router-dom";
import LoggedComponent, {mapStateToProps} from "../../Services/ConnexionService";
import Welcome from "./Step1/Welcome";
import ConfirmMail from "./Step4/ConfirmMail";
import {connect} from "react-redux";
import {withUserAgent} from "react-useragent";
import Cookies from 'universal-cookie';

const cookieOptions = { maxAge: `${process.env.REACT_APP_COOKIE_BASKET_MAX_AGE}`, path: '/', domain: `${process.env.REACT_APP_COOKIE_DOMAIN}` }
const cookies = new Cookies(null, cookieOptions);
class Registration extends LoggedComponent {

    constructor(props) {
        super(props);
        this.step = 1;
        this.persoInfo = {};
        this.shipping = {};
    }

    doesMustLog = () => {
        this.mustLog = -1
    }

    register = () => {
        let data = new FormData();
        let jsonData = {};
        let key;
        for (key in this.persoInfo) {
            if(this.persoInfo.hasOwnProperty(key)){
                jsonData[key] = this.persoInfo[key];
            }
        }

        for (key in this.shipping) {
            if(this.shipping.hasOwnProperty(key)){
                jsonData[key] = this.shipping[key];
            }
        }
        jsonData["userAgent"] = this.props.ua.md.ua;
        jsonData["isShopping"] = (this.props.item.item.length !== 0)

        data.append('json', JSON.stringify(jsonData));
        let request = new Request(process.env.REACT_APP_API_URL + '/client/register', {method:'POST', body: data, credentials: 'include'});
        this.fetchJsonOrError(request, (json) => {
            if(json["created"]){
                if(json["connected"]) {
                    let jsonD = new FormData();
                    jsonD.append("json", JSON.stringify(cookies.get('basketQuantity')));
                    let request = new Request(process.env.REACT_APP_API_URL + '/client/basket/create', { method: 'POST', credentials: 'include', body: jsonD });
                    fetch(request).then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        this.setState({shouldNavigate: true, navigateTo: '/basket'})
                    })
                }
                else{
                    this.step = 4;
                    this.forceUpdate()
                }
            }
        })
    }

    isSubmitWelcome = (mail) => {
        this.mail = mail;
        this.step = 2;
        this.forceUpdate();
    }

    isSubmitPersoInfo = (persoInfo) => {
        this.persoInfo = persoInfo;
        this.step = 3;
        this.forceUpdate();
    }

    isSubmitShipping = (shipping) => {
        this.shipping = shipping;
        this.shipping.ignoreAddress = false;
        this.forceUpdate();
        this.register();
    }

    ignore = () => {
        this.shipping.ignoreAddress = true;
        this.forceUpdate();
        this.register();
    }

    previous = (shipping) => {
        this.shipping = shipping;
        this.step = 2;
        this.forceUpdate();
    }

    redirect = () => {
        return <Navigate to={this.state.redirectTo}/>
    }


    renderChild() {
        return (
            <div>

                {this.step === 1 &&
                <Welcome
                    isSubmit={this.isSubmitWelcome.bind(this)}
                    mail={this.props.mail}
                />
                }

                {this.step === 2 &&
                <PersonnalInformation
                    isSubmit={this.isSubmitPersoInfo.bind(this)}
                    data={this.persoInfo}
                    mail={this.mail}
                />
                }

                {this.step === 3 &&
                <Shipping
                    isSubmit={this.isSubmitShipping.bind(this)}
                    ignore={this.ignore.bind(this)}
                    data={this.shipping}
                    previous={this.previous.bind(this)}
                    nameChild={this.persoInfo.nbChildren > 0 ?  this.persoInfo.children[0].name : this.persoInfo.nameParent}
                    firstNameChild={this.persoInfo.nbChildren > 0 ? this.persoInfo.children[0].firstName : this.persoInfo.firstNameParent}
                    nameParent={this.persoInfo.nameParent}
                    firstNameParent={this.persoInfo.firstNameParent}
                />
                }

                {this.step === 4 &&
                <ConfirmMail
                    firstName={this.persoInfo.firstNameParent}
                    mail={this.persoInfo.mailParent}
                />
                }

            </div>
        );
    };

}

export default connect(mapStateToProps)(withUserAgent(Registration));
