import React from "react";
import globalStyles from '../../Styles/Style.module.css';

function CardHomeChildInfo(props) {

    return (
        <div className={`${globalStyles.card} ${globalStyles.cardMore}`} style={{...props.style, backgroundColor: "#f3f6f8"}}>
            <div style={{flex: 5}}>
                <h2 style={{margin: "0 0 15px 0"}}>
                    {props.name}
                </h2>

                <div style={{flexDirection: "column", marginTop: "15px", marginBottom: "15px"}}>
                    <div style={{
                        backgroundColor: props.color,
                        marginBottom: "10px",
                        borderRadius: "5px",
                        fontSize: "0.8em",
                        padding: "2px 10px",
                        display: "inline-flex",
                        color:"white",
                        fontWeight: 600
                    }}>
                        {props.commitment}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardHomeChildInfo;
