import React from 'react';
import fr from 'date-fns/locale/fr';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChild,
    faEnvelope,
    faVenus,
    faIdBadge, faInfo,
    faMars,
    faPassport,
    faQuestionCircle, faSchool,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import LoggedComponent, {mapStateToProps} from "../../Services/ConnexionService";
import FilAriane from "../Header_Footer/FilAriane";
import globalStyles from "../../Styles/Style.module.css";
import formStyles from '../../Styles/FormStyle.module.css';
import {connect} from "react-redux";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {Stack} from "@mui/material";
import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import Bandeau from "../Header_Footer/Bandeau";
import titleAriane from "../../Helpers/TitleAriane.json";
import {encode} from "../../Services/ObfuscatorService";

class EditProfile extends LoggedComponent {

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            id: props.id,
            isChild: false,
            lastName: '',
            firstName: '',
            username: '',
            birth: '',
            gender: 'F',
            mail: '',
            errors : {
                mail: null,
                cannotUseMail: false,
                cannotUseUsername: false
            }
        };
        this.isChild = false;
        this.isLoading = true;
    }

    doesMustLog = () => {
        this.mustLog = 1
    }

    edit = () => {
        let data = new FormData();
        data.append('json', JSON.stringify({...this.state, email: this.state.mail, userId: this.state.id}));
        let request = new Request(process.env.REACT_APP_API_URL + '/client/user/edit/' + this.state.id, {method:'POST', body: data, credentials:'include'});
        this.fetchJsonOrError(request, (data) => {
            if(data.canUseMail && data.edited && data.canUseUsername){
                this.setState({shouldNavigate: true, navigateTo: '/'});
            } else {
                this.setState({errors: {...this.state.errors, cannotUseMail: !data.canUseMail, cannotUseUsername: !data.canUseUsername}})
            }
        })

    }

    getAge = (date) => {
        if(date){
            let today = new Date();
            let birthDate = new Date(date);
            let age = today.getFullYear() - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        }
        return NaN;
    }


    renderChild() {

        const emailRegex = /\S+@\S+\.\S+/;

        if(this.isLoading){
            let user = null;

            if(this.state.user.id === this.state.id){
                user = this.state.user;
            } else {
                for(let child in this.state.children){
                    if(this.state.children[child].user.id === this.state.id){
                        user = this.state.children[child].user;
                        this.isChild = true
                    }
                }
            }

            this.isLoading = false
            this.setState({
                lastName: user.lastName,
                firstName: user.firstName,
                birth: new Date(user.birthDate.date.replace(" ", "T")),
                gender: user.genderLetter,
                mail: user.email ? user.email : '',
                username: user.username,
                isChild: this.isChild,
                isEntity: user.isEntity,
            });

            if(user.isEntity){
                this.setState({
                    entityName: user.entityName,
                    entitySiret: user.entitySiret
                })
            }

        }


        const validateEmail = (event) => {
            const email = event.target.value;
            this.state.errors.mail = !emailRegex.test(email);
        };

        const formValid = () => {
            let infosPerso = (this.state.lastName.length > 0) && (this.state.firstName.length > 0) && (this.state.birth !== '') && (!isNaN(this.getAge(this.state.birth)) && this.getAge(this.state.birth) < 100);

            if(!this.isChild){
                let infos = (this.state.mail.length > 0) && !this.state.errors.mail;
                return infos && infosPerso;
            }

            return infosPerso;
        }



        return (
            <div>
                <Bandeau title={titleAriane[this.isChild ? "editChild" : "edit"]["title"].replace("_name_", this.state.firstName)}/>
                <FilAriane
                    links={[
                        [
                            titleAriane[this.isChild ? "editChild" : "edit"]["ariane"][0],
                            "/"
                        ],
                        [
                            titleAriane[this.isChild ? "editChild" : "edit"]["ariane"][1],
                            ""
                        ]
                    ]}
                />

                <div className={globalStyles.globalContainer}>
                    <div style={{flex: 1}}>

                        <h2 style={{marginLeft: "10px"}}>{this.isChild ? "Modifier le profil" : "Modifier ton profil"}</h2>

                        {this.isChild &&
                            <div className={globalStyles.bulle}>
                                <span className={globalStyles.iconBulle}>
                                    <FontAwesomeIcon icon={faInfo}/>
                                </span>
                                <span className={globalStyles.iconText}>
                                    En créant un nouveau profil, tu pourras suivre les progrès de la personne que tu ajoutes via ses propres points d'expériences et badges.
                                    Si tu renseignes une adresse email, ou un nom d'utilisateur et un mot de passe, cette personne pourra se connecter sur ce site et sur l'application mobile de manière totalement autonome.
                                </span>
                            </div>
                        }

                        {this.state.isEntity &&
                            <div className={formStyles.inputLine}>
                                <div className={formStyles.inputGroup}>
                                    <label>Nom de la structure</label>
                                    <div className={formStyles.inputField}>
                                        <div className={formStyles.inputIcon}>
                                            <FontAwesomeIcon icon={faSchool}/>
                                        </div>
                                        <input type="text" required className={formStyles.input} placeholder="Nom de la structure" disabled={true} value={this.state.entityName} onChange={(event) => this.setState({lastName: event.target.value})}/>
                                    </div>
                                </div>

                                <div className={formStyles.inputGroup}>
                                    <label>SIRET</label>
                                    <div className={formStyles.inputField}>
                                        <div className={formStyles.inputIcon}>
                                            <FontAwesomeIcon icon={faPassport}/>
                                        </div>
                                        <input type="text" required className={formStyles.input} placeholder="SIRET" disabled={true} value={this.state.entitySiret} onChange={(event) => this.setState({firstName: event.target.value})}/>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroup}>
                                <label>Nom*</label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faIdBadge}/>
                                    </div>
                                    <input type="text" required className={formStyles.input} placeholder="Nom" value={this.state.lastName} onChange={(event) => this.setState({lastName: event.target.value})}/>
                                </div>
                            </div>

                            <div className={formStyles.inputGroup}>
                                <label>Prénom*</label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faChild}/>
                                    </div>
                                    <input type="text" required className={formStyles.input} placeholder="Prénom" value={this.state.firstName} onChange={(event) => this.setState({firstName: event.target.value})}/>
                                </div>
                            </div>
                        </div>

                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroupBirthGender}>
                                <div className={formStyles.inputGroupGender}>
                                    <label>Sexe*</label>
                                    <div className={formStyles.inputContent}>
                                        <div className={formStyles.gender}>
                                            <button
                                                type="button"
                                                onClick={() => this.setState({gender: "F"})}
                                                className={this.state.gender === "F" ? `${formStyles.btnSelected} ${formStyles.btnFemale}` : `${formStyles.btnNotSelected} ${formStyles.btnFemale}`}
                                            >
                                                <FontAwesomeIcon icon={faVenus}/>
                                            </button>
                                            <button type="button" onClick={() => this.setState({gender: "M"})}  className={this.state.gender === "M" ? `${formStyles.btnSelected} ${formStyles.btnMale}` : `${formStyles.btnNotSelected} ${formStyles.btnMale}`}>
                                                <FontAwesomeIcon icon={faMars}/>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className={formStyles.inputGroupBirth}>
                                    <div style={{marginBottom: "5px"}}>
                                        <label>Date de naissance*</label>
                                        {!isNaN(this.getAge(this.state.birth)) &&
                                            (this.getAge(this.state.birth) > 100 ?
                                                    <span style={{fontSize: "12px", color: "red", marginLeft: "5px"}}> {this.getAge(this.state.birth)} ans</span>
                                                    :
                                                    <span style={{fontSize: "12px", marginLeft: "5px"}}> {this.getAge(this.state.birth)} ans</span>
                                            )
                                        }
                                    </div>
                                    <div className={formStyles.inputField} style={{display: "inline", border: "none"}}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={fr}>
                                            <Stack spacing={3}>
                                                <DatePicker
                                                    label="Date de naissance"
                                                    value={this.state.birth}
                                                    openTo="year"
                                                    views={['year', 'month', 'day']}
                                                    onChange={(date) => {
                                                        if(date){
                                                            date.setHours(12);
                                                        }
                                                        this.setState({birth: date});
                                                    }}
                                                    renderInput={(params) => <TextField size={"small"} {...params} helperText={null} />}
                                                    enableFuture={false}
                                                    inputFormat={"dd/MM/yyyy"}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                    </div>
                                </div>

                            </div>


                            <div className={formStyles.inputGroup}>
                                <label>
                                    E-mail
                                    <FontAwesomeIcon
                                        className={formStyles.iconHelp}
                                        icon={faQuestionCircle}
                                        tabIndex={-1}
                                        data-tip="L'e-mail nous servira à te contacter <br/>pour le suivi de ta commande, <br/>la connexion à ton compte ..."
                                    />
                                </label>
                                <div className={formStyles.inputField} style={this.state.errors.mail ? {borderColor: 'red'} : {}} >
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faEnvelope}/>
                                    </div>
                                    <input
                                        type="text"
                                        required
                                        className={formStyles.input}
                                        placeholder="prenom.nom@exemple.fr"
                                        value={this.state.mail}
                                        onChange={(event) => {
                                            let cleanMail = event.target.value.replace(/\s/g, "");
                                            cleanMail = cleanMail.toLowerCase();
                                            this.setState({mail: cleanMail});
                                            validateEmail(event)}
                                    }
                                    />
                                </div>
                            </div>
                        </div>
                        {this.state.errors.cannotUseMail &&
                            <div className={formStyles.inputLine}>
                                <div className={formStyles.inputGroup}/>
                                <div className={formStyles.inputGroup}>
                                    <div className={formStyles.textDanger}>
                                        Cette adresse e-mail est déjà utilisé
                                    </div>
                                </div>
                            </div>
                        }

                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroup}>
                                <label>Nom d'utilisateur</label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faUser}/>
                                    </div>
                                    <input type="text" className={formStyles.input} placeholder="Nom d'utilisateur" value={this.state.username} onChange={(event) => this.setState({username: event.target.value.toLowerCase})}/>
                                </div>
                            </div>

                            <div className={formStyles.inputGroup}>

                            </div>
                        </div>

                        {this.state.errors.cannotUseUsername &&
                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroup}>
                                <div className={formStyles.textDanger}>
                                    Ce nom d'utilisateur est déjà utilisé
                                </div>
                            </div>
                            <div className={formStyles.inputGroup}/>
                        </div>
                        }



                        <div className={formStyles.btnContainer} style={{marginTop: "20px"}}>
                            <div style={{display: "flex", flex: 1}}>
                                <a className={globalStyles.defaultBtn}  style={{backgroundColor: "#ce3534"}} href={"/delete/" + encode(this.state.id)}>
                                    Supprimer
                                </a>
                            </div>

                            <div style={{display: "flex", flex: 1, justifyContent: "flex-end"}}>
                                <button type="button" className={formValid() ? globalStyles.defaultBtn : formStyles.disabledBtn} onClick={() => this.edit()} disabled={!formValid()}>
                                    Enregistrer
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
                <ReactTooltip multiline={true}/>
            </div>
        );
    };

}

export default connect(mapStateToProps)(EditProfile);
