import React from "react"
import LoggedComponent, { mapStateToProps } from "../../../../Services/ConnexionService";
import { connect } from "react-redux";
import globalStyles from "../../../../Styles/Style.module.css";
import FilAriane from "../../../Header_Footer/FilAriane";
import Bandeau from "../../../Header_Footer/Bandeau";
import AddItemTemplate from "./AddItemTemplate";


class UnloggedAddItem extends LoggedComponent {

    constructor(props) {
        super(props);
        this.hasAdded = false;
        this.hasResponded = false;
        this.itemsAdded = [];
        this.qts = {}
    }

    setIsAddItem() {
        this.isAddItem = false
    }

    doesMustLog = () => {
        this.mustLog = 0
    }

    addItem = () => {
        if (!this.hasAdded && !this.state.shouldNavigate) {
            this.hasAdded = true
            let id = 0;
            let item = "";
            let ids = this.props.item.ids
            let refs = []
            let qts = {}
            for (const i in ids) {
                id = ids[i]
                item = this.props.item.item['item' + id]
                if (refs.includes(item.reference)) {
                    qts[item.reference.split('-')[0]] += 1
                } else {
                    refs.push(item.reference)
                    qts[item.reference.split('-')[0]] = 1
                }
            }
            this.qts = qts
            let jsonD = new FormData()
            jsonD.append("json", JSON.stringify(refs))
            let request = new Request(process.env.REACT_APP_API_URL + '/client/basket/items', { method: 'POST', credentials: 'include', body: jsonD })
            this.fetchJsonOrError(request, (json) => {
                switch (json.type) {
                    case 'OK':
                        this.hasResponded = true
                        this.itemsAdded = json.added
                        this.forceUpdate()
                        break
                    case 'NONE':
                        this.hasResponded = true
                        this.itemsAdded = []
                        this.forceUpdate()
                        break
                    default:
                        this.goError()
                }
            })
        }
    }

    displayItems() {
        let items = []
        for (let i = 0; i < this.itemsAdded.length; i++) {
            let article = this.itemsAdded[i];

            let infos = [];

            if (article.duration && article.type === "commitment") {
                infos.push("Durée : " + article.duration + " mois")
            }
            infos.push(
                article.description
            )

            items.push(
                <AddItemTemplate
                    name={article.name}
                    media={article.media.length > 0 ? article.media[0] : undefined}
                    infos={infos}
                    priceTtc={article.priceTtc / 100}
                    unitPriceTtc={article.unitPriceTtc}
                    quantity={this.qts[article.reference]}
                    theme={article.theme}
                    secondaryTheme={article.secondaryTheme}
                    stock={article.stock}
                    isComm={article.isCommitment}
                    choosen={article.choosen}
                />
            )
        }

        return items

    }

    renderChild() {
        this.addItem()
        return (
            <div>
                <Bandeau title={"Panier"} />

                <FilAriane
                    links={
                        [
                            [
                                "Les ateliers",
                                process.env.REACT_APP_SHOP_URL
                            ],
                            [
                                "Ajouter au panier",
                                ""
                            ],
                        ]
                    }
                />
                {!this.hasResponded ? <div className="loader"><div></div></div>
                    :
                    <div className={globalStyles.globalContainer} style={{ flexDirection: "column", alignItems: "unset", padding: window.innerWidth > 960 ? "unset" : "10px", paddingTop: "10px" }}>
                        {this.displayItems()}

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row"
                            }}>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                textAlign: "left",
                                marginTop: "10px",
                                marginRight: window.innerWidth < 1020 ? "5px" : undefined
                            }}>
                                <a href={process.env.REACT_APP_SHOP_URL} className={globalStyles.secondaryBtn}>
                                    Continuer mes achats
                                </a>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flex: 1,
                                    justifyContent: "flex-end",
                                    alignItems: "flex-end",
                                    textAlign: "left",
                                    marginTop: "10px",
                                    marginLeft: window.innerWidth < 1020 ? "5px" : undefined
                                }}>
                                <a href={"/basket/additem"} className={globalStyles.defaultBtn} style={{ border: "1px solid #571088" }}>
                                    Valider mon panier
                                </a>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default connect(mapStateToProps)(UnloggedAddItem);
