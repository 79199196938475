import React, {useState} from "react";
import globalStyles from '../../../Styles/Style.module.css';
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function CardTemplateListPoints(props) {

    let [view, setView] = useState(false)

    let weekdays = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"]

    let getSchedule = () => {
        let schedule = []
        for(let i = 0; i < props.schedule.day.length; i++){
            schedule.push(weekdays[parseInt(props.schedule.day[i].weekday) - 1])
            schedule.push(" : ")
            if(
                props.schedule.day[i].open_am.length === 0 &&
                props.schedule.day[i].close_am.length === 0 &&
                props.schedule.day[i].open_pm.length === 0 &&
                props.schedule.day[i].close_pm.length === 0
            ){
                schedule.push("Fermé")
            } else if (
                props.schedule.day[i].open_pm.length === 0 &&
                props.schedule.day[i].close_pm.length === 0
            ) {
                schedule.push(props.schedule.day[i].open_am)
                schedule.push(" - ")
                schedule.push(props.schedule.day[i].close_am)
            } else {
                schedule.push(props.schedule.day[i].open_am)
                schedule.push(" - ")
                schedule.push(props.schedule.day[i].close_am)
                schedule.push(", ")
                schedule.push(props.schedule.day[i].open_pm)
                schedule.push(" - ")
                schedule.push(props.schedule.day[i].close_pm)
            }
            schedule.push(<br key={i}/>)
        }
        return schedule
    }

    return (
        <div
            style={{
                padding: "15px",
                flex: 1,
                background: props.selected ? "white" : "none",
                borderBottom: props.beforeSelect ? "none" : "1px solid #d3d3d3",
                borderTop: props.selected ? "1px solid #d3d3d3" : "none",
                borderRight: props.selected ? "1px solid #d3d3d3" : "none",
                borderLeft: props.selected ? "1px solid #d3d3d3" : "none",
                borderRadius: props.selected ? "10px" : 0,
            }}
        >
            <div style={{display: "flex"}}>
                <div style={{flex: 3}}>
                    <h5 style={{textAlign: "left", margin: 0}}>
                        {props.selected &&
                            <FontAwesomeIcon icon={faCheck} style={{marginRight: "15px", color: "#6fa939"}}/>
                        }
                        {props.title}
                    </h5>

                    <div style={{fontSize: "0.8rem"}}>
                        {props.address}
                        <br/>
                        {props.city}
                        <br/>
                        {props.country}
                    </div>
                </div>
                <div  style={{flex: 1, display: "flex", justifyContent: "flex-end"}}>
                    <img style={{height: "40px"}} src={require(`../../../assets/carriers/${props.carrier}`).default} alt={"Logo transporteur"}/>
                </div>
            </div>


            {view &&
            <div style={{fontSize: "0.8rem", marginTop: "10px"}}>
                HORAIRES<br/>

                {getSchedule()}
            </div>
            }

            <div style={{textAlign: "right", marginTop: "15px", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <button className={globalStyles.defaultLink} style={{padding: "10px", color: "#666"}} onClick={() => setView(!view)}>
                    {view ? "Masquer les horaires" : "Voir les horaires"}
                </button>

                {!props.selected &&
                    <button className={globalStyles.selectPointBtn} style={{marginRight: "5px"}} onClick={() => props.select(props.code)}>
                        Sélectionner
                    </button>
                }
            </div>



        </div>
    );
}

export default CardTemplateListPoints;
