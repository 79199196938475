import React, {useState} from "react";
import globalStyles from '../../../Styles/Style.module.css';
import moment from "moment";
import ThemeBadge from "../../Utils/ThemeBadge";
import ChooseBoxCard from "../ChooseBoxCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";

function CardBoxCommitment(props) {

    let [isOpen, setIsOpen] = useState(false);

    const displayInfos = (infos) => {
        let informations = []
        for(let i in infos){
            if(infos[i]){
                informations.push(
                    <div style={{marginBottom: "5px"}}>
                        {infos[i]}
                    </div>
                )
            }
        }
        return informations
    }

    return (
        <div
            className={globalStyles.card}
            style={{
                padding: "30px",
                flex: 1,
                backgroundColor: props.color ? props.color : "#f3f6f8",
                boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                borderRadius: "5px",
                display: "flex",
                height: "100%",
                boxSizing: "border-box",
                flexDirection: "row"
            }}
        >
            {props.media &&
                <div style={{flex: 1, display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <img
                        alt={props.title}
                        src={process.env.REACT_APP_CDN_URL + '/uploads/article/' + props.media}
                        style={{maxWidth: "100%", maxHeight: "100%", borderRadius: "8px"}}
                    />
                </div>
            }

            <div style={{flex: 2, marginLeft: props.media ? "10px" : "none"}}>
                <div>
                    <h3 style={{margin: "0 0 15px 0"}}>
                        {props.title}
                    </h3>

                    <ThemeBadge
                        theme={props.theme}
                        baseColor={props.themeBaseColor}
                        bgColor={props.themeColor}
                    />

                    {props.secondaryTheme &&
                        <ThemeBadge
                            theme={props.secondaryTheme}
                            baseColor={props.secondaryThemeBaseColor}
                            bgColor={props.secondaryThemeColor}
                            style={{marginLeft: "5px"}}
                        />
                    }


                    <div style={{flexDirection: "column", marginTop: "15px", marginBottom: "15px"}}>
                        {props.date &&
                            <div style={{marginBottom: "5px"}}>
                                {(new Date(props.date) >= new Date()) &&
                                    "A choisir avant le "
                                }
                                {moment(props.date).format("DD/MM/YYYY")}
                            </div>
                        }

                        {displayInfos(props.infos)}
                    </div>
                </div>

                {(new Date(props.date) >= new Date()) &&
                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "flex-end"
                        }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            textAlign: "left",
                            marginTop: "10px"
                        }}>
                            <button className={globalStyles.defaultLink} onClick={() => setIsOpen(true)}>
                                Changer
                            </button>
                        </div>
                    </div>
                }
            </div>

            <Modal
                isOpen={isOpen}
                bodyOpenClassName={"noScroll"}
                contentLabel="Choisir la prochaine box"
                style={{
                    content : {
                        margin: "40px auto",
                        zIndex: 500,
                        width: window.innerWidth > 1020 ? "70%" : "calc(100% - 60px)",
                        padding: window.innerWidth > 1020 ? "40px" : "20px",
                        maxHeight: window.innerWidth > 960 ? (window.innerHeight - 180) + "px" : (window.innerHeight - 80) + "px",
                        overflowY: "hidden",
                        display: "flex",
                        inset: 0,
                        borderRadius: "15px"
                    },
                    overlay : {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 1500,
                        backgroundColor: "rgba(0, 0, 0, 0.8)"
                    }
                }}
            >
                <ChooseBoxCard
                    setBox={props.setBox.bind(this)}
                    setBoxInfos={props.setBoxInfos.bind(this)}
                    oaid={props.oaid}
                    close={() => setIsOpen(false)}
                    dispatch={props.dispatch.bind(this)}
                />
                <button
                    style={{
                        position: "absolute",
                        top: "20px",
                        right: "20px",
                        background: "none",
                        border: "none",
                        fontSize: "20px",
                        cursor: "pointer"
                    }}
                    onClick={() => {setIsOpen(false)}}
                >
                    <FontAwesomeIcon icon={faTimes}/>
                </button>
            </Modal>
        </div>
    );
}

export default CardBoxCommitment;