import styles from './NotFound.module.css';
import React from "react"
import LoggedComponent, {mapStateToProps} from "../Services/ConnexionService";
import globalStyles from  '../Styles/Style.module.css';
import AtoRikaTristes from '../assets/mascottes/ato_rika_tristes.svg';
import {connect} from "react-redux";

class NotFound extends LoggedComponent {


    doesMustLog = () => {
        this.mustLog = 1
    }

    renderChild () {
        return (
            <div className={`${globalStyles.globalContainer} ${styles.container}`}>
                <div className={styles.subContainer}>
                    <div className={styles.robot}>
                        <img alt="" src={AtoRikaTristes} style={{maxWidth: "350px"}}/>
                    </div>
                    <div className={styles.infos}>
                        <h1 className={styles.title}>Oops !</h1>

                        <h2 className={styles.subTitle}>L'expérience a mal tourné avec cette page...</h2>

                        <p>
                            Il s’agit d’une erreur 404. <br/>
                            Vérifie si le lien que tu essaies d’ouvrir est correct. <br/>
                            Tu peux aussi aller sur la page d’accueil. <br/>
                        </p>
                        <div>
                            <a className={globalStyles.defaultBtn} href={"/"}>Aller à la page d'accueil</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(NotFound);
