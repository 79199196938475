import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartArrowDown} from "@fortawesome/free-solid-svg-icons";
import globalStyles from "../../../../Styles/Style.module.css";
import DisplayPrice from "../../../Utils/DisplayPrice";
import styles from "../CardTemplateBasket.module.css";

function AddItemTemplate(props) {

    let displayInfos = (infos) => {
        let informations = []
        for(let i in infos){
            informations.push(
                <div key={'info'+i} style={{marginTop: "10px"}}>
                    {infos[i]}
                </div>
            )
        }
        return informations
    }

    let displayStock = (stock, qt, isComm) => {
        if (isComm)
            return []
        if(stock < qt) {
            return (
                <span className={globalStyles.badge} style={{backgroundColor:"#52BAEC"}}>
                    En précommande
                </span>
            )
        }
        if(stock < 10) {
            return (
                <span className={globalStyles.badge} style={{backgroundColor:"#f39200"}}>
                    Moins de 10 en stock
                </span>
            )
        }
        return (
            <span className={globalStyles.badge} style={{backgroundColor:"#6fa939"}}>
                En stock
            </span>
        )
    }


    let formatTitle = (title, reference) => {

        return title
            .toLowerCase() // Convertit en minuscules
            .normalize('NFD') // Normalise pour séparer les caractères accentués de leurs accents
            .replace(/[\u0300-\u036f]/g, '') // Supprime les accents
            .replace(/\s+/g, '-') // Remplace les espaces par des tirets
            .concat(`-${reference}`); // Ajoute la référence à la fin
    }

    return (
        <div
            className={globalStyles.card}
            style={{
                padding: "30px",
                backgroundColor: "white",
                boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                borderRadius: "5px",
                display: "flex",
                boxSizing: "border-box",
                flexDirection: "column",
                flex: 2,
                marginBottom: "0px",
            }}
        >
            <div style={{flex: 5}}>
            <a className={styles.linkTitle} href={process.env.REACT_APP_SHOP_URL + "/produit/" + formatTitle(props.name, props.reference)}>
            <h3 style={{margin: "0 0 15px 0" , textDecoration: 'none', color: 'black'}}>
                    <FontAwesomeIcon icon={faCartArrowDown} style={{marginRight: "15px"}}/>
                    {props.name}
                </h3>
                </a>

                <div style={{flexDirection: window.innerWidth > 1020 ? "row" : "column", display: "flex"}}>
                    {props.media &&
                    <div style={{flex: 1, display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <img
                            alt={props.name}
                            src={process.env.REACT_APP_CDN_URL + '/uploads/article/' + props.media}
                            style={{maxWidth: window.innerWidth > 1020 ? "100%" : "50%", margin: "20px", maxHeight: "170px"}}
                        />
                    </div>
                    }
                    <div style={{flex: 2, margin: "auto", marginLeft: "15px"}}>
                        {displayInfos(props.infos)}

                        { props.choosen &&
                            <div style={{flexDirection: "row", marginTop: "15px", marginBottom: "15px"}}>
                                <div style={{display: "flex", flexDirection: "row", margin: "10px 0"}}>
                                    <div>
                                        Première box sélectionnée pour cet abonnement : <b>{props.choosen.name}</b>
                                    </div>
                                </div>
                            </div>
                        }

                        <div style={{fontSize: "1.2rem", fontWeight: 600, margin: props.unitPriceTtc ? "20px auto 5px" : "20px auto"}}>
                            Prix : <DisplayPrice price = {props.priceTtc}/>
                        </div>

                        {props.unitPriceTtc &&
                            <span className={globalStyles.badge} style={{backgroundColor:"#6fa939"}}>
                                Equivaut à <DisplayPrice price = {props.unitPriceTtc/100}/>/mois
                            </span>
                        }

                        <div style={{marginTop: "10px"}}>
                            {displayStock(props.stock, props.quantity, props.isComm)}
                        </div>

                        <div style={{flexDirection: "row", marginTop: "15px", marginBottom: "15px"}}>
                            <div style={{display: "flex", flexDirection: "row", margin: "10px 0"}}>
                                <div>
                                    Quantité : {props.quantity}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default AddItemTemplate;
