import React from "react";
import {MapContainer, TileLayer, Marker, Popup, useMap} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import marker from "../../../assets/utils/location-dot-solid.svg";

const icon = L.icon({
        iconUrl: marker,
        iconSize: [30],
        iconAnchor: [15, 40],
        popupAnchor: [0, -50]
    });

function Markers({ data, selected }) {
    const map = useMap();

    let weekdays = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"]

    let getSchedule = (data) => {
        let schedule = []
        for(let i = 0; i < 7; i++){
            schedule.push(weekdays[parseInt(data.day[i].weekday) - 1])
            schedule.push(" : ")
            if(
                data.day[i].open_am.length === 0 &&
                data.day[i].close_am.length === 0 &&
                data.day[i].open_pm.length === 0 &&
                data.day[i].close_pm.length === 0
            ){
                schedule.push("Fermé")
            } else if (
                data.day[i].open_pm.length === 0 &&
                data.day[i].close_pm.length === 0
            ) {
                schedule.push(data.day[i].open_am)
                schedule.push(" - ")
                schedule.push(data.day[i].close_am)
            } else {
                schedule.push(data.day[i].open_am)
                schedule.push(" - ")
                schedule.push(data.day[i].close_am)
                schedule.push(", ")
                schedule.push(data.day[i].open_pm)
                schedule.push(" - ")
                schedule.push(data.day[i].close_pm)
            }
            schedule.push(<br key={i}/>)
        }
        return schedule
    }

    let markers = [];
    for(let i in data){
        markers.push({
            latitude: data[i].latitude,
            longitude: data[i].longitude,
            name: data[i].name,
            schedule: getSchedule(data[i].schedule),
            code: data[i].code,
        });
    }

    return (
        markers.length > 0 &&
        markers.map((marker, index) => {
            return (
                <Marker
                    key={index}
                    eventHandlers={{
                        click: () => {
                            map.setView(
                                [
                                    marker.latitude,
                                    marker.longitude
                                ],
                                18
                            );
                            selected(marker.code);
                            //marker.openPopup();
                        }
                    }}                    
                    position={{
                        lat: marker.latitude,
                        lng: marker.longitude
                    }}
                    icon={icon}
                >
                    <Popup>
                        <h3>{marker.name}</h3>
                        HORAIRES<br/>
                        {marker.schedule}
                    </Popup>
                </Marker>
            );
        })
    );
}

function MapPoints(props) {


    let getBounds = () => {
        let latLngs = [];

        for(let i = 0; i < Math.min(5, props.listpoints.length); i++){

            latLngs.push(
                [
                    props.listpoints[i].latitude,
                    props.listpoints[i].longitude
                ]
            )
        }
        let bounds = new L.LatLngBounds(latLngs);

        return bounds
    }

    return (
        <MapContainer
            bounds={getBounds()}
            zoom={13}
            style={{height: "100%"}}
        >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Markers data={props.listpoints} selected={props.selected.bind(this)}/>
        </MapContainer>
    );
}

export default MapPoints;
