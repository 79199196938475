import React from "react";
import globalStyles from '../../../Styles/Style.module.css';
import {encode} from "../../../Services/ObfuscatorService";

function CardCommitment(props) {

    const displayInfos = (infos) => {
        let informations = []
        for(let i in infos){
            if(infos[i]){
                informations.push(
                    <div style={{marginBottom: "5px"}}>
                        {infos[i]}
                    </div>
                )
            }
        }
        return informations
    }


    return (
        <div
            className={globalStyles.card}
            style={{
                padding: "30px",
                flex: 1,
                backgroundColor: "#f3f6f8",
                boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                borderRadius: "5px",
                display: "flex",
                height: "100%",
                boxSizing: "border-box",
                flexDirection: "column"
            }}
        >
            <div style={{flexDirection: window.innerWidth > 565 ? "row" : "column", display: "flex", gap: "10px"}}>

                {props.media &&
                    <div style={{flex: 1, display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <img
                            src={process.env.REACT_APP_CDN_URL + '/uploads/article/' + props.media}
                            style={{maxWidth: "100%", maxHeight: "100%", borderRadius: "8px"}}
                            alt={props.title}
                        />
                    </div>
                }

                <div style={{flex: 2}}>
                    <h3 style={{margin: "0 0 15px 0"}}>
                        {props.title}
                    </h3>

                    <span
                        className={globalStyles.badge}
                        style={{backgroundColor: props.color}}
                    >
                {props.state}
                </span>

                    <div style={{flexDirection: "column", marginTop: "15px", marginBottom: "15px"}}>
                        <div style={{marginBottom: "5px"}}>
                            {props.begin} - {props.end}
                        </div>
                        {displayInfos(props.infos)}
                    </div>
                </div>
            </div>


            <div
                style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end"
                }}>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end", textAlign: "left", marginTop: "10px"}}>
                    <a className={globalStyles.defaultLink} href={"/commitment/" + encode(props.id)}>
                        Plus de détails
                    </a>
                </div>
            </div>
        </div>


    );
}

export default CardCommitment;