import React from "react"
import LoggedComponent, {mapStateToProps} from "../../Services/ConnexionService";
import globalStyles from '../../Styles/Style.module.css';
import FilAriane from "../Header_Footer/FilAriane";
import {connect} from "react-redux";
import {faEuroSign, faTruck} from "@fortawesome/free-solid-svg-icons";
import Bandeau from "../Header_Footer/Bandeau";
import ListAddress from "../Utils/Address/ListAddress";
import titleAriane from "../../Helpers/TitleAriane.json";

class Addresses extends LoggedComponent {

    doesMustLog = () => {
        this.mustLog = 1
    }

    renderChild () {

        return (
            <div>
                <Bandeau title={titleAriane["addresses"]["title"]}/>
                <FilAriane
                    links={[
                        [
                            titleAriane["addresses"]["ariane"][0],
                            "/"
                        ],
                        [
                            titleAriane["addresses"]["ariane"][1],
                            ""
                        ]
                    ]}
                />

                <div className={globalStyles.globalContainer} style={{flexDirection: "column", padding: window.innerWidth > 960 ? "unset" : "10px"}}>

                    <ListAddress
                        title={"Adresses de livraison"}
                        iconTitle={faTruck}
                        desktopNumber={3}
                        mobileNumber={1}
                        type={0}
                        state={this.state}
                        fetchJsonOrError={this.fetchJsonOrError}
                        add={["link", "/address/new"]}
                        choose={false}
                    />

                    <ListAddress
                        title={"Adresses de facturation"}
                        iconTitle={faEuroSign}
                        desktopNumber={3}
                        mobileNumber={1}
                        type={1}
                        state={this.state}
                        fetchJsonOrError={this.fetchJsonOrError}
                        add={["link", "/address/new"]}
                        choose={false}
                    />

                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(Addresses);