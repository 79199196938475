import React from 'react';
import globalStyles from '../../../Styles/Style.module.css';
import FilAriane from "../../Header_Footer/FilAriane";
import Lottie from 'react-lottie';
import animationData from "../../../assets/lottie/Atorika-check.json"
import Bandeau from "../../Header_Footer/Bandeau";
import titleAriane from "../../../Helpers/TitleAriane.json";

class ConfirmMail extends React.Component {

    render() {
        return (
            <div style={{marginBottom: "20px"}}>

                <Bandeau title={titleAriane["finish"]["title"]}/>
                <FilAriane
                    links={[
                        [
                            titleAriane["finish"]["ariane"][0],
                            "/register"
                        ],
                        [
                            titleAriane["finish"]["ariane"][1],
                            ""
                        ]
                    ]}
                />

                <div className={globalStyles.globalContainer}>
                    <div style={{flex: 1, margin : window.innerWidth > 1020 ? undefined : "0 10px"}}>

                        <div style={{display: "flex", alignItems: "flex-start", justifyContent: "flex-start"}}>
                            <span>
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: animationData,
                                        rendererSettings: {
                                            preserveAspectRatio: 'xMidYMid slice'
                                        }
                                    }}
                                    height={120}
                                    width={120}
                                />
                            </span>
                            <h2 style={{margin: "auto 10px auto 0"}}>Parfait {this.props.firstName}, tout est en ordre !</h2>
                        </div>

                        <h2>Il te reste à confirmer ton adresse e-mail</h2>

                        Pour pouvoir continuer, merci de confirmer ton adresse {this.props.mail}, en cliquant sur le lien que tu as reçu par mail.

                        <h2>Un souci, une question ?</h2>

                        Va vite dans notre <a href={process.env.REACT_APP_HELP_URL} className={globalStyles.defaultLink}>centre d'assistance</a> pour qu'on t'aide.

                    </div>

                </div>
            </div>
        );
    }

}

export default ConfirmMail;
