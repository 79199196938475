import React from "react";
import styles from './Login.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEnvelope, faEye, faEyeSlash, faLock} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import LoggedComponent, {mapStateToProps, checkIfConnected} from "../../Services/ConnexionService"
import FilAriane from "../Header_Footer/FilAriane";
import globalStyles from  '../../Styles/Style.module.css';
import formStyles from  '../../Styles/FormStyle.module.css';
import ReCAPTCHA from "react-google-recaptcha";
import Bandeau from "../Header_Footer/Bandeau";
import {withUserAgent} from "react-useragent";
import titleAriane from "../../Helpers/TitleAriane.json";


class Login extends LoggedComponent {

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            withPwd: true,
            email : '',
            otp : '',
            pwd : '',
            hasSendMail : false,
            error : false,
            errorMsg : 'Identifiants incorrects.',
            recaptchaRef : React.createRef(),
            captcha: false,
            showPwd: false,
            loading: false
        }
        this.first = true
    }

    doesMustLog = () => {
        this.mustLog = -1
    }

    handleEnter() {
        if (this.first) {
            this.first = true
            document.addEventListener('keydown', (event) => {
                if (event.key === "Enter") {
                    this.submit()
                }
                }
            );
        }
    }

    submit = () => {
        this.setState({loading : true});
        if(this.state.withPwd){
            this.loginPwd()
        }
        else if (this.state.hasSendMail) {
            this.loginOtp()
        }
        else {
            this.state.recaptchaRef.current.executeAsync()
                .then(() => {
                    if(this.state.captcha){
                        this.sendMail()
                    }
                });
        }
    }

    loginPwd = () => {
        const emailRegex = /\S+@\S+\.\S+/;
        if(emailRegex.test(this.state.email)) this.state.email = this.state.email.toLowerCase();
        let data = {
            email: this.state.email,
            pwd : this.state.pwd,
            userAgent : this.props.ua.md.ua
        }
        let jsonD = new FormData()
        jsonD.append("json", JSON.stringify(data))
        let request = new Request(process.env.REACT_APP_API_URL + '/client/login/pwd', {method:'POST', body: jsonD, credentials:'include'})
        this.fetchJsonOrError(request, (data) => {
            switch (data.type) {
                case 'OK':
                    checkIfConnected()
                    let redirect = new URLSearchParams(window.location.search).get("_redirect")
                    this.setState({shouldNavigate: true, navigateTo: redirect ? redirect : '/basket', loading: false})
                    break
                case 'NON_MAIL':
                    this.setState({...this.state, error: true, errorMsg: 'Ton mail n\'est pas validé. Un nouveau mail de validation t\'a été envoyé.', loading: false})
                    break
                default:
                    this.setState({...this.state, error: true, errorMsg : 'Identifiants incorrects.', loading: false})
            }
        })
    }

    sendMail = () => {
        let data = {
            email: this.state.email
        }
        let jsonD = new FormData()
        jsonD.append("json", JSON.stringify(data))
        let request = new Request(process.env.REACT_APP_API_URL + '/client/login/sendmail', {method:'POST', body: jsonD, credentials:'include'})
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            switch (data.type) {
                case 'OK':
                    this.setState({...this.state, hasSendMail: true, error: false, loading: false})
                    break
                default:
                    this.setState({...this.state, error: true, errorMsg : 'Tu n\'as pas encore de compte !', loading: false})
            }
        })
    }

    loginOtp = () => {
        const emailRegex = /\S+@\S+\.\S+/;
        if(emailRegex.test(this.state.email)) this.state.email = this.state.email.toLowerCase();

        let data = {
            email: this.state.email.trim(),
            otp: this.state.otp,
            userAgent : this.props.ua.md.ua
        }
        let jsonD = new FormData()
        jsonD.append("json", JSON.stringify(data))
        let request = new Request(process.env.REACT_APP_API_URL + '/client/login/otp', {method:'POST', body: jsonD, credentials:'include'})
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            switch (data.type) {
                case 'OK':
                checkIfConnected()  
                this.setState({shouldNavigate: true, navigateTo:'/basket', loading: false})
                    break
                case 'EXPIRED':
                    this.setState({error: true, errorMsg: 'Ce code a expiré', loading: false})
                    break
                default:
                    this.setState({...this.state, error: true, errorMsg : 'Code incorrect', loading: false})
            }
        })
    }

    renderChild() {
        this.handleEnter();

        return (

            <div className="Login">
                <Bandeau title={"Connexion"}/>
                <FilAriane
                    links={[
                        [
                            titleAriane["login"]["ariane"][0],
                            ""
                        ]
                    ]}
                />
                <div className={globalStyles.globalContainer}>

                    <div className={`${globalStyles.card} ${styles.loginCard}`}>

                        <h3 style={{textAlign: "center", fontWeight: 600}}>Connexion {this.state.withPwd ? "par mot de passe" : "sans mot de passe"}</h3>
                        <div style={{textAlign: "center", marginBottom: "20px"}}>{this.state.withPwd ? "Connecte-toi avec ton adresse email et ton mot de passe habituel." : "Saisis ton adresse email pour recevoir un code d'accès à usage unique."}</div>
                        {this.state.error &&
                        <div className={styles.error}>
                            {this.state.errorMsg}
                        </div>
                        }

                        {(!this.state.hasSendMail || this.state.withPwd) &&
                        <div className={styles.inputGroup}>
                            <div className={formStyles.inputIcon}>
                                <FontAwesomeIcon icon={faEnvelope}/>
                            </div>
                            <input
                                type="text"
                                name="username"
                                size={1}
                                className={formStyles.input}
                                required placeholder="E-Mail"
                                value={this.state.email}
                                onChange={(event) => {
                                    let cleanMail = event.target.value.replace(/\s/g, "");
                                    this.setState({...this.state, email: cleanMail.toLowerCase(), error:false})
                                }}
                            />
                        </div>
                        }

                        {this.state.hasSendMail && !this.state.withPwd &&
                        <div>
                            <p style={{textAlign: "center"}}>Un email contenant les instructions de connexion a été envoyé à {this.state.email}</p>
                            <div className={styles.inputGroup}>
                                <div className={formStyles.inputIcon}>
                                    <FontAwesomeIcon icon={faLock}/>
                                </div>
                                <input type="text" name="otp" size={1} className={formStyles.input} required placeholder="Code reçu" onChange={(event) => this.setState({...this.state, otp: event.target.value, error: false})}/>
                            </div>
                        </div>
                        }

                        {this.state.withPwd &&
                        <div className={styles.inputGroup}>
                            <div className={formStyles.inputIcon} style={{flex: 2}}>
                                <FontAwesomeIcon icon={faLock}/>
                            </div>
                            <input style={{flex: window.innerWidth > 1020 ? 21 : 13}} size={1} type={this.state.showPwd ? "text" : "password"} name="password" className={formStyles.input} required placeholder="Mot de passe" onChange={(event) => this.setState({...this.state, pwd: event.target.value, error: false})}/>
                            <button className={formStyles.eyeIcon} onClick={() => this.setState({showPwd: !this.state.showPwd})}>
                                <FontAwesomeIcon icon={this.state.showPwd ? faEyeSlash : faEye}/>
                            </button>
                        </div>
                        }

                        {!this.state.withPwd &&
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            onChange={() => this.setState({captcha: true})}
                            size={"invisible"}
                            ref={this.state.recaptchaRef}
                            badge={"bottomleft"}
                        />
                        }


                        <button type="submit" className={`${globalStyles.defaultBtn} ${styles.loginBtn} ${this.state.loading && styles.loaderBtn}`} onClick={this.submit} disabled={this.state.email === ''}>
                            <div>{!this.state.withPwd && !this.state.hasSendMail ? "Recevoir un code d'accès" : "Se connecter"}</div> {this.state.loading && <div className={`${globalStyles.loaderNextStep} ${styles.loadingLoginBtn}`}></div>}
                        </button>



                            <div className={styles.footer}>
                                    <button onClick={() => this.setState({...this.state, withPwd: !this.state.withPwd})} className={`${globalStyles.defaultLink} ${styles.linkUsePwd}`}>
                                        {this.state.withPwd ? "Se connecter sans mot de passe" : "Utiliser un mot de passe"}
                                    </button>
                                {!this.state.hasSendMail &&
                                    <div>
                                        Pas encore de compte ?<br/>
                                        <a href="/register" className={`${globalStyles.defaultLink} ${styles.linkRegister}`}>S'inscrire</a>
                                    </div>
                                }
                            </div>


                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(withUserAgent(Login));
