import React from 'react';
import styles from './PersonnalInformation.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEnvelope,
    faChild,
    faIdBadge,
    faVenus,
    faMars,
    faLock,
    faQuestionCircle,
    faEyeSlash, faEye, faTrash, faSchool, faPassport, faInfo
} from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import fr from 'date-fns/locale/fr';
import ReactTooltip from "react-tooltip";
import globalStyles from '../../../Styles/Style.module.css';
import formStyles from '../../../Styles/FormStyle.module.css';
import FilAriane from "../../Header_Footer/FilAriane";
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {Stack} from "@mui/material";
import Bandeau from "../../Header_Footer/Bandeau";
import titleAriane from "../../../Helpers/TitleAriane.json";

class PersonnalInformation extends React.Component {

    constructor(props) {
        super(props);
        if(Object.keys(props.data).length === 0){
            this.state = {
                nameParent: '',
                firstNameParent: '',
                birthParent: '',
                genderParent: 'f',
                mailParent: props.mail,
                password: '',
                confirmPassword: '',
                showPwd: false,
                isEntity: false,
                isSchool: false,
                SIRET: '',
                nbChildren: 0,
                nameEntity: '',
                children: [],
                nameSIRET: "",
                status: true
            };
        } else {
            this.state = props.data;
        }
        const {isSubmit} = props
        this.isSubmit = isSubmit
        this.errors = {
            password: '',
            mail: undefined,
            cannotUseMail: false,
            siret: {
                longueur: undefined,
                find: undefined
            }
        }
    }

    getAge = (date) => {
        if(date){
            let today = new Date();
            let birthDate = new Date(date);
            let age = today.getFullYear() - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        }
        return NaN;
    }

    goNext = () => {
        let data = new FormData();
        data.append('json', JSON.stringify({email: this.state.mailParent}));
        let request = new Request(process.env.REACT_APP_API_URL + '/client/user/checkmail', {method:'POST', body: data, credentials:'include'});
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            if(data.canUseMail) {
                this.isSubmit(this.state)
            } else {
                this.errors.cannotUseMail = true
                this.forceUpdate()
            }
        })
    }

    checkSIRET = (siret) => {
        let data = new FormData();
        data.append('json', JSON.stringify({siret: siret}));
        let request = new Request(process.env.REACT_APP_API_URL + '/client/check/siret', {method:'POST', body: data, credentials:'include'});
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            if(data.status === 200){
                this.setState({nameSIRET: data.name});
                this.errors.siret.find = false;
            } else {
                this.setState({nameSIRET: "Non trouvé"});
                this.errors.siret.find = true;
            }
            this.forceUpdate();
        })
    }

    SIRETerror(){
        let isDefined = (this.errors.siret.longueur !== undefined && this.errors.siret.find !== undefined);
        let error = this.errors.siret.longueur || this.errors.siret.find;
        return isDefined && error;
    }

    addChild = () => {
        this.state.children.push(
            {
                name: '',
                firstName: '',
                birth: '',
                gender: 'f',
            }
        )
        this.setState({nbChildren: this.state.nbChildren+1});
        this.forceUpdate()

    }

    deleteChild = (nb) => {
        this.state.children.splice(nb, 1);
        this.setState({nbChildren: this.state.nbChildren-1});
        this.forceUpdate()
    }

    displayChildrenForm = () => {
        let childrenForm = []

        for(let i = 0; i < this.state.nbChildren; i++){
            childrenForm.push(
                <div>
                    <h3>
                        {i === 0 ? "1er enfant" : i+1 + "ème enfant"}
                        <button
                            type="button"
                            style={{border: "none", backgroundColor: "#CE3534", color: "white", padding: "5px 10px", borderRadius: "5px", marginLeft: "10px", cursor: "pointer"}}
                            onClick={() => this.deleteChild(i)}
                        >
                            <FontAwesomeIcon icon={faTrash}/>
                        </button>
                    </h3>
                    <div className={formStyles.inputLine}>
                        <div className={formStyles.inputGroup}>
                            <label>Nom*</label>
                            <div className={formStyles.inputField}>
                                <div className={formStyles.inputIcon}>
                                    <FontAwesomeIcon icon={faIdBadge}/>
                                </div>
                                <input
                                    type="text"
                                    required
                                    className={formStyles.input}
                                    placeholder="Nom"
                                    value={this.state.children[i].name}
                                    onChange={(event) => {
                                        this.state.children[i].name = event.target.value.toUpperCase();
                                        this.forceUpdate();
                                    }}
                                />
                            </div>
                        </div>

                        <div className={formStyles.inputGroup}>
                            <label>Prénom*</label>
                            <div className={formStyles.inputField}>
                                <div className={formStyles.inputIcon}>
                                    <FontAwesomeIcon icon={faChild}/>
                                </div>
                                <input
                                    type="text"
                                    required
                                    className={formStyles.input}
                                    placeholder="Prénom"
                                    value={this.state.children[i].firstName}
                                    onChange={(event) => {
                                        this.state.children[i].firstName = event.target.value;
                                        this.forceUpdate();
                                    }}
                                />
                            </div>
                        </div>
                    </div>


                    <div className={formStyles.inputLine}>
                        <div className={formStyles.inputGroupBirthGender}>
                            <div className={formStyles.inputGroupGender}>
                                <label>Sexe*</label>
                                <div className={formStyles.inputContent}>
                                    <div className={formStyles.gender}>
                                        <button type="button"
                                                onClick={() => {
                                                    this.state.children[i].gender = "f";
                                                    this.forceUpdate();
                                                }}
                                                className={this.state.children[i].gender === "f" ? `${styles.btnSelected} ${styles.btnFemale}` : `${styles.btnNotSelected} ${styles.btnFemale}`}>
                                            <FontAwesomeIcon icon={faVenus}/>
                                        </button>
                                        <button type="button"
                                                onClick={() => {
                                                    this.state.children[i].gender = "m";
                                                    this.forceUpdate();
                                                }}
                                                className={this.state.children[i].gender === "m" ? `${styles.btnSelected} ${styles.btnMale}` : `${styles.btnNotSelected} ${styles.btnMale}`}>
                                            <FontAwesomeIcon icon={faMars}/>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className={formStyles.inputGroupBirth}>
                                <div style={{marginBottom: "5px"}}>
                                    <label>Date de naissance*</label>
                                    {!isNaN(this.getAge(this.state.children[i].birth)) &&
                                        (this.getAge(this.state.children[i].birth) > 100 ?
                                                <span style={{fontSize: "12px", color: "red", marginLeft: "5px"}}> {this.getAge(this.state.children[i].birth)} ans</span>
                                                :
                                                <span style={{fontSize: "12px", marginLeft: "5px"}}> {this.getAge(this.state.children[i].birth)} ans</span>
                                        )
                                    }
                                </div>

                                <div className={formStyles.inputField} style={{display: "inline", border: "none"}}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={fr}>
                                        <Stack spacing={3}>
                                            <DatePicker
                                                label="Date de naissance"
                                                value={this.state.children[i].birth}
                                                openTo="year"
                                                views={['year', 'month', 'day']}
                                                onChange={(date) => {
                                                    if(date){
                                                        date.setHours(12);
                                                    }
                                                    this.state.children[i].birth = date;
                                                    this.forceUpdate();
                                                }}
                                                enableFuture={false}
                                                inputFormat={"dd/MM/yyyy"}
                                                renderInput={(params) => <TextField size={"small"} {...params} helperText={null} />}
                                            />
                                        </Stack>
                                    </LocalizationProvider>
                                </div>
                            </div>

                        </div>

                        <div className={formStyles.inputGroup}>

                        </div>

                    </div>
                </div>
            )
        }

        return childrenForm
    }


    render() {

        const emailRegex = /\S+@\S+\.\S+/;
        
        const validateEmail = (email) => {
            this.errors.mail = !emailRegex.test(email);
            this.forceUpdate();
        };

        const validateSIRET = (event) => {
            const siret = event.target.value;
            this.errors.siret.longueur = !(siret.length === 14);
            if(siret.length === 14){
                this.checkSIRET(siret);
            } else {
                this.setState({nameSIRET: ""});
                this.errors.siret.find = true;
            }
            this.forceUpdate();
        };

        const formValid = () => {
            let infoChildren = true;
            for(let i = 0; i < this.state.nbChildren; i++){
                let infoChild = (this.state.children[i].name.length > 0) && (this.state.children[i].firstName.length > 0) && (this.state.children[i].birth !== '') && (!isNaN(this.getAge(this.state.children[i].birth)) && this.getAge(this.state.children[i].birth) < 100);
                infoChildren = infoChildren && infoChild;
            }
            let infoParent = (this.state.nameParent.length > 0) && (this.state.firstNameParent.length > 0) && (this.state.birthParent !== '') && (!isNaN(this.getAge(this.state.birthParent)) && this.getAge(this.state.birthParent) < 100);
            let infos = this.state.status && (this.state.mailParent.length > 0) && !this.errors.mail && (this.state.password.length > 0) && (this.state.confirmPassword.length > 0) && !(this.errors.password.length > 0);
            if(this.state.isEntity){
                return infoParent && infos && this.state.nameEntity.length > 0 && this.state.SIRET.length > 0 && !this.errors.siret.longueur && !this.errors.siret.find;
            }
            return (infoChildren && infoParent && infos);
        }


        return (
            <div>

                <Bandeau title={titleAriane["persoInfo"]["title"]}/>
                <FilAriane
                    links={[
                        [
                            titleAriane["persoInfo"]["ariane"][0],
                            "/register"
                        ],
                        [
                            titleAriane["persoInfo"]["ariane"][1],
                            ""
                        ],
                    ]}
                />

                <div className={globalStyles.globalContainer}>

                    <div style={{flex: 1}}>

                        <div className={globalStyles.bulle}>
                            <span className={globalStyles.iconBulle}>
                                <FontAwesomeIcon icon={faInfo}/>
                            </span>
                            <span className={globalStyles.iconText}>
                                Atorika accorde une grande importance à la protection de tes données personnelles.
                                Elles ne seront en aucun cas revendues à des tiers et serviront uniquement à t'offrir le meilleur service possible.
                            </span>
                        </div>

                        <h2 style={{marginLeft : window.innerWidth > 1020 ? undefined : "10px"}}>Tes informations</h2>

                        <div style={{display: "flex"}}>
                            <div style={{margin: "10px 20px", flex: 1}}>

                                Statut* :

                                <div style={{flex: 1, display: "flex"}} className={formStyles.inputLine}>
                                    <div className={formStyles.inputGroup} style={{ flex: "none", display: "flex", alignItems: 'center' }}>
                                        <input id="particulier" type="radio" name="status" value="perso" style={{ height: "20px", width: "20px", marginRight: "20px" }} checked={this.state.status && !this.state.isEntity} onChange={() => {this.setState({isEntity: false, isSchool: false, status: true})}}/>
                                        <label for="particulier">
                                            Particulier
                                        </label>
                                    </div>

                                    <div className={formStyles.inputGroup} style={{ flex: "none", display: "flex", alignItems: 'center' }}>
                                        <input id="school" type="radio" name="status" value="pro" style={{ height: "20px", width: "20px", marginRight: "20px" }} checked={this.state.status && this.state.isEntity && this.state.isSchool} onChange={() => {this.setState({isEntity: true, isSchool: true, status: true})}}/>
                                        <label for="school">
                                            Etablissement scolaire
                                        </label>
                                    </div>

                                    <div className={formStyles.inputGroup} style={{ flex: "none", display: "flex", alignItems: 'center' }}>
                                        <input id="otherentity" type="radio" name="status" value="pro" style={{ height: "20px", width: "20px", marginRight: "20px" }} checked={this.state.status && this.state.isEntity && !this.state.isSchool} onChange={() => {this.setState({isEntity: true, isSchool: false, status: true})}}/>
                                        <label for="otherentity">
                                            Autre professionel (Comité d'Entreprise ...)
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {this.state.isEntity &&
                            <div>
                                <div className={formStyles.inputLine}>
                                    <div className={formStyles.inputGroup}>
                                        <label>Nom de la structure*</label>
                                        <div className={formStyles.inputField}>
                                            <div className={formStyles.inputIcon}>
                                                <FontAwesomeIcon icon={faSchool}/>
                                            </div>
                                            <input
                                                type="text"
                                                required
                                                className={formStyles.input}
                                                placeholder="Nom de la structure" value={this.state.nameEntity}
                                                onChange={(event) => this.setState({nameEntity: event.target.value})}/>
                                        </div>
                                    </div>

                                    <div className={formStyles.inputGroup}>
                                        <label>SIRET*
                                            <span style={{fontSize: "0.75rem"}}>  {this.state.nameSIRET}</span>
                                        </label>
                                        <div
                                            className={formStyles.inputField}
                                            style={{borderColor : this.SIRETerror() ? "red" : undefined}}
                                        >
                                            <div className={formStyles.inputIcon}>
                                                <FontAwesomeIcon icon={faPassport}/>
                                            </div>
                                            <input
                                                type="text"
                                                required
                                                className={formStyles.input}
                                                placeholder="SIRET"
                                                value={this.state.SIRET}
                                                onChange={(event) => {
                                                    this.setState({SIRET: event.target.value});
                                                    validateSIRET(event);
                                                }}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroup}>
                                <label>Nom*</label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faIdBadge}/>
                                    </div>
                                    <input
                                        type="text"
                                        required
                                        className={formStyles.input}
                                        placeholder="Nom" value={this.state.nameParent}
                                        onChange={(event) => this.setState({nameParent: event.target.value.toUpperCase()})}/>
                                </div>
                            </div>

                            <div className={formStyles.inputGroup}>
                                <label>Prénom*</label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faChild}/>
                                    </div>
                                    <input type="text" required className={formStyles.input} placeholder="Prénom" value={this.state.firstNameParent} onChange={(event) => this.setState({firstNameParent: event.target.value})}/>
                                </div>
                            </div>
                        </div>

                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroupBirthGender}>
                                <div className={formStyles.inputGroupGender}>
                                    <label>Sexe*</label>
                                    <div className={formStyles.inputContent}>
                                        <div className={formStyles.gender}>
                                            <button
                                                type="button"
                                                onClick={() => this.setState({genderParent: "f"})}
                                                className={this.state.genderParent === "f" ? `${styles.btnSelected} ${styles.btnFemale}` : `${styles.btnNotSelected} ${styles.btnFemale}`}
                                            >
                                                <FontAwesomeIcon icon={faVenus}/>
                                            </button>
                                            <button type="button" onClick={() => this.setState({genderParent: "m"})}  className={this.state.genderParent === "m" ? `${styles.btnSelected} ${styles.btnMale}` : `${styles.btnNotSelected} ${styles.btnMale}`}>
                                                <FontAwesomeIcon icon={faMars}/>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className={formStyles.inputGroupBirth}>
                                    <div style={{marginBottom: "5px"}}>
                                        <label>Date de naissance*</label>
                                        {!isNaN(this.getAge(this.state.birthParent)) &&
                                            (this.getAge(this.state.birthParent) > 100 ?
                                                <span style={{fontSize: "12px", color: "red", marginLeft: "5px"}}> {this.getAge(this.state.birthParent)} ans</span>
                                                :
                                                <span style={{fontSize: "12px", marginLeft: "5px"}}> {this.getAge(this.state.birthParent)} ans</span>
                                            )
                                        }
                                    </div>

                                    <div className={formStyles.inputField} style={{display: "inline", border: "none"}}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={fr}>
                                            <Stack spacing={3}>
                                                <DatePicker
                                                    label="Date de naissance"
                                                    value={this.state.birthParent}
                                                    openTo="year"
                                                    views={['year', 'month', 'day']}
                                                    disableFuture={true}
                                                    inputFormat={"dd/MM/yyyy"}
                                                    onChange={(date) => {
                                                        if(date){
                                                            date.setHours(12);
                                                        }
                                                        this.setState({birthParent: date});
                                                    }}
                                                    renderInput={(params) => <TextField size={"small"} {...params} helperText={null} />}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            </div>



                            <div className={formStyles.inputGroup}>
                                <label>
                                    E-mail*
                                    <FontAwesomeIcon className={formStyles.iconHelp} icon={faQuestionCircle} tabIndex={-1} data-tip="L'e-mail nous servira à te contacter <br/>pour le suivi de ta commande, <br/>la connexion à ton compte ..."/>
                                </label>
                                <div className={formStyles.inputField} style={this.errors.mail ? {borderColor: 'red'} : {}} >
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faEnvelope}/>
                                    </div>
                                    <input
                                        type="text"
                                        required
                                        className={formStyles.input}
                                        placeholder="prenom.nom@exemple.fr"
                                        value={this.state.mailParent}
                                        onChange={(event) => {
                                            let mail = event.target.value;
                                            let cleanMail = mail.replace(/\s/g, "");
                                            cleanMail = cleanMail.toLowerCase();
                                            this.setState({mailParent: cleanMail});
                                            validateEmail(cleanMail)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {this.errors.cannotUseMail &&
                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroup}/>
                            <div className={formStyles.inputGroup}>
                                <div className={formStyles.textDanger}>
                                    Cette adresse e-mail est déjà utilisée.
                                </div>
                            </div>
                        </div>
                        }

                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroup}>
                                <label>
                                    Mot de passe*
                                    <FontAwesomeIcon className={formStyles.iconHelp} icon={faQuestionCircle} tabIndex={-1} data-tip="Choissis un mot de passe sécurisé."/>
                                </label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faLock}/>
                                    </div>
                                    <input type={this.state.showPwd ? "text" : "password"} required  className={formStyles.input} placeholder="••••••••" value={this.state.password}
                                           onChange={(event) => {
                                               this.setState({password: event.target.value});
                                               if (this.state.confirmPassword !== "" && event.target.value !== "") {
                                                   if (this.state.confirmPassword !== event.target.value) {
                                                       this.errors.password = "Les mots de passe ne correspondent pas.";
                                                   } else {
                                                       this.errors.password = "";
                                                   }
                                               } else {
                                                   this.errors.password = "";
                                               }
                                           }}
                                    />
                                    <button className={formStyles.eyeIcon} onClick={() => this.setState({showPwd: !this.state.showPwd})}>
                                        <FontAwesomeIcon icon={this.state.showPwd ? faEyeSlash : faEye}/>
                                    </button>
                                </div>
                            </div>

                            <div className={formStyles.inputGroup}>
                                <label>Confirmation de mot de passe*</label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faLock}/>
                                    </div>
                                    <input type={this.state.showPwd ? "text" : "password"} required className={formStyles.input} placeholder="••••••••" value={this.state.confirmPassword}
                                           onChange={(event) => {
                                               this.setState({confirmPassword: event.target.value});
                                               if (this.state.password !== "" && event.target.value !== "") {
                                                   if (this.state.password !== event.target.value) {
                                                       this.errors.password = "Les mots de passe ne correspondent pas.";
                                                   } else {
                                                       this.errors.password = "";
                                                   }
                                               } else {
                                                   this.errors.password = "";
                                               }
                                           }}
                                    />
                                    <button className={formStyles.eyeIcon} onClick={() => this.setState({showPwd: !this.state.showPwd})}>
                                        <FontAwesomeIcon icon={this.state.showPwd ? faEyeSlash : faEye}/>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className={formStyles.inputLine}>
                            <div className={formStyles.textDanger}>{this.errors.password}</div>
                        </div>




                        {this.state.isEntity === false &&

                            <div style={{marginLeft : window.innerWidth > 1020 ? undefined : "10px"}}>
                                <h2>
                                    Tes enfants
                                    <FontAwesomeIcon
                                        className={formStyles.iconHelp}
                                        icon={faQuestionCircle}
                                        place={"top"}
                                        tabIndex={-1}
                                        data-tip="Ces informations sont utiles pour le suivi de la progression des enfants."
                                    />
                                </h2>

                                {this.displayChildrenForm()}

                                <div style={{display: "flex", alignItems: "center", marginTop: '15px'}}>
                                    <button type="button" className={globalStyles.defaultBtn} onClick={this.addChild} style={{padding: "10px 20px"}}>
                                        Ajouter un enfant
                                    </button>
                                </div>

                                <ReactTooltip multiline={true}/>
                            </div>


                        }



                        <div
                            className={formStyles.btnContainer}
                            style={{marginTop: "20px", justifyContent: window.innerWidth > 960 ? "flex-end" : "center", marginRight: window.innerWidth > 960 ? "20px" : undefined}}
                        >
                            <button
                                type="button"
                                className={formValid() ? globalStyles.defaultBtn : formStyles.disabledBtn}
                                onClick={this.goNext} disabled={!formValid()}
                            >
                                Continuer
                            </button>
                        </div>


                    </div>
                </div>
                <ReactTooltip multiline={true}/>
            </div>
        );
    }

}

export default PersonnalInformation;
