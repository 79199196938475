import React from "react";
import DisplayPrice from "../../../Utils/DisplayPrice";
import styles from "./ItemBasketChoice.module.css";
import useWindowDimensions from "../../../../Services/WindowsDimensions";

function ItemBasketChoice(props) {

    const { width } = useWindowDimensions();


    let displayInfos = (infos) => {
        let informations = []
        for(let i in infos){
            informations.push(
                <div key={"infos" + i} style={{marginTop: "10px"}}>
                    {infos[i]}
                </div>
            )
        }
        return informations
    }


    return (
        <div className={`${styles.containerBasket} ${props.lastItem ? styles.lastItem : ""}`}>
            <div style={{flex: 5}}>

                <div style={{flexDirection:"row", display: "flex", justifyContent:"space-between"}}>
                    {props.media &&
                    <div style={{flex: .4, display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <img
                            alt={props.name}
                            src={process.env.REACT_APP_CDN_URL + '/uploads/article/' + props.media}
                            style={{maxHeight: "100px", maxWidth: "calc(100% - -10px)"}}
                        />
                    </div>
                    }
                    <div className={styles.containerTitreQty} style={{flex: .5, margin: "5px", marginLeft: "20px", marginRight:"20px", display:"flex", flexDirection:"column", justifyContent:'center'}}>
                    <div className={styles.productName}>{props.name}</div>
                        {displayInfos(props.infos)}

                        <div style={{flexDirection: "row", marginTop: "-5px", marginBottom: "0"}}>
                            <div style={{display: "flex", flexDirection: "row", margin: "6px 0"}}>
                                <div>
                                    x{props.quantity ?? "1" }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{display:'flex', alignItems:'center'}}>
                        <div className={styles.price} style={{fontSize: "1.5rem", fontWeight: 600, margin: width <  768 ? "0 auto" : props.unitPriceTtc ? "20px auto 5px" : "20px auto"}}>
                                <DisplayPrice price = {props.priceTtc}/>
                            </div>
                        </div>

                </div>
            </div>

        </div>
    );
}

export default ItemBasketChoice;
