import React from "react"
import LoggedComponent, { mapStateToProps } from "../../../Services/ConnexionService";
import { connect } from "react-redux";
import globalStyles from "../../../Styles/Style.module.css";
import ListPoints from "./ListPoints";
import FilAriane from "../../Header_Footer/FilAriane";
import styles from "./Delivery.module.css"
import MapPoints from "./MapPoints";
import carriers from "../../../Helpers/carriers.json";
import {
    faAt,
    faChild,
    faCity, faGlobeEurope, faIdBadge,
    faMailBulk, faMapMarkerAlt, faPhone, faQuestionCircle,
    faStore, faTimes,
    faTruck
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import formStyles from '../../../Styles/FormStyle.module.css';
import Bandeau from "../../Header_Footer/Bandeau";
import Modal from 'react-modal';
import ListAddress from "../../Utils/Address/ListAddress";
import AddAddressDelivery from "./AddAdressDelivery";
import titleAriane from "../../../Helpers/TitleAriane.json";
import DisplayPrice from "../../Utils/DisplayPrice";
import { encode } from "../../../Services/ObfuscatorService";
import ReactTooltip from "react-tooltip";
import Step from "../Basket/step";
import marker from "../../../assets/utils/location-dot-solid.svg";

class Delivery extends LoggedComponent {

    constructor(props) {
        super(props);
        this.state = {
            loadingNextStep: false,
            totalAmount: 0,
            basket: {},
            listpoints: [],
            nbPoints: null,
            countries: [],
            selectedPoint: undefined,
            postalCode: '',
            city: '',
            country: undefined,
            deliveryOptions: undefined,
            order: undefined,
            relais: {
                lastName: "",
                firstName: "",
                email: "",
                phone: ""
            },
            address: {
                id: undefined,
                firstName: undefined,
                lastName: undefined,
                streetAddress: undefined,
                city: undefined,
                postalCode: undefined,
                country: undefined,
                phone: undefined
            },
            come: {
                lastName: "",
                firstName: "",
                email: "",
                phone: ""
            },
            option: undefined,
            errors: {
                phone: false,
                comePhone: false
            },
        }
        this.isOpen = false;
        this.isOpenAdd = false;
        this.loadingDO = true;
        this.loading = true;
        this.loadingPts = true;
        this.loadingAddress = true;
        this.loadingCountries = true;
        this.reloadRelay = false;
        this.isFetchingDO = false;
        this.isFetchingListPoints = false;
        this.isFetchingCountries = false;
        this.isFetchingBasket = false;
    }

    componentDidUpdate() {
        if (!this.state.option) {
            this.setState({ option: "relais" });
        }
        if (!this.state.country) {
            this.setState({ country: "France" })
        }

        if (this.loadingCountries) {
            if (!this.isFetchingCountries) {
                this.getCountries();
            }
            if (!this.isFetchingBasket) {
                this.getBasket();
            }
        }
        if (this.loadingDO && !this.isFetchingDO) {
            this.getDO();
        }

        if (!this.state.totalAmount && this.state.basket.articles && this.state.deliveryOptions) {
            this.calculatetotalAmount();
        }

        if (!this.loadingDO && this.loadingPts && !this.loadingAddress) {
            this.getListPoints(true)
        }
        this.getInfos()
    }

    doesMustLog = () => {
        this.mustLog = 1
    }

    getCountries() {
        if (this.isFetchingCountries) { return; }
        this.isFetchingCountries = true;
        let request = new Request(process.env.REACT_APP_API_URL + '/client/countries', { method: 'GET', credentials: 'include' });
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({ countries: data.countries })
            this.isFetchingCountries = false;
            this.loadingCountries = false;
            this.forceUpdate();
        })
    }

    async getDO(callback) {
        if (this.isFetchingDO) { return; }
        this.isFetchingDO = true;
        let data = new FormData();
        data.append('json', JSON.stringify({
            countryRelay: this.state.country ?? this.state.address.country ?? 'France',
            countryHome: this.state.address.country ?? 'France'
        }))

        let request = new Request(process.env.REACT_APP_API_URL + '/client/delivery/basket/' + this.props.id, { method: 'POST', body: data, credentials: 'include' });
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({ deliveryOptions: data.deliveryOptions })
            this.isFetchingDO = false;
            if (!this.state.order) {
                this.setState({ order: data.order, selectedPoint: data.order.relayId });
                if (data.order.type === 0) {
                    this.setState({
                        come: {
                            firstName: data.order.firstName,
                            lastName: data.order.lastName,
                            phone: data.order.phone,
                            email: data.order.email
                        },
                        option: "come"
                    })
                } else if (data.order.type === 1 && !this.state.selectedPoint ) {
                    this.setState({
                        relais: {
                            relayPoint: data.order.relayId,
                            relayName: data.order.relayName,
                            address: data.order.address,
                            postalCode: data.order.postalCode,
                            city: data.order.city,
                            country: data.order.country ?? 'France',
                            firstName: data.order.firstName,
                            lastName: data.order.lastName,
                            phone: data.order.phone,
                            email: data.order.email
                        },
                        option: "relais"
                    })
                    this.selected(data.order.relayId);
                } else if (data.order.type === 2) {
                    this.setState({
                        option: "home"
                    })
                }
                this.loadingDO = false;
                this.forceUpdate();
                this.getAddress();
            }
        }).then(() => {
            if (callback) callback()
        })
    }

    getListPoints(init = false) {
        if (this.isFetchingListPoints) { return; }
        this.isFetchingListPoints = true;
        if (Object.keys(this.state.deliveryOptions.relay.carriers).length > 0 && this.state.city && this.state.postalCode && this.state.country) {
            let data = new FormData();
            data.append('json', JSON.stringify({ carriers: this.state.deliveryOptions.relay.carriers }));
            let request = new Request(process.env.REACT_APP_API_URL + '/client/delivery/listpoints/' + this.state.city + "/" + this.state.postalCode + '/' + this.state.country, { method: 'POST', body: data, credentials: 'include' });
            fetch(request).then((response) => {
                return response.json()
            }).then((data) => {
                let points = []
                this.isFetchingListPoints = false;
                if (data.carrier && data.total) {
                    var coordsRef = [0, 0];
                    if (data.carrier instanceof Array) {
                        coordsRef = [0, 0];
                        let count = 0;
                        for (let carrier in data.carrier) {
                            if (data.carrier[carrier].points.point) {
                                coordsRef[0] += parseFloat(data.carrier[carrier].points.point[0].latitude);
                                coordsRef[1] += parseFloat(data.carrier[carrier].points.point[0].longitude);
                                count++;
                            }
                        }

                        coordsRef[0] /= count;
                        coordsRef[1] /= count;

                        for (let carrier in data.carrier) {
                            let carrier_code = data.carrier[carrier].operator;
                            if (data.carrier[carrier].points.point) {
                                for (let i = 0; i < data.carrier[carrier].points.point.length; i++) {
                                    points.push(
                                        {
                                            name: data.carrier[carrier].points.point[i].name,
                                            carrier: carriers[carrier_code].img,
                                            address: data.carrier[carrier].points.point[i].address,
                                            zipcode: data.carrier[carrier].points.point[i].zipcode,
                                            city: data.carrier[carrier].points.point[i].city,
                                            country: data.carrier[carrier].points.point[i].country,
                                            schedule: data.carrier[carrier].points.point[i].schedule,
                                            code: data.carrier[carrier].points.point[i].code,
                                            latitude: data.carrier[carrier].points.point[i].latitude,
                                            longitude: data.carrier[carrier].points.point[i].longitude,
                                            selected: false
                                        }
                                    )
                                }
                            }
                        }
                    } else {
                        let carrier_code = data.carrier.operator;
                        coordsRef = [data.carrier.points.point[0].latitude, data.carrier.points.point[0].longitude];
                        for (let i = 0; i < data.carrier.points.point.length; i++) {
                            points.push(
                                {
                                    name: data.carrier.points.point[i].name,
                                    carrier: carriers[carrier_code].img,
                                    address: data.carrier.points.point[i].address,
                                    zipcode: data.carrier.points.point[i].zipcode,
                                    city: data.carrier.points.point[i].city,
                                    country: data.carrier.points.point[i].country,
                                    schedule: data.carrier.points.point[i].schedule,
                                    code: data.carrier.points.point[i].code,
                                    latitude: data.carrier.points.point[i].latitude,
                                    longitude: data.carrier.points.point[i].longitude,
                                    selected: false
                                }
                            )
                        }
                    }
                    points.sort(function (a, b) {
                        let distA = (a.latitude - coordsRef[0]) ** 2 + (a.longitude - coordsRef[1]) ** 2;
                        let distB = (b.latitude - coordsRef[0]) ** 2 + (b.longitude - coordsRef[1]) ** 2;
                        return distA - distB;
                    })
                    this.setState({ listpoints: points, nbPoints: data.total });
                    this.loadingPts = false;
                    this.reloadRelay = false;
                    if (init) {
                        this.selected(this.state.order.relayId)
                    } else {
                        this.selected(points[0].code)
                    }
                    this.forceUpdate();
                } else {
                    this.isFetchingListPoints = false;
                    this.setState({ nbPoints: data.total });
                }
            })
                .catch(error => {
                    console.error("Erreur lors de la récupération des points relais :", error);
                    this.isFetchingListPoints = false;
                    this.setState({ nbPoints: 0 });

                });
        } else {
            this.loadingPts = false;
            this.isFetchingListPoints = false;
            this.reloadRelay = false;
            this.forceUpdate();
        }
    }

    setDeliveryAddress = () => {
        if (this.state.user) {
            for (let i in this.state.user.addresses) {
                let address = this.state.user.addresses[i];
                if (this.state.order.address) {
                    if (address.id === this.state.order.address) {
                        this.setState({
                            address: {
                                id: address.id,
                                firstName: address.firstName,
                                lastName: address.lastName,
                                streetAddress: address.streetAddress,
                                streetAddressMore: address.streetAddressMore,
                                city: address.city,
                                postalCode: address.postalCode,
                                country: address.country,
                                phone: address.phone
                            },
                        });
                        if (!this.state.city) {
                            this.setState({
                                city: address.city,
                                postalCode: address.postalCode,
                                country: address.country
                            })
                        }
                        this.loadingAddress = false;
                        this.forceUpdate();
                    }
                } else if (address.type === 0 && address.default) {
                    this.setState({
                        address: {
                            id: address.id,
                            firstName: address.firstName,
                            lastName: address.lastName,
                            streetAddress: address.streetAddress,
                            streetAddressMore: address.streetAddressMore,
                            city: address.city,
                            postalCode: address.postalCode,
                            country: address.country,
                            phone: address.phone
                        },
                    });
                    if (!this.state.city) {
                        this.setState({
                            city: address.city,
                            postalCode: address.postalCode,
                            country: address.country
                        })
                    }
                    this.loadingAddress = false;
                    this.forceUpdate();
                }
            }
        }
    }

    getAddress = () => {
        if (this.loadingAddress) {
            this.setDeliveryAddress()
        }
        return [
            this.state.address.firstName + " " + this.state.address.lastName,
            this.state.address.streetAddress,
            this.state.address.streetAddressMore,
            this.state.address.postalCode + " " + this.state.address.city,
            this.state.address.country,
            this.state.address.phone
        ];
    }

    getDetails = (type) => {
        if (
            Object.keys(this.state.deliveryOptions[type].details).length === 1 &&
            this.state.deliveryOptions[type].details[Object.keys(this.state.deliveryOptions[type].details)[0]] === 1
        ) {
            return ["Livraison d'ici le " + this.state.deliveryOptions[type].delay]
        }
        let details = [];
        let first = true;
        for (let i = Object.keys(this.state.deliveryOptions[type].details).length - 1; i >= 0; i--) {
            let price = parseInt(Object.keys(this.state.deliveryOptions[type].details)[i]);
            let duration = parseInt(this.state.deliveryOptions[type].details[price]);
            if (first) {
                first = false;
                details.push("Livraison initale d'ici le " + this.state.deliveryOptions[type].delay + " : ")
                details.push(<span key={'first' + i} style={{ whiteSpace: "nowrap" }}>1 x <DisplayPrice price={price / 100} /></span>)
                details.push(<br key={'firstbr' + i} />)
                if (parseInt(duration) > 1) {
                    details.push("Pour les " + (duration - 1) + " mois suivants : ")
                    details.push(<span key={'next' + i} style={{ whiteSpace: "nowrap" }}>{(duration - 1)} x <DisplayPrice price={price / 100} /></span>)
                    details.push(<br key={'nexbr' + i} />)
                }
            } else {
                details.push("Pour les " + duration + " mois suivants : " + duration + " x ")
                details.push(<DisplayPrice key={'display' + i} price={price / 100} />)
                details.push(<br key={'displaybr' + i} />)
            }

        }
        return details
    }


    selected = (code) => {
        if (code) {
            let newListpoint = this.state.listpoints;
            let newSelectedPoint = {};
            for (let i in newListpoint) {
                if (newListpoint[i].code === code) {
                    newSelectedPoint = newListpoint[i];
                    newListpoint[i].selected = true;
                    this.setState({
                        listpoints: newListpoint,
                        selectedPoint: newSelectedPoint,
                        relais: {
                            ...this.state.relais,
                            relayPoint: newSelectedPoint.code,
                            relayName: newSelectedPoint.name,
                            address: newSelectedPoint.address,
                            postalCode: newSelectedPoint.zipcode,
                            city: newSelectedPoint.city,
                            country: newSelectedPoint.country,
                        }
                    }, () => this.getDO(() => { this.calculatetotalAmount() }));
                }
                else{
                    newListpoint[i].selected = false;
                }
            }
        } else {
            this.setState({
                selectedPoint: undefined,
                relais: {
                    ...this.state.relais,
                    relayPoint: undefined,
                    relayName: undefined,
                    address: undefined,
                    postalCode: undefined,
                    city: undefined,
                    country: undefined,
                }
            }, () => this.calculatetotalAmount());
        }
    }

    openModal = () => {
        this.isOpen = true;
        this.forceUpdate();
    }

    closeModal = () => {
        this.isOpen = false;
        this.forceUpdate();
    }

    openModalAdd = () => {
        this.isOpenAdd = true;
        this.forceUpdate();
    }

    closeModalAdd = () => {
        this.isOpenAdd = false;
        this.forceUpdate();
    }

    choose = () => {
        this.setState({ loadingNextStep: true })
        let data = new FormData();

        if (this.state.option === "relais") {
            data.append('json', JSON.stringify({
                type: 1,
                relayPoint: this.state.relais.relayPoint,
                relayName: this.state.relais.relayName,
                address: this.state.relais.address,
                postalCode: this.state.relais.postalCode,
                city: this.state.relais.city,
                country: this.state.relais.country,
                firstName: this.state.relais.firstName,
                lastName: this.state.relais.lastName,
                email: this.state.relais.email,
                phone: this.state.relais.phone ?? ""
            }));
        } else if (this.state.option === "home") {
            data.append('json', JSON.stringify({ address: this.state.address, type: 2 }));
        } else {
            data.append('json', JSON.stringify({
                type: 0,
                firstName: this.state.come.firstName,
                lastName: this.state.come.lastName,
                email: this.state.come.email,
                phone: this.state.come.phone ?? ""
            }));
        }
        let request = new Request(process.env.REACT_APP_API_URL + '/client/delivery/choose/' + this.props.id, { method: 'POST', body: data, credentials: 'include' });
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            if (data["choose"]) {
                this.setState({ loadingNextStep: false })
                this.setState({ shouldNavigate: true, navigateTo: '/payment/' + encode(this.props.id) });
            }
            this.setState({ loadingNextStep: false })
        })
    }

    setAddress = (id, firstName, lastName, streetAddress, streetAddressMore, postalCode, city, country, phone) => {
        this.setState({
            address: {
                id: id,
                firstName: firstName,
                lastName: lastName,
                streetAddress: streetAddress,
                streetAddressMore: streetAddressMore,
                postalCode: postalCode,
                city: city,
                country: country,
                phone: phone
            },
        }, () => this.getDO(() => { this.calculatetotalAmount() }))
        this.closeModal();
    }

    getInfos = () => {
        if (!this.state.relais.lastName) {
            this.setState({
                relais: {
                    lastName: this.state.user.lastName,
                    firstName: this.state.user.firstName,
                    email: this.state.user.email,
                    phone: this.state.user.addresses.length > 0 ? this.state.user.addresses[0].phone : undefined
                },
            })
        }

        if (!this.state.come.lastName) {
            this.setState({
                come: {
                    lastName: this.state.user.lastName,
                    firstName: this.state.user.firstName,
                    email: this.state.user.email,
                    phone: this.state.user.addresses.length > 0 ? this.state.user.addresses[0].phone : undefined
                },
            })
        }
    }

    formValid = () => {
        if (this.state.option === "relais") {
            return this.state.selectedPoint && Object.keys(this.state.selectedPoint).length > 1 &&
                this.state.relais.firstName && this.state.relais.firstName.length > 0 &&
                this.state.relais.lastName && this.state.relais.lastName.length > 0 &&
                this.state.relais.email && this.state.relais.email.length > 0 &&
                !this.state.errors.phone && this.state.deliveryOptions.relay.total != null
        }

        if (this.state.option === "home") return this.state.address.id != null && this.state.deliveryOptions.home.total != null

        if (this.state.option === "come") {
            return this.state.come.firstName && this.state.come.firstName.length > 0 &&
                this.state.come.lastName && this.state.come.lastName.length > 0 &&
                this.state.come.email && this.state.come.email.length > 0 &&
                !this.state.errors.comePhone
        }

        return false;

    }

    getBasket() {
        if (this.isFetchingBasket) { return; }
        this.isFetchingBasket = true;
        let request = new Request(process.env.REACT_APP_API_URL + '/client/basket', { method: 'GET', credentials: 'include' });
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({ basket: data });
            this.isFetchingBasket = false;
            this.loading = false;
            this.basket = (Object.keys(data).length > 0);
            this.forceUpdate();
        })
    }

    getTotalArticles() {
        const totalArticles = this.state.basket.articles ? this.state.basket.articles.reduce((total, article) => {
            return total + (article.quantity || 0);
        }, 0)
            : 0
        return totalArticles
    }
    
    getNombreArticle() {
        const totalArticles = this.state.basket.articles ? this.state.basket.articles.reduce((total, article) => {
            return total + (article.quantity || 0);
        }, 0)
            : 0

        // Retourner la chaîne formatée
        return `${totalArticles} article${totalArticles > 0 ? 's' : ''}`; // Ajoute 's' si le nombre n'est pas 1
    }

    calculatetotalAmount(value) {
        const option = value ? value : this.state.option;
        let startTotalAmount = 0;

        if (this.state.deliveryOptions && this.state.basket.articles) {
            for (let i = 0; i < this.state.basket.articles?.length; i++) {
                const articlePrice = parseInt(this.state.basket.articles[i].article.priceTtc);
                const quantity = parseInt(this.state.basket.articles[i].quantity);
                startTotalAmount += articlePrice * quantity;
            }

            let additionalCost = 0;

            if (option === "home" && this.state.deliveryOptions.home.total) {
                additionalCost = parseInt(this.state.deliveryOptions.home.total);
            } else if (option === "relais" && this.state.deliveryOptions.relay.total) {
                additionalCost = parseInt(this.state.deliveryOptions.relay.total);
            } else if (option === "come") {
                additionalCost = 0;
            }
            this.setState({
                totalAmount: (startTotalAmount + additionalCost) / 100
            });
        }
    }


    getdataBasket() {
        const { articles } = this.state.basket;

        if (!articles || articles.length === 0) {
            return [];
        }

        const mediaList = articles.map(articleObj => {
            const media = articleObj.article.media;
            const name = articleObj.article.name;

            // Vérifie si 'media' existe et contient au moins un élément
            if (media && media.length > 0) {
                return {
                    name: name,
                    media: media[0],
                    qty: articleObj.quantity
                };
            } else {
                return {
                    name: name,
                    media: null,
                    qty: articleObj.quantity
                };
            }
        });
        return mediaList;
    }

    changeOption(value) {
        this.setState({ option: value });
        this.calculatetotalAmount(value);
    }

    searchRelay() {
        this.getDO(() => {
            this.reloadRelay = true;
            this.selected(false);
            this.getListPoints();
            this.forceUpdate();
        })
    }

    keyUp(event) {
        if (event.key === "Enter") {
            this.searchRelay();
        }
    }

    renderChild() {
        return (
            <div>
                <Bandeau title={titleAriane["delivery"]["title"]} />
                <FilAriane
                    links={[
                        [
                            titleAriane["delivery"]["ariane"][0],
                            "/basket"
                        ],
                        [
                            titleAriane["delivery"]["ariane"][1],
                            ""
                        ]
                    ]}
                />
                {this.loadingDO && this.loadingPts && this.loadingAddress ? <div className="loader"><div></div></div> :
                    <>
                        <div className={globalStyles.headerStep}>
                            <div className={globalStyles.headernbArticlePrice}>
                                <div className={globalStyles.headerStepPrice}>
                                    Montant total : {this.state.totalAmount === 0 ? "-" : <DisplayPrice price={this.state.totalAmount} />}
                                </div>
                            </div>
                            <div className={globalStyles.containerBtnError}>
                                <button className={`${globalStyles.btnNextStep} ${this.formValid() ? globalStyles.defaultBtn : formStyles.disabledBtn}`}
                                    disabled={!this.formValid()}
                                    onClick={() => this.choose()}
                                    data-tip={
                                        !this.formValid() ?
                                            (!this.state.selectedPoint || Object.keys(this.state.selectedPoint).length <= 1 ?
                                                "Sélectionne un point relais pour continuer ta commande" :
                                                "Remplis les informations du destinataire pour continuer ta commande") :
                                            ""
                                    }
                                >
                                    {this.state.loadingNextStep ? <div className={globalStyles.loaderNextStep}></div> : <div>Passer la commande</div>}
                                </button>
                            </div>
                        </div>
                        <div className={globalStyles.globalContainer} style={{ flexDirection: "column", alignItems: "unset", padding: window.innerWidth > 960 ? "unset" : "10px" }}>

                            {localStorage.getItem('fromValidateBasket') === "true" ?
                                <>
                                    <Step state="green" text="Aperçu du panier" />
                                    <Step state="green" text="Inscrirption / Connexion" />
                                </>
                                : ""}
                            <Step state="green" text="Contenu de la commande" totalArticles={this.getTotalArticles()} dataBasket={this.getdataBasket()} btnPrevUrl="/basket" btnPrevText="Modifier..." />
                            <div className={globalStyles.containerStepAndBasket}>
                                <Step text="Mode de livraison" image="livraison" />
                                <div className={globalStyles.basketContainer}>
                                    <div style={{ flexDirection: window.innerWidth > 1020 ? "row" : "column", display: "flex" }}>

                                        {this.state.deliveryOptions.come &&
                                            <div className={`${globalStyles.card} ${globalStyles.cardMore}`} style={{
                                                marginRight: window.innerWidth > 1020 ? "10px" : 0,
                                                marginTop: "10px",
                                                marginBottom: "10px",
                                                background: this.state.option !== "come" ? "white" : "rgb(243, 246, 248)",
                                                height: "unset",
                                                cursor: "pointer"
                                            }}
                                                onClick={() => this.changeOption("come")}
                                            >
                                                <div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
                                                    <div style={{ flex: 3, justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
                                                        <div style={{ display: "flex", flexDirection: 'column' }}>
                                                            <h3 style={{ display: "flex", alignItems: 'center', margin: "0 0 15px 0" }}>
                                                                <input
                                                                    id="retraitradio"
                                                                    type={"radio"}
                                                                    style={{ height: "20px", width: "20px", marginRight: "20px" }}
                                                                    value={"come"}
                                                                    name={"delivery"}
                                                                    checked={this.state.option === "come"}
                                                                    onChange={(event) => this.changeOption(event.target.value)}
                                                                />
                                                                <label htmlFor="retraitradio">
                                                                    <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: "15px", marginTop: "4px" }} />
                                                                    Retrait
                                                                </label>
                                                            </h3>
                                                            <p style={{ marginTop: "5px", marginBottom: "0", color: "#555" }}>
                                                                Retrait sur rendez-vous
                                                            </p>
                                                        </div>
                                                        <div style={{ textAlign: "right", marginTop: "15px" }}>
                                                            <span className={globalStyles.badge}
                                                                style={{ backgroundColor: "#6fa939" }}>
                                                                Offert
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {this.state.deliveryOptions.relay &&
                                            <div className={`${globalStyles.card} ${globalStyles.cardMore}`} style={{
                                                marginRight: window.innerWidth > 1020 ? "10px" : 0,
                                                marginLeft: window.innerWidth > 1020 ? "10px" : 0,
                                                marginTop: "10px",
                                                marginBottom: "10px",
                                                background: this.state.option !== "relais" ? "white" : "rgb(243, 246, 248)",
                                                height: "unset",
                                                justifyContent: 'space-between',
                                                cursor: 'pointer'
                                            }}
                                                onClick={() => this.changeOption("relais")}
                                            >
                                                <div>
                                                    <h3 style={{ display: "flex", alignItems: 'center', margin: "0 0 15px 0" }}>
                                                        <input
                                                            id="relaisradio"
                                                            type={"radio"}
                                                            style={{ height: "20px", width: "20px", marginRight: "20px" }}
                                                            value={"relais"}
                                                            name={"delivery"}
                                                            checked={this.state.option === "relais"}
                                                            onChange={(event) => this.changeOption(event.target.value)}
                                                        />
                                                        <label htmlFor="relaisradio">
                                                            <FontAwesomeIcon icon={faStore} style={{ marginRight: "15px", marginTop: "4px" }} />
                                                            En point relais
                                                        </label>
                                                    </h3>
                                                    <p style={{ marginTop: "5px", marginBottom: "0", color: "#555" }}>
                                                        {this.getDetails("relay")}
                                                    </p>
                                                </div>
                                                <h2 style={{ margin: "15px 0 0 0", textAlign: "end" }}>
                                                    {this.state.deliveryOptions.relay.total ? <DisplayPrice price={this.state.deliveryOptions.relay.total / 100} /> : "-"}

                                                </h2>
                                            </div>
                                        }
                                        {this.state.deliveryOptions.home &&
                                            <div className={`${globalStyles.card} ${globalStyles.cardMore}`} style={{ marginLeft: window.innerWidth > 1020 ? "10px" : 0, marginTop: "10px", marginBottom: "10px", background: this.state.option !== "home" ? "white" : "rgb(243, 246, 248)", height: "unset", justifyContent: 'space-between', cursor: 'pointer' }}
                                                onClick={() => this.changeOption("home")}
                                            >
                                                <div>
                                                    <h3 style={{ display: "flex", alignItems: 'center', margin: "0 0 15px 0" }}>
                                                        <input
                                                            id="homeradio"
                                                            type={"radio"}
                                                            style={{ height: "20px", width: "20px", marginRight: "20px" }}
                                                            value={"home"}
                                                            name={"delivery"}
                                                            checked={this.state.option === "home"}
                                                            onChange={(event) => this.changeOption(event.target.value)}
                                                        />
                                                        <label htmlFor="homeradio">
                                                            <FontAwesomeIcon icon={faTruck} style={{ marginRight: "15px", marginTop: "4px" }} />
                                                            A domicile
                                                        </label>
                                                    </h3>
                                                    <p style={{ marginTop: "5px", marginBottom: "0", color: "#555" }}>
                                                        {this.getDetails("home")}
                                                    </p>
                                                </div>
                                                <h2 style={{ margin: "15px 0 0 0", textAlign: "end" }}>
                                                    {this.state.deliveryOptions.home.total ? <DisplayPrice price={this.state.deliveryOptions.home.total / 100} /> : "-"}
                                                </h2>
                                            </div>
                                        }
                                    </div>

                                    {this.state.option !== "come" && !this.state.deliveryOptions.relay.total && !this.state.deliveryOptions.home.total &&
                                        <div className={styles.cardContainer}>
                                            <div
                                                className={globalStyles.card}
                                                style={{
                                                    padding: "30px",
                                                    flex: 1,
                                                    backgroundColor: "#f3f6f8",
                                                    boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                                    borderRadius: "5px",
                                                    display: "flex",
                                                    height: "100%",
                                                    boxSizing: "border-box",
                                                    flexDirection: "column"
                                                }}
                                            >
                                                <div style={{ flex: 5 }}>
                                                    <h2 style={{ margin: "0 0 15px 0" }}>
                                                        <FontAwesomeIcon icon={faTimes} style={{ marginRight: "15px" }} />
                                                        Oups...
                                                    </h2>

                                                    Aucune option de livraison ne semble pour l'instant disponible pour ta commande.<br />
                                                    Pas de panique, essaie d'abord de trouver <b>un point relais ailleurs</b> ou de saisir <b>une autre adresse</b>.
                                                    <br /><br />
                                                    Si rien ne change, c'est que ta commande est vraiment particulière ! <br /><br />
                                                    Dans ce cas, contacte-nous sur <a href={"mailto:support@atorika.fr"} className={globalStyles.defaultLink}>support@atorika.fr</a> pour qu'on trouve ensemble une solution.
                                                </div>
                                            </div>
                                        </div>
                                    }


                                    {this.state.option === "home" &&
                                        <div>
                                            <div className={styles.cardContainer}>
                                                <div
                                                    className={globalStyles.card}
                                                    style={{
                                                        padding: "20px 30px 30px",
                                                        flex: 1,
                                                        backgroundColor: "#f3f6f8",
                                                        boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                                        borderRadius: "5px",
                                                        display: "flex",
                                                        height: "100%",
                                                        boxSizing: "border-box",
                                                        flexDirection: "column"
                                                    }}
                                                >
                                                    <ListAddress
                                                        title={"Mes adresses"}
                                                        desktopNumber={3}
                                                        mobileNumber={1}
                                                        type={0}
                                                        state={this.state}
                                                        fetchJsonOrError={this.fetchJsonOrError}
                                                        add={["btn", this.openModalAdd.bind(this)]}
                                                        choose={true}
                                                        chooseFtn={this.setAddress.bind(this)}
                                                        color="white"
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    }


                                    <Modal
                                        isOpen={this.isOpenAdd}
                                        contentLabel="Adresse de livraison"
                                        bodyOpenClassName={"noScroll"}
                                        style={{
                                            content: {
                                                top: '50%',
                                                left: '50%',
                                                right: 'auto',
                                                bottom: 'auto',
                                                transform: 'translate(-50%, -50%)',
                                                zIndex: 500,
                                                width: "70%",
                                                padding: "40px",
                                                paddingTop: "20px",
                                                maxHeight: (window.innerHeight - 180) + "px",
                                                borderRadius: "15px"
                                            },
                                            overlay: {
                                                zIndex: 1500,
                                                backgroundColor: "rgba(0, 0, 0, 0.8)"
                                            }
                                        }}
                                    >
                                        <AddAddressDelivery
                                            fetchJsonOrError={this.fetchJsonOrError}
                                            countries={this.state.countries}
                                        />
                                        <button
                                            style={{
                                                position: "absolute",
                                                top: "20px",
                                                right: "20px",
                                                background: "none",
                                                border: "none",
                                                fontSize: "20px",
                                                cursor: "pointer"
                                            }}
                                            onClick={() => this.closeModalAdd()}
                                        >
                                            <FontAwesomeIcon icon={faTimes} />
                                        </button>
                                    </Modal>
                                    
                                    {this.state.option === "relais" &&
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <div className={styles.cardContainer}>
                                                <div
                                                    className={globalStyles.card}
                                                    style={{
                                                        padding: "30px",
                                                        flex: 1,
                                                        backgroundColor: "#f3f6f8",
                                                        boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                                        borderRadius: "5px",
                                                        display: "flex",
                                                        height: "100%",
                                                        boxSizing: "border-box",
                                                        flexDirection: "column"
                                                    }}
                                                >
                                                    <div style={{ flex: 5, position: "relative" }}>
                                                        <h2 style={{ marginTop: "0" }}>Choisis ton point relais</h2>
                                                        {this.state.relais.relayName &&
                                                            <div className={styles.currentRelay}>
                                                                <div><img src={marker} alt="" width="30" /></div>
                                                                <div>
                                                                    <span style={{ fontWeight: "bold" }}> {this.state.relais.relayName}</span>
                                                                    <br />
                                                                    {this.state.relais.postalCode} {this.state.relais.city}
                                                                </div>
                                                            </div>
                                                        }
                                                        <div style={{ flexDirection: "column", marginTop: "15px", marginBottom: "15px" }}>

                                                            <div className={formStyles.inputLine} style={{ marginBottom: "10px" }}>

                                                                <div className={formStyles.inputGroup} style={{ marginLeft: 0, marginRight: window.innerWidth < 1020 ? 0 : undefined }}>
                                                                    <label>
                                                                        Code postal*
                                                                    </label>
                                                                    <div className={formStyles.inputField}>
                                                                        <div className={formStyles.inputIcon}>
                                                                            <FontAwesomeIcon icon={faMailBulk} />
                                                                        </div>
                                                                        <input type="text" required className={formStyles.input}
                                                                            placeholder="00000" value={this.state.postalCode ?? ""}
                                                                            onKeyUp={this.keyUp.bind(this)}
                                                                            onChange={(event) => this.setState({ postalCode: event.target.value })}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className={formStyles.inputGroup}
                                                                    style={{
                                                                        marginLeft: window.innerWidth < 1020 ? 0 : undefined,
                                                                        marginRight: window.innerWidth < 1020 ? 0 : undefined
                                                                    }}
                                                                >
                                                                    <label>Ville*</label>
                                                                    <div className={formStyles.inputField}>
                                                                        <div className={formStyles.inputIcon}>
                                                                            <FontAwesomeIcon icon={faCity} />
                                                                        </div>
                                                                        <input type="text" required className={formStyles.input}
                                                                            placeholder="Ville"
                                                                            value={this.state.city ?? ""}
                                                                            onKeyUp={this.keyUp.bind(this)}
                                                                            onChange={(event) => this.setState({ city: event.target.value.toUpperCase() })} />
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className={formStyles.inputGroup}
                                                                    style={{
                                                                        flex: 2,
                                                                        marginLeft: window.innerWidth < 1020 ? 0 : undefined,
                                                                        marginRight: window.innerWidth < 1020 ? 0 : undefined
                                                                    }}
                                                                >
                                                                    <label>Pays*</label>
                                                                    <div className={formStyles.inputField}>
                                                                        <div className={formStyles.inputIcon}>
                                                                            <FontAwesomeIcon icon={faGlobeEurope} />
                                                                        </div>
                                                                        <select value={this.state.country} className={formStyles.input} onChange={(event) => {
                                                                            this.setState({ country: event.target.value });
                                                                        }}>
                                                                            {
                                                                                this.state.countries?.map((country, index) =>
                                                                                    <option
                                                                                        key={index}
                                                                                        value={country.label}>{country.label}</option>
                                                                                )
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div style={{
                                                                    display: "flex",
                                                                    alignItems: "flex-end",
                                                                    justifyContent: "flex-end",
                                                                    paddingBottom: "12px"
                                                                }}>
                                                                    <button
                                                                        className={this.state.postalCode && this.state.city && this.state.country ? globalStyles.defaultBtn : formStyles.disabledBtn}
                                                                        disabled={!(this.state.postalCode && this.state.city && this.state.country)}
                                                                        style={{ padding: "9px 20px", fontSize: "1rem" }}
                                                                        onClick={this.searchRelay.bind(this)}>
                                                                        Chercher
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            {Object.keys(this.state.listpoints).length > 0 && !this.reloadRelay ? (
                                                                <div style={{ display: "flex", flexDirection: window.innerWidth > 1020 ? "row" : "column" }}>
                                                                    <ListPoints
                                                                        listpoints={this.state.listpoints}
                                                                        select={this.selected.bind(this)}
                                                                    />
                                                                    <div
                                                                        style={{
                                                                            flex: window.innerWidth > 1020 ? 1 : undefined,
                                                                            height: "500px",
                                                                            marginTop: window.innerWidth < 1020 ? "10px" : undefined,
                                                                        }}>
                                                                        <MapPoints
                                                                            listpoints={this.state.listpoints}
                                                                            selected={this.selected.bind(this)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ) : (Object.keys(this.state.deliveryOptions.relay.carriers).length === 0 || this.state.nbPoints === 0) && !this.isFetchingListPoints ? (
                                                                <div>
                                                                    Aucun point relais disponible
                                                                </div>
                                                            ) : this.isFetchingListPoints ? (
                                                                <div className="loader"><div></div></div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.cardContainer}>
                                                <div
                                                    className={globalStyles.card}
                                                    style={{
                                                        padding: "30px",
                                                        flex: 1,
                                                        backgroundColor: "#f3f6f8",
                                                        boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                                        borderRadius: "5px",
                                                        display: "flex",
                                                        height: "100%",
                                                        boxSizing: "border-box",
                                                        flexDirection: "column"
                                                    }}
                                                >
                                                    <div>
                                                        <div style={{ flexDirection: "column" }}>
                                                            <div className={formStyles.inputLine}>
                                                                <div className={formStyles.inputGroup} style={{ marginLeft: 0, marginRight: window.innerWidth < 1020 ? 0 : undefined }}>
                                                                    <label>
                                                                        Nom du destinataire*
                                                                    </label>
                                                                    <div className={formStyles.inputField}>
                                                                        <div className={formStyles.inputIcon}>
                                                                            <FontAwesomeIcon icon={faIdBadge} />
                                                                        </div>
                                                                        <input type="text" required className={formStyles.input}
                                                                            placeholder="Nom" value={this.state.relais.lastName.toUpperCase() ?? this.state.user.lastName.toUpperCase() ?? ""}
                                                                            onChange={(event) =>
                                                                                this.setState({ relais: { ...this.state.relais, lastName: event.target.value.toUpperCase() } })
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className={formStyles.inputGroup}
                                                                    style={{
                                                                        marginLeft: window.innerWidth < 1020 ? 0 : undefined,
                                                                        marginRight: window.innerWidth < 1020 ? 0 : undefined
                                                                    }}
                                                                >
                                                                    <label>Prénom du destinataire*</label>
                                                                    <div className={formStyles.inputField}>
                                                                        <div className={formStyles.inputIcon}>
                                                                            <FontAwesomeIcon icon={faChild} />
                                                                        </div>
                                                                        <input type="text" required className={formStyles.input}
                                                                            placeholder="Prénom"
                                                                            value={this.state.relais.firstName ?? this.state.user.firstName ?? ""}
                                                                            onChange={(event) =>
                                                                                this.setState({ relais: { ...this.state.relais, firstName: event.target.value } })
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div style={{ flexDirection: "column" }}>
                                                                <div className={formStyles.inputLine} style={{ marginBottom: "10px" }}>
                                                                    <div className={formStyles.inputGroup} style={{ marginLeft: 0, marginRight: window.innerWidth < 1020 ? 0 : undefined }}>
                                                                        <label>
                                                                            Adresse e-mail du destinataire*
                                                                            <FontAwesomeIcon
                                                                                className={formStyles.iconHelp}
                                                                                icon={faQuestionCircle}
                                                                                data-tip="Cela permettera de faciliter le suivi et la livraison."
                                                                                tabIndex={-1}
                                                                            />
                                                                        </label>
                                                                        <div className={formStyles.inputField}>
                                                                            <div className={formStyles.inputIcon}>
                                                                                <FontAwesomeIcon icon={faAt} />
                                                                            </div>
                                                                            <input type="text" required className={formStyles.input}
                                                                                placeholder="Adresse e-mail" value={this.state.relais.email ?? ""}
                                                                                onChange={(event) =>
                                                                                    this.setState({ relais: { ...this.state.relais, email: event.target.value } })
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        className={formStyles.inputGroup}
                                                                        style={{
                                                                            marginLeft: window.innerWidth < 1020 ? 0 : undefined,
                                                                            marginRight: window.innerWidth < 1020 ? 0 : undefined
                                                                        }}
                                                                    >
                                                                        <label>
                                                                            Numéro de téléphone
                                                                            <FontAwesomeIcon
                                                                                className={formStyles.iconHelp}
                                                                                icon={faQuestionCircle}
                                                                                data-tip="Cela permettera de faciliter le suivi et la livraison."
                                                                                tabIndex={-1}
                                                                            />
                                                                        </label>
                                                                        <div className={formStyles.inputField}>
                                                                            <div className={formStyles.inputIcon}>
                                                                                <FontAwesomeIcon icon={faPhone} />
                                                                            </div>
                                                                            <input type="text" required className={formStyles.input}
                                                                                placeholder="0600000000"
                                                                                pattern={
                                                                                    this.state.countries?.map(country =>
                                                                                        country.phoneRegex
                                                                                    )?.join('|')
                                                                                }
                                                                                value={this.state.relais.phone ?? ""}
                                                                                onChange={(event) =>
                                                                                    this.setState({ relais: { ...this.state.relais, phone: event.target.value }, errors: { ...this.state.errors, phone: (event.target.validity.patternMismatch && event.target.value !== '') } })
                                                                                }
                                                                            />
                                                                        </div>
                                                                        {this.state.errors.phone &&
                                                                            <span className={formStyles.textDanger}>Le numéro de téléphone doit être valide.</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ReactTooltip multiline={true} place="top" effect="solid" />
                                        </div>
                                    }

                                    {this.state.option === "come" &&
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <div className={styles.cardContainer}>
                                                <div
                                                    className={globalStyles.card}
                                                    style={{
                                                        padding: "20px 30px 30px",
                                                        flex: 1,
                                                        backgroundColor: "#f3f6f8",
                                                        boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                                        borderRadius: "5px",
                                                        display: "flex",
                                                        height: "100%",
                                                        boxSizing: "border-box",
                                                        flexDirection: window.innerWidth > 1020 ? "row" : "column",
                                                    }}
                                                >
                                                    <div style={{ flex: 1, marginRight: window.innerWidth > 1020 ? "10px" : undefined }} dangerouslySetInnerHTML={{ __html: this.state.deliveryOptions.come[0] }} />
                                                    <div style={{ flex: 1, marginLeft: window.innerWidth > 1020 ? "10px" : undefined }} dangerouslySetInnerHTML={{ __html: this.state.deliveryOptions.come[1] }} />
                                                </div>
                                            </div>

                                            <div className={styles.cardContainer}>
                                                <div
                                                    className={globalStyles.card}
                                                    style={{
                                                        padding: "30px",
                                                        flex: 1,
                                                        backgroundColor: "#f3f6f8",
                                                        boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                                        borderRadius: "5px",
                                                        display: "flex",
                                                        height: "100%",
                                                        boxSizing: "border-box",
                                                        flexDirection: "column"
                                                    }}
                                                >
                                                    <div>
                                                        <div style={{ flexDirection: "column", marginTop: "15px" }}>
                                                            <div className={formStyles.inputLine}>
                                                                <div className={formStyles.inputGroup} style={{ marginLeft: 0, marginRight: window.innerWidth < 1020 ? 0 : undefined }}>
                                                                    <label>
                                                                        Nom du destinataire*
                                                                    </label>
                                                                    <div className={formStyles.inputField}>
                                                                        <div className={formStyles.inputIcon}>
                                                                            <FontAwesomeIcon icon={faIdBadge} />
                                                                        </div>
                                                                        <input type="text" required className={formStyles.input}
                                                                            placeholder="Nom" value={this.state.come.lastName.toUpperCase() ?? this.state.user.lastName.toUpperCase() ?? ""}
                                                                            onChange={(event) =>
                                                                                this.setState({ come: { ...this.state.come, lastName: event.target.value.toUpperCase() } })
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className={formStyles.inputGroup}
                                                                    style={{
                                                                        marginLeft: window.innerWidth < 1020 ? 0 : undefined,
                                                                        marginRight: window.innerWidth < 1020 ? 0 : undefined
                                                                    }}
                                                                >
                                                                    <label>Prénom du destinataire*</label>
                                                                    <div className={formStyles.inputField}>
                                                                        <div className={formStyles.inputIcon}>
                                                                            <FontAwesomeIcon icon={faChild} />
                                                                        </div>
                                                                        <input type="text" required className={formStyles.input}
                                                                            placeholder="Prénom"
                                                                            value={this.state.come.firstName ?? this.state.user.firstName.toUpperCase() ?? ""}
                                                                            onChange={(event) =>
                                                                                this.setState({ come: { ...this.state.come, firstName: event.target.value } })
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <div style={{ flexDirection: "column", marginBottom: "25px" }}>
                                                            <div className={formStyles.inputLine} style={{ marginBottom: "10px" }}>
                                                                <div className={formStyles.inputGroup} style={{ marginLeft: 0, marginRight: window.innerWidth < 1020 ? 0 : undefined }}>
                                                                    <label>
                                                                        Adresse e-mail du destinataire*
                                                                        <FontAwesomeIcon
                                                                            className={formStyles.iconHelp}
                                                                            icon={faQuestionCircle}
                                                                            data-tip="Cela permettera de faciliter le suivi et la livraison."
                                                                            tabIndex={-1}
                                                                        />
                                                                    </label>
                                                                    <div className={formStyles.inputField}>
                                                                        <div className={formStyles.inputIcon}>
                                                                            <FontAwesomeIcon icon={faAt} />
                                                                        </div>
                                                                        <input type="text" required className={formStyles.input}
                                                                            placeholder="Adresse e-mail" value={this.state.come.email ?? ""}
                                                                            onChange={(event) =>
                                                                                this.setState({ come: { ...this.state.come, email: event.target.value } })
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className={formStyles.inputGroup}
                                                                    style={{
                                                                        marginLeft: window.innerWidth < 1020 ? 0 : undefined,
                                                                        marginRight: window.innerWidth < 1020 ? 0 : undefined
                                                                    }}
                                                                >
                                                                    <label>
                                                                        Numéro de téléphone
                                                                        <FontAwesomeIcon
                                                                            className={formStyles.iconHelp}
                                                                            icon={faQuestionCircle}
                                                                            data-tip="Cela permettera de faciliter le suivi et la livraison."
                                                                            tabIndex={-1}
                                                                        />
                                                                    </label>
                                                                    <div className={formStyles.inputField}>
                                                                        <div className={formStyles.inputIcon}>
                                                                            <FontAwesomeIcon icon={faPhone} />
                                                                        </div>
                                                                        <input type="text" required className={formStyles.input}
                                                                            placeholder="0600000000"
                                                                            pattern={
                                                                                this.state.countries?.map(country =>
                                                                                    country.phoneRegex
                                                                                )?.join('|')
                                                                            }
                                                                            onChange={(event) =>
                                                                                this.setState({ come: { ...this.state.come, phone: event.target.value }, errors: { ...this.state.errors, comePhone: (event.target.validity.patternMismatch && event.target.value !== '') } })
                                                                            }
                                                                        />
                                                                    </div>
                                                                    {this.state.errors.comePhone &&
                                                                        <span className={formStyles.textDanger}>Le numéro de téléphone doit être valide.</span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ReactTooltip multiline={true} place="top" effect="solid" />
                                        </div>
                                    }
                                </div>
                            </div>
                            <Step state="grey" text="Paiement" image="paiement" btnNext={true} disabledBtn={!this.formValid()} onValidate={this.choose} loadingNextStep={this.state.loadingNextStep}
                                errorMessage={!this.state.selectedPoint || Object.keys(this.state.selectedPoint).length <= 1 ?
                                    "Sélectionne un point relais pour continuer ta commande" :
                                    "Remplis les informations du destinataire pour continuer ta commande"}
                            />
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                                className={globalStyles.btnBottomPage}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flex: 1,
                                        justifyContent: "flex-start",
                                        alignItems: "flex-start",
                                        textAlign: "left",
                                        marginTop: "10px",
                                        marginRight: window.innerWidth < 1020 ? "5px" : undefined,
                                    }}
                                >
                                    <a href={"/basket"} className={globalStyles.secondaryBtn}>
                                        Retour au panier
                                    </a>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: 'column',
                                        flex: 1,
                                        justifyContent: "flex-end",
                                        alignItems: "flex-end",
                                        textAlign: "left",
                                        marginTop: "10px",
                                        marginLeft: window.innerWidth < 1020 ? "5px" : undefined,
                                        position: "relative"
                                    }}
                                >
                                    <button
                                        disabled={!this.formValid()}
                                        onClick={() => this.choose()}
                                        className={`${globalStyles.btnNextStep} ${this.formValid() ? globalStyles.defaultBtn : formStyles.disabledBtn}`}
                                        data-tip={
                                            !this.formValid() ?
                                                (!this.state.selectedPoint || Object.keys(this.state.selectedPoint).length <= 1 ?
                                                    "Sélectionne un point relais pour continuer ta commande" :
                                                    "Remplis les informations du destinataire pour continuer ta commande") :
                                                ""
                                        }
                                    >
                                        {this.state.loadingNextStep ? <div className={globalStyles.loaderNextStep}></div> : <div>Passer la commande</div>}
                                    </button>

                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>

        );
    }
}

export default connect(mapStateToProps)(Delivery);
