import React from "react"
import LoggedComponent, { mapStateToProps } from "../../../Services/ConnexionService";
import { connect } from "react-redux";
import globalStyles from "../../../Styles/Style.module.css";
import FilAriane from "../../Header_Footer/FilAriane";
import CardTemplateBasket from "./CardTemplateBasket";
import Bandeau from "../../Header_Footer/Bandeau";
import formStyles from "../../../Styles/FormStyle.module.css";
import moment from "moment";
import { faCartArrowDown, faCheckCircle, faTag, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import titleAriane from "../../../Helpers/TitleAriane.json";
import DisplayPrice from "../../Utils/DisplayPrice";
import { encode } from "../../../Services/ObfuscatorService";
import StyleResume from "./ResumeBasket.module.css"
import style from "../Basket/AddItem/UnloggedValidateBasket.module.css";
import { faTruck, faMapMarkerAlt, faStore } from "@fortawesome/free-solid-svg-icons";
import Step from "./step";
import ReactTooltip from 'react-tooltip';
import Cookies from 'universal-cookie';
import AtoRikaTristes from "../../../assets/mascottes/ato_rika_tristes.svg"

const cookieOptions = { maxAge: `${process.env.REACT_APP_COOKIE_BASKET_MAX_AGE}`, path: '/', domain: `${process.env.REACT_APP_COOKIE_DOMAIN}` }
const cookies = new Cookies(null, cookieOptions);
class ResumeBasket extends LoggedComponent {

    constructor(props) {
        super(props);
        this.state = {
            basket: {},
            repartition: {},
            boxes: {},
            promo: null,
            errorPromo: false,
            hasPromo: false,
            loadingNextStep: false,
        }
        this.boxesInfos = {};
        this.loading = true;
        this.basket = undefined;
        this.selectedCountry = "France";
        this.deliveryOption = "";
        this.countries = [];
        this.deliveryOptions = [];
        this.filteredOptions = [];
        this.tabDeliveryOptions = ['Domicile', 'Point Relais'];
        this.isFetchingCountries = false;
        this.isFetchingBasket = false;

    }

    getCountries() {
        if (this.isFetchingCountries) { return; }
        this.isFetchingCountries= true;
        let request = new Request(process.env.REACT_APP_API_URL + '/client/countries', { method: 'GET', credentials: 'include' });
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            this.isFetchingCountries=false;
            data.countries.forEach(country => {
                if (!this.countries.includes(country.label)) {
                    this.countries.push(country.label);
                }
            });

        })
    }

    async getDeliveryOption() {

        var choosenArticle = null;

        for (let i = 0; i < this.state?.basket?.articles?.length; i++) {
            let article = this.state.basket.articles[i] ? this.state.basket.articles[i] : [];
            if (article && article.choosen) {
                choosenArticle = {
                    reference: article.choosen.reference,
                    quantity: 1,
                    weight: article.choosen.weight,
                    isCommitment: false,
                    commitmentDuration: null,
                };
            }
        }


        const jsonData = {
            articles: [
                ...(Array.isArray(this.state?.basket?.articles)
                    ? this.state.basket.articles.flatMap((item, index) => {
                        const article = item.article;
                        if (!article.reference) {
                            console.error(`L'article à l'index ${index} n'a pas de référence :`, article);
                        }

                        const mainArticle = {
                            reference: article.reference,
                            quantity: item.quantity ?? 1,
                            weight: article.weight || 0,
                            isCommitment: article.isCommitment || false,
                            commitmentDuration:
                                article.duration && article.isCommitment ? article.duration : null,
                        };

                        const firstBox = item.firstBox ? {
                            reference: item.firstBox.article.reference,
                            quantity: item.firstBox.article.quantity ?? 1,
                            weight: item.firstBox.article.weight || 0,
                            isCommitment: false,
                            commitmentDuration: null,
                        } : null;

                        return [mainArticle, firstBox].filter(Boolean); // Retourner uniquement les articles non nuls
                    })
                    : []),
                ...(choosenArticle && Object.keys(choosenArticle).length > 0
                    ? [choosenArticle]
                    : []),
            ],
            countryHome: this.selectedCountry,
            countryRelay: this.selectedCountry,
        };

        try {
            let response = await fetch(process.env.REACT_APP_API_URL + "/client/delivery/estimate", {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(jsonData),
            });

            if (response.status === 200) {
                const data = await response.json();
                this.deliveryOptions = data.deliveryOptions;
                this.forceUpdate();
            }
        } catch (error) {
            error.log(
                "Erreur lors de la récupération des options de livraison",
                error
            );
        }
    }

    getNombreArticle() {
        const totalArticles = this.state.basket.articles ? this.state.basket.articles.reduce((total, article) => {
            return total + (article.quantity || 0); // Ajouter la quantité de chaque article
        }, 0)
            : 0

        // Retourner la chaîne formatée
        return `${totalArticles} article${totalArticles > 1 ? 's' : ''}`; // Ajoute 's' si le nombre n'est pas 1
    }


    handleCountryChange = (event) => {
        this.selectedCountry = event.target.value;
        this.getDeliveryOption();
        // this.forceUpdate();
    };

    calculateTotalPriceArticle() {
        // enelver deuxieme partie de ref pour les abonnement
        // var finaleRefs = Object.keys(this.qts).reduce((acc, ref) => {
        //     let cleanedRef = ref.split('-')[0];
        //     acc[cleanedRef] = this.qts[ref];
        //     return acc;
        // }, {});

        let refs = this.state.basket.articles.map(element => element.article.reference);
        let finaleRefs = refs.map(ref => ref.split('-')[0]);


        return this.state.basket.articles.reduce((total, item) => {
            // Calculer le prix total pour cet article
            const itemTotal = (item.priceTtc / 100) * finaleRefs[item.reference];
            return total + itemTotal;
        }, 0);
    }

    formatPrice(price) {
        // Formater le prix avec deux décimales
        const formattedPrice = new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(price);

        // Diviser la chaîne pour séparer l'euro et les centimes
        const [euros, centimes] = formattedPrice.replace("€", "").split(",");

        return (
            <span className={style.euroPrice}>
                {euros}
                <span className={style.centimePrice}>€{centimes}</span>
            </span>
        );
    }

    priceDisplay(optionType) {
        const option = this.deliveryOptions[optionType];
        if (!option || !option.details) {
            return "-";
        }

        const details = option.details;


        const gratuit = (
            <span className={globalStyles.badge} style={{ backgroundColor: "#6fa939" }}>
                Offert
            </span>
        );

        if (Object.keys(details).length > 1) {
            const priceDetails = Object.entries(details)
                .map(([priceKey, quantity]) => {
                    const price = Number(priceKey) / 100;
                    const formattedPrice = `${price.toFixed(2).replace(".", ",")}€`;
                    return { price, quantity, formattedPrice };
                })
                .reverse(); //Mon detail est inversé donc je le reverse

            // Vérifie si tous les prix sont à zéro
            if (priceDetails.every(({ price }) => price === 0)) {
                return gratuit;
            }

            const priceDetail = priceDetails.flatMap(({ quantity, formattedPrice }, index) => {
                const result = [];

                if (index === 0) {
                    // Première ligne : toujours 1x pour le premier mois
                    result.push(`Pour le premier mois : 1 x ${formattedPrice}`);

                    // Ajout d'une ligne pour le reste du premier item, si quantity > 1
                    if (quantity > 1) {
                        result.push(`Pour les ${quantity - 1} mois suivants : ${quantity - 1} x ${formattedPrice}`);
                    }
                } else {
                    // Pour les lignes suivantes
                    result.push(`Pour les ${quantity} mois suivants : ${quantity} x ${formattedPrice}`);
                }

                return result;
            }).join("<br/>");




            const total = priceDetails.reduce((sum, { price, quantity }) => sum + (price * quantity), 0);

            const tooltipId = `tooltip-${optionType}`;

            return (
                <div>
                    <span>{this.formatPrice(total)}</span>
                    <FontAwesomeIcon
                        className={formStyles.iconHelp}
                        icon={faQuestionCircle}
                        tabIndex={-1}
                        data-tip={priceDetail}
                        data-for={tooltipId}
                    />
                    <ReactTooltip id={tooltipId} multiline={true} place="top" effect="solid" />
                </div>
            );
        } else if (Object.keys(details).length === 1) {
            const [priceKey] = Object.keys(details);
            const price = Number(priceKey) / 100;
            const formattedPrice = `${price.toFixed(2).replace(".", ",")}€`;
            const duration = details[priceKey];
            let total = price * duration;

            if (priceKey === "0") {
                return gratuit;
            }

            if (duration === 1) {
                return (
                    <div>
                        <span>{formattedPrice}</span>
                    </div>
                );
            } else if (duration > 1) {
                const extraQuantity = duration - 1;
                const priceDetail = `Pour le premier mois : 1 x ${formattedPrice} <br />Pour les ${extraQuantity} mois suivants : ${extraQuantity} x ${formattedPrice}`;
                const tooltipId = `tooltip-${optionType}`;

                return (
                    <div>
                        <span>{this.formatPrice(total)}</span>
                        <FontAwesomeIcon
                            className={formStyles.iconHelp}
                            icon={faQuestionCircle}
                            tabIndex={-1}
                            data-tip={priceDetail}
                            data-for={tooltipId}
                        />
                        <ReactTooltip id={tooltipId} multiline={true} place="top" effect="solid" />
                    </div>
                );
            }

            return formattedPrice;
        }

        return "-";
    }


    doesMustLog = () => {
        this.mustLog = 1
    }

    setBox = (oa, reference) => {
        this.state.boxes[oa] = reference;
        this.forceUpdate();
    }

    getBox = (oa) => {
        return this.state.boxes[oa];
    }

    setBoxInfos = (boxRef, infos) => {
        this.boxesInfos[boxRef] = infos;
    }

    getBoxInfos = (boxRef) => {
        return this.boxesInfos[boxRef];
    }


    setQuantity = (oa, user, quantity, all) => {
        if (!this.state.repartition[oa] || all) {
            this.state.repartition[oa] = {};
        }
        this.state.repartition[oa][user] = parseInt(quantity);
        this.forceUpdate();
        let qty = 0;

        for (let c in this.state.repartition[oa]) {
            qty += parseInt(this.state.repartition[oa][c]);
        }
        this.changeQuantity(oa, qty);
    }

    cleanQuantity = (oa) => {
        delete this.state.repartition[oa];
        this.forceUpdate();
    }

    getQuantity = (oa) => {
        for (let i = 0; i < this.state.basket.articles.length; i++) {
            if (oa === this.state.basket.articles[i].id) {
                return parseInt(this.state.basket.articles[i].quantity);
            }
        }
    }

    changeQuantity = (oa, qty, user) => {
        for (let i = 0; i < this.state.basket.articles.length; i++) {
            if (oa === this.state.basket.articles[i].id) {
                this.state.basket.articles[i].quantity = qty;
                if (user) {
                    if (!this.state.repartition[oa]) {
                        this.state.repartition[oa] = {};
                    }
                    this.state.repartition[oa][user] = qty;
                }
                this.forceUpdate();
            }
        }
    }

    calculateSubTotal() {
        let subtotal = 0;
        for (let i = 0; i < this.state.basket.articles.length; i++) {
            subtotal += parseInt(this.state.basket.articles[i].article.priceTtc) * parseInt(this.state.basket.articles[i].quantity);
        }
        return subtotal / 100;
    }

    calculateSubTotalWithPromo() {
        let subtotal = 0;
        for (let i = 0; i < this.state.basket.articles.length; i++) {
            subtotal += parseInt(this.state.basket.articles[i].article.priceTtc) * parseInt(this.state.basket.articles[i].quantity);
        }
        subtotal -= this.state.basket.appliedPromo;
        return subtotal / 100;
    }

    getBasket() {
        if (this.isFetchingBasket) { return; }
        this.isFetchingBasket = true;
        let request = new Request(process.env.REACT_APP_API_URL + '/client/basket', { method: 'GET', credentials: 'include' });
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            this.isFetchingBasket = false;
            this.setState({ basket: data });
            this.loading = false;
            if (Object.keys(data).length > 0) {
                for (let i = 0; i < data?.articles?.length; i++) {
                    if (data.articles[i].user) {
                        this.setQuantity(data.articles[i].id, data.articles[i].user, data.articles[i].quantity);
                    }
                    if (data.articles[i].firstBox) {
                        this.setBox(data.articles[i].id, data.articles[i].firstBox.article.reference);
                        this.setBoxInfos(data.articles[i].firstBox.article.reference, data.articles[i].firstBox.article.name)
                    }
                }
                if (this.state.basket.explanation) {
                    let exp = this.state.basket.explanation
                    let expSplit = exp.split('//')
                    for (let i in expSplit) {
                        if (expSplit[i].startsWith('PROMOCODE=')) {
                            this.setState({ promo: expSplit[i].split('=')[1], hasPromo: true })
                        }
                    }
                }
                this.getDeliveryOption()
            }
            this.basket = (Object.keys(data).length > 0);
            this.forceUpdate();
        })
    }

    formValid() {
        let validate = true;
        validate = validate && (this.state.basket.articles.length > 0);
        for (let i = 0; i < this.state.basket.articles.length; i++) {
            validate = validate && (this.state.basket.articles[i].quantity > 0);
            if (this.state.basket.articles[i].article.needChooseBox) {
                validate = validate && this.state.boxes[this.state.basket.articles[i].id] && this.state.boxes[this.state.basket.articles[i].id] !== '';
            }
            if (this.state.repartition[this.state.basket.articles[i].id]) {
                validate = validate && (Object.keys(this.state.repartition[this.state.basket.articles[i].id]).length > 0);
            } else {
                if (this.state.basket.articles[i].article.type !== "other") {
                    validate = false;
                }
            }
        }
        return validate;
    }

    getUsers() {
        let users = [];
        users.push({
            id: this.state.user.id,
            fullName: this.state.user.firstName + " " + this.state.user.lastName,
            firstName: this.state.user.firstName,
            birthDate: "Né le " + moment(this.state.user.birthDate.date).format("DD/MM/YYYY")
        })

        for (let i = 0; i < this.state.children.length; i++) {
            users.push({
                id: this.state.children[i].user.id,
                fullName: this.state.children[i].user.firstName + " " + this.state.children[i].user.lastName,
                firstName: this.state.children[i].user.firstName,
                birthDate: "Né le " + moment(this.state.children[i].user.birthDate.date).format("DD/MM/YYYY")
            })
        }

        return users
    }

    deleteArticle = (oa) => {
        this.state.basket.articles = this.state.basket.articles.filter(article => article.id !== oa);
        this.forceUpdate();
    }

    displayBasket() {
        let basket = [];
        for (let i = 0; i < this.state.basket.articles.length; i++) {
            let needChooseBox = false;
            let canChangeQuantity = true;
            let article = this.state.basket.articles[i].article;


            if (article.needChooseBox) {
                needChooseBox = article.needChooseBox
            }

            let infos = [];
            if (article.duration && article.type === "commitment") {
                canChangeQuantity = false
                infos.push("Durée : " + article.duration + " mois")
            }
            infos.push(
                article.description
            )

            basket.push(
                <div key={i} style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                    <CardTemplateBasket
                        title={article.name}
                        price={article.priceTtc / 100}
                        unitPrice={article.unitPriceTtc ? article.unitPriceTtc / 100 : undefined}
                        quantity={this.state.basket.articles[i].quantity}
                        stock={article.stock}
                        isComm={article.isCommitment}
                        infos={infos}
                        for={article.user}
                        users={this.getUsers()}
                        icon={faCartArrowDown}
                        fetchJsonOrError={this.fetchJsonOrError.bind(this)}
                        oaid={this.state.basket.articles[i].id}
                        userId={this.state.basket.articles[i].user}
                        img={this.state.basket.articles[i].article.media.length > 0 ? this.state.basket.articles[i].article.media[0] : undefined}
                        setQuantity={this.setQuantity.bind(this)}
                        cleanQuantity={this.cleanQuantity.bind(this)}
                        changeQuantity={this.changeQuantity.bind(this)}
                        getQuantity={this.getQuantity.bind(this)}
                        getDeliveryOption={this.getDeliveryOption.bind(this)}
                        canChangeQuantity={canChangeQuantity}
                        needChooseBox={needChooseBox}
                        setBox={this.setBox.bind(this)}
                        getBox={this.getBox.bind(this)}
                        setBoxInfos={this.setBoxInfos.bind(this)}
                        getBoxInfos={this.getBoxInfos.bind(this)}
                        type={article.type}
                        dependencies={this.state.basket.articles[i].dependencies}
                        fromDependency={this.state.basket.articles[i].fromDependency}
                        dispatch={this.props.dispatch.bind(this)}
                        orderId={this.state.basket.id}
                        childOAid={this.state.basket.articles[i].firstBox ? this.state.basket.articles[i].firstBox.id : null}
                        reference={this.state.basket.articles[i].article.reference}
                        userSelected={this.state.basket.articles[i].user}
                        onDelete={this.deleteArticle.bind(i)}
                    />
                </div>
            )
        }

        return basket;
    }

    validate = () => {
        this.setState({ loadingNextStep: true })
        const cookieData = {};
        let data = new FormData();
        data.append('json', JSON.stringify({ repartition: this.state.repartition, basket: this.state.basket, boxes: this.state.boxes }));
        let request = new Request(process.env.REACT_APP_API_URL + '/client/basket/update/' + this.state.basket.id, { method: 'POST', body: data, credentials: 'include' });

        this.state.basket.articles?.forEach(item => {

            const articleRef = item.article.reference;

            const cookieKey = this.state.boxes[item.id] ? `${articleRef}-${this.state.boxes[item.id]}` : articleRef;

            cookieData[cookieKey] = (cookieData[cookieKey] || 0) + item.quantity;
        });

        cookies.set('basketQuantity', JSON.stringify(cookieData), cookieOptions);
        cookies.set('lastchanger', "client", cookieOptions);

        this.fetchJsonOrError(request, (data) => {
            if (data["updated"]) {
                this.setState({ loadingNextStep: false })
                this.setState({ shouldNavigate: true, navigateTo: '/delivery/' + encode(this.state.basket.id) });
            }
            this.setState({ loadingNextStep: false })
        })
    }

    getPromo() {
        let request = new Request(process.env.REACT_APP_API_URL + '/client/basket', { method: 'GET', credentials: 'include' });
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({ basket: { ...this.state.basket, explanation: data.explanation, appliedPromo: data.appliedPromo } });
            if (Object.keys(data).length > 0) {
                if (this.state.basket.explanation) {
                    let exp = this.state.basket.explanation
                    let expSplit = exp.split('//')
                    for (let i in expSplit) {
                        if (expSplit[i].startsWith('PROMOCODE=')) {
                            this.setState({ promo: expSplit[i].split('=')[1], hasPromo: true })
                        }
                    }
                } else {
                    this.setState({ promo: null, hasPromo: false });
                }
            }
            this.forceUpdate();
        })
    }

    applyPromo = () => {
        let data = new FormData();
        data.append('json', JSON.stringify({ promo: this.state.promo }));
        let request = new Request(process.env.REACT_APP_API_URL + '/client/basket/' + this.state.basket.id + '/promo', { method: 'POST', body: data, credentials: 'include' });
        this.fetchJsonOrError(request, (data) => {
            if (data["applied"]) {
                this.getPromo();
            } else {
                this.setState({ errorPromo: true })
            }
        })
    }

    denyPromo = () => {
        let request = new Request(process.env.REACT_APP_API_URL + '/client/basket/' + this.state.basket.id + '/promo/delete', { method: 'POST', credentials: 'include' });
        this.fetchJsonOrError(request, (data) => {
            this.getPromo()
        })
    }

    renderChild() {
        if (this.loading) {
            if (!this.isFetchingBasket) {
                this.getBasket();
            }
            if (!this.isFetchingCountries) {
                this.getCountries();
            }
        }

        return (
            <div>
                <Bandeau title={titleAriane["basket"]["title"]} />
                <FilAriane
                    links={[
                        [
                            titleAriane["basket"]["ariane"][0],
                            "/basket"
                        ]
                    ]}
                />
                {this.loading ? <div className="loader"><div></div></div>
                    :
                    this.basket && this.state?.basket?.articles?.length > 0 ?
                        <>
                            <div className={globalStyles.headerStep}>
                                <div className={globalStyles.headernbArticlePrice}>
                                    <div className={globalStyles.headerStepPrice}>
                                        Sous-total : <DisplayPrice price={this.calculateSubTotal()} />
                                    </div>
                                </div>
                                <div className={globalStyles.tooltipContainer}>
                                    <button className={`${globalStyles.btnNextStep} ${this.formValid() ? globalStyles.defaultBtn : formStyles.disabledBtn}`}
                                        onClick={() => this.validate()} disabled={!this.formValid()}
                                        data-tip={!this.formValid() ? "Choisis la box de ton abonnement pour continuer ta commande" : ""}
                                    >
                                        {this.state.loadingNextStep ? <div className={globalStyles.loaderNextStep}></div> : <div>Passer la commande</div>}
                                    </button>
                                </div>
                            </div>
                            <div className={globalStyles.globalContainer} style={{ flexDirection: "column", alignItems: "unset", padding: window.innerWidth > 960 ? "unset" : "10px" }}>

                                {localStorage.getItem('fromValidateBasket') === "true" ?
                                    <>
                                        <Step state="green" text="Aperçu du panier" />
                                        <Step state="green" text="Inscrirption / Connexion" />
                                    </>
                                    : ""}
                                <div className={globalStyles.containerStepAndBasket}>
                                    <Step text="Contenu de la commande" />
                                    <div className={globalStyles.basketContainer}>
                                        {this.displayBasket()}

                                        <div style={{ display: "flex", flexDirection: window.innerWidth > 1020 ? "row" : "column", marginBottom: "10px" }}>
                                            <div
                                                className={globalStyles.card}
                                                style={{
                                                    padding: "30px",
                                                    flex: "0.7 1 0%",
                                                    backgroundColor: "#f3f6f8",
                                                    boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                                    borderRadius: "5px",
                                                    display: "flex",
                                                    boxSizing: "border-box",
                                                    flexDirection: "column",
                                                    marginRight: window.innerWidth > 1020 ? "10px" : undefined,
                                                    marginBottom: "10px",
                                                    marginTop: "10px"
                                                }}
                                            >
                                                {this.state.hasPromo ?
                                                    <div>
                                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                                            <div style={{ flex: 1 }}>
                                                                <h3 style={{ textAlign: "left", color: "#6FA939", letterSpacing: "1px" }}>
                                                                    <FontAwesomeIcon icon={faCheckCircle} style={{ marginRight: "10px", fontSize: "1.5em", position: "relative", bottom: "-3px" }} />
                                                                    {this.state.promo}
                                                                </h3>
                                                            </div>
                                                            <div style={{ flex: 1 }}>
                                                                <h2 style={{ textAlign: "right" }}>
                                                                    -<DisplayPrice price={this.state.basket.appliedPromo / 100} />
                                                                </h2>
                                                            </div>
                                                        </div>

                                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end", textAlign: "left", marginTop: "10px" }}>
                                                            <button className={globalStyles.defaultLink} onClick={this.denyPromo}>
                                                                Supprimer
                                                            </button>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className={StyleResume.containerPromoPrice}>
                                                        <div className={formStyles.inputLine} style={{ marginBottom: "10px" }}>
                                                            <div className={formStyles.inputGroup} style={{ marginLeft: 0, marginRight: window.innerWidth > 1020 ? undefined : 0 }}>
                                                                <div className={formStyles.inputField} style={{ borderColor: this.state.errorPromo ? "#CE3534" : "#ced4da" }}>
                                                                    <div className={formStyles.inputIcon} style={{ flex: 2 }}>
                                                                        <FontAwesomeIcon icon={faTag} />
                                                                    </div>
                                                                    <input
                                                                        type="text"
                                                                        required
                                                                        className={formStyles.input}
                                                                        placeholder="Code promo"
                                                                        value={this.state.promo ?? ""}
                                                                        onChange={(event) => this.setState({ ...this.state, promo: event.target.value.toUpperCase(), errorPromo: false })}
                                                                    />
                                                                </div>
                                                            </div>


                                                            <div style={{
                                                                display: "flex",
                                                                alignItems: "flex-end",
                                                                justifyContent: window.innerWidth > 1020 ? "flex-end" : "center",
                                                                paddingBottom: "10px"
                                                            }}>
                                                                <button
                                                                    className={globalStyles.defaultBtn}
                                                                    style={{ fontSize: "1rem" }}
                                                                    onClick={this.applyPromo}
                                                                >
                                                                    Appliquer
                                                                </button>
                                                            </div>
                                                        </div>
                                                        {this.state.hasPromo ?
                                                            <>
                                                                <div className={StyleResume.nombreArticle}>
                                                                    {this.getNombreArticle()}
                                                                </div>
                                                                <h2 style={{ textAlign: "right", marginTop: "1rem" }}>
                                                                    Sous-total : <DisplayPrice price={this.calculateSubTotal()} strike={true} /> <DisplayPrice price={this.calculateSubTotalWithPromo()} color={"#6FA939"} />
                                                                </h2>
                                                            </>
                                                            :
                                                            <>
                                                                <div className={StyleResume.nombreArticle}>
                                                                    {this.getNombreArticle()}
                                                                </div>
                                                                <h2 style={{ textAlign: "right", marginTop: "1rem" }}>
                                                                    Sous-total : <DisplayPrice price={this.calculateSubTotal()} />
                                                                </h2>
                                                            </>
                                                        }
                                                    </div>
                                                }
                                            </div>

                                            <div
                                                className={globalStyles.card}
                                                style={{
                                                    padding: "30px",
                                                    flex: 1,
                                                    backgroundColor: "#f3f6f8",
                                                    boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                                    borderRadius: "5px",
                                                    boxSizing: "border-box",
                                                    flexDirection: "column",
                                                    display: "flex",
                                                    justifyContent: window.innerWidth > 1020 ? "flex-end" : "center",
                                                    marginLeft: window.innerWidth > 1020 ? "10px" : undefined,
                                                    marginBottom: "10px",
                                                    marginTop: "10px"
                                                }}
                                            >
                                                <div>
                                                    <div className={style.titleFraiLivraison}>
                                                        <h3 style={{ margin: "0px 0px 15px" }}>Estimation de la livraison</h3>
                                                        <div>
                                                            <select
                                                                className={style.customSelect}
                                                                value={this.selectedCountry}
                                                                onChange={this.handleCountryChange}
                                                            >
                                                                <option value="" disabled>
                                                                    Choisis un pays
                                                                </option>
                                                                {this.countries.map((country) => (
                                                                    <option key={country} value={country}>
                                                                        {country}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className={style.sousTexte}>
                                                        Selon le mode de livraison choisi, voilà une estimation des frais qui seront appliqués pour ce panier.
                                                    </div>
                                                </div>

                                                <div className={style.priceLivraison}>
                                                    <div className={style.retrait}>
                                                        <div className={StyleResume.containerTextIcon}>
                                                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                                                            Retrait en magasin
                                                        </div>
                                                        <span className={globalStyles.badge}
                                                            style={{ backgroundColor: "#6fa939" }}>
                                                            Offert
                                                        </span>
                                                    </div>
                                                    <div className={style.retrait}>
                                                        <div className={StyleResume.containerTextIcon}>
                                                            <FontAwesomeIcon icon={faStore} />
                                                            Livraison en point relais</div>
                                                        <div>
                                                            {" "}
                                                            {this.priceDisplay("relay")}
                                                        </div>
                                                    </div>
                                                    <div className={style.retrait}>
                                                        <div className={StyleResume.containerTextIcon}>
                                                            <FontAwesomeIcon icon={faTruck} />
                                                            Livraison à domicile</div>
                                                        <div>
                                                            {" "}
                                                            {this.priceDisplay("home")}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Step state="grey" text="Mode de livraison" image="livraison" btnNext={true} loadingNextStep={this.state.loadingNextStep} disabledBtn={!this.formValid()} onValidate={this.validate} errorMessage="Choisis la box de ton abonnement pour continuer ta commande" />
                                <Step state="grey" text="Paiement" image="paiement" />
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                    className={globalStyles.btnBottomPage}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flex: 1,
                                            justifyContent: "flex-start",
                                            alignItems: "flex-start",
                                            textAlign: "left",
                                            marginTop: "10px",
                                            marginRight: window.innerWidth < 1020 ? "5px" : undefined,
                                        }}
                                    >
                                        <a href={process.env.REACT_APP_SHOP_URL} className={globalStyles.secondaryBtn}>
                                            Retour à la boutique
                                        </a>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flex: 1,
                                            justifyContent: "flex-end",
                                            alignItems: "flex-end",
                                            textAlign: "left",
                                            marginTop: "10px",
                                            marginLeft: window.innerWidth < 1020 ? "5px" : undefined,
                                        }}
                                    >
                                        <div className={globalStyles.tooltipContainer}>
                                            <button
                                                onClick={() => this.validate()}
                                                className={`${globalStyles.btnNextStep} ${this.formValid() ? globalStyles.defaultBtn : formStyles.disabledBtn}`}
                                                disabled={!this.formValid()}
                                                data-tip={!this.formValid() ? "Choisis la box de ton abonnement pour continuer ta commande" : ""}
                                            >
                                                {this.state.loadingNextStep ? <div className={globalStyles.loaderNextStep}></div> : <div>Passer la commande</div>}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>

                        :
                        <div className={globalStyles.globalContainer} style={{ flexDirection: "column", alignItems: "unset", padding: window.innerWidth > 960 ? "unset" : "10px" }}>
                            <div className={globalStyles.emptyBasket}>
                                <div>
                                    <img src={AtoRikaTristes} alt="" />
                                </div>
                                <div>
                                    <h2>Hum, ton panier est vide !</h2>
                                    <div>
                                        Ton panier est là pour servir.<br />Remplis-le pour faire plaisir à Ato et Rika...
                                    </div>
                                    <a
                                        href={process.env.REACT_APP_SHOP_URL}
                                        className={globalStyles.defaultBtn}
                                        style={{ border: "1px solid #571088" }}
                                    >
                                        Aller à la boutique
                                    </a>
                                </div>
                            </div>
                        </div>
                }
            </div>
        );
    }
}

export default connect(mapStateToProps)(ResumeBasket);
