import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChild,
    faCity,
    faGlobeEurope,
    faIdBadge,
    faMailBulk,
    faMapMarkerAlt,
    faPhone,
    faQuestionCircle
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import LoggedComponent, {mapStateToProps} from "../../Services/ConnexionService";
import FilAriane from "../Header_Footer/FilAriane";
import globalStyles from "../../Styles/Style.module.css";
import formStyles from '../../Styles/FormStyle.module.css';
import {connect} from "react-redux";
import Bandeau from "../Header_Footer/Bandeau";
import titleAriane from "../../Helpers/TitleAriane.json";

class AddAddress extends LoggedComponent {

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            id: props.id,
            lastName: '',
            firstName: '',
            name: '',
            type: 2,
            address: '',
            address2: '',
            postalCode: '',
            city: '',
            country: 'France',
            phone: '',
            errors : {
                phone: false,
            },
            countries: undefined,
        };
        this.loadingCountries = true;
    }

    doesMustLog = () => {
        this.mustLog = 1
    }

    getCountries() {
        let request = new Request(process.env.REACT_APP_API_URL + '/client/countries', {method:'GET', credentials:'include'});
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({countries: data.countries})
            this.loadingCountries = false;
            this.forceUpdate();
        })
    }

    create = () => {
        let data = new FormData();
        data.append('json', JSON.stringify({...this.state}));
        let request = new Request(process.env.REACT_APP_API_URL + '/client/user/address/new', {method:'POST', body: data, credentials:'include'});
        this.fetchJsonOrError(request, (data) => {
            if(data["added"]){
                this.setState({shouldNavigate: true, navigateTo: '/address'});
            }
        })

    }


    renderChild() {
        if(this.loadingCountries) this.getCountries()

        const formValid = () => {
            let infoShipping = (this.state.lastName.length > 0) && (this.state.firstName.length > 0) && (this.state.address.length > 0) && (this.state.city.length > 0) && (this.state.country.length > 0) && (!this.state.errors.phone);
            return infoShipping;
        }

        return (
            <div>
                <Bandeau title={titleAriane["newAddress"]["title"]}/>
                <FilAriane
                    links={[
                        [
                            titleAriane["newAddress"]["ariane"][0],
                            "/"
                        ],
                        [
                            titleAriane["newAddress"]["ariane"][1],
                            "/address"
                        ],
                        [
                            titleAriane["newAddress"]["ariane"][2],
                            ""
                        ]
                    ]}
                />

                <div className={globalStyles.globalContainer}>
                    <div style={{flex: 1}}>
                        <div className={formStyles.inputLine}>
                            <div style={{margin: "10px 20px", flex: 1}}>

                                Type* :

                                <div style={{flex: 1, display: "flex", flexDirection: window.innerWidth > 1020 ? "row" : "col"}}>
                                    <div className={formStyles.inputGroup}>
                                        <input type="radio" name="type" value="delivery" style={{marginRight: "10px"}} onChange={() => {this.setState({type: 0})}}/>
                                        <label>
                                            Livraison
                                        </label>
                                    </div>

                                    <div className={formStyles.inputGroup}>
                                        <input type="radio" name="type" value="biling" style={{marginRight: "10px"}} onChange={() => {this.setState({type: 1})}}/>
                                        <label>
                                            Facturation
                                        </label>
                                    </div>

                                    <div className={formStyles.inputGroup}>
                                        <input type="radio" name="type" value="both" style={{marginRight: "10px"}} onChange={() => {this.setState({type: 2})}} checked={this.state.type === 2}/>
                                        <label>
                                            Les deux
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroup}>
                                <label>
                                    Nom de l'adresse*
                                    <FontAwesomeIcon
                                        className={formStyles.iconHelp}
                                        icon={faQuestionCircle}
                                        tabIndex={-1}
                                        data-tip="Afin de mieux te y retrouver, tu peux donner un nom à cette adresse."
                                    />
                                </label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faMapMarkerAlt}/>
                                    </div>
                                    <input type="text" required className={formStyles.input}
                                           placeholder="Nom de l'adresse" value={this.state.name}
                                           onChange={(event) => this.setState({name: event.target.value})}
                                    />

                                </div>
                            </div>
                        </div>

                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroup}>
                                <label>
                                    Nom*
                                    <FontAwesomeIcon
                                        className={formStyles.iconHelp}
                                        icon={faQuestionCircle}
                                        data-tip="A qui sera adressé le colis ?"
                                        tabIndex={-1}
                                    />
                                </label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faIdBadge}/>
                                    </div>
                                    <input type="text" required className={formStyles.input}
                                           placeholder="Nom du destinataire" value={this.state.lastName}
                                           onChange={(event) => this.setState({lastName: event.target.value.toUpperCase()})}/>

                                </div>
                            </div>

                            <div className={formStyles.inputGroup}>
                                <label>
                                    Prénom*
                                    <FontAwesomeIcon
                                        className={formStyles.iconHelp}
                                        icon={faQuestionCircle}
                                        data-tip="A qui sera adressé le colis ?"
                                        tabIndex={-1}
                                    />
                                </label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faChild}/>
                                    </div>
                                    <input type="text" required className={formStyles.input}
                                           placeholder="Prénom du destinataire" value={this.state.firstName}
                                           onChange={(event) => this.setState({firstName: event.target.value})}/>
                                </div>
                            </div>
                        </div>

                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroup}>
                                <label>
                                    Adresse*
                                    <FontAwesomeIcon
                                        className={formStyles.iconHelp}
                                        icon={faQuestionCircle}
                                        data-tip={this.state.type === 0 || this.state.type === 2 ? "Où sera livré le colis ?" : "A quelle adresse sera adressée la facture ?"}
                                        tabIndex={-1}
                                    />
                                </label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faMapMarkerAlt}/>
                                    </div>
                                    <input type="text" required className={formStyles.input}
                                           placeholder="Adresse" value={this.state.address}
                                           onChange={(event) => this.setState({address: event.target.value})}/>
                                </div>
                            </div>

                            {this.state.type !== 1 &&
                                <div className={formStyles.inputGroup}>
                                    <label>
                                        Complément d'adresse
                                        <FontAwesomeIcon
                                            className={formStyles.iconHelp}
                                            icon={faQuestionCircle}
                                            data-tip="Cela permettera de faciliter la livraison."
                                            tabIndex={-1}
                                        />
                                    </label>
                                    <div className={formStyles.inputField}>
                                        <div className={formStyles.inputIcon}>
                                            <FontAwesomeIcon icon={faMapMarkerAlt}/>
                                        </div>
                                        <input type="text" required className={formStyles.input}
                                               placeholder="Étage, Bâtiment .." value={this.state.address2}
                                               onChange={(event) => this.setState({address2: event.target.value})}/>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroup}>
                                <label>
                                    Code postal
                                </label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faMailBulk}/>
                                    </div>
                                    <input type="text" maxLength={5} required className={formStyles.input}
                                           placeholder="00000" value={this.state.postalCode}
                                           onChange={(event) => this.setState({postalCode: event.target.value})}/>
                                </div>
                            </div>

                            <div className={formStyles.inputGroup}>
                                <label>Ville*</label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faCity}/>
                                    </div>
                                    <input type="text" required className={formStyles.input} placeholder="Ville"
                                           value={this.state.city}
                                           onChange={(event) => this.setState({city: event.target.value})}/>
                                </div>
                            </div>
                        </div>

                        <div className={formStyles.inputLine}>
                            <div className={formStyles.inputGroup}>
                                <label>
                                    Pays
                                    {this.state.type !== 1 &&
                                        <FontAwesomeIcon
                                            className={formStyles.iconHelp}
                                            icon={faQuestionCircle}
                                            data-tip={"Nous ne livrons que dans les pays suivants : " + this.state.countries?.map(country => country.label)?.join(',')}
                                            tabIndex={-1}
                                        />
                                    }
                                </label>
                                <div className={formStyles.inputField}>
                                    <div className={formStyles.inputIcon}>
                                        <FontAwesomeIcon icon={faGlobeEurope}/>
                                    </div>

                                    {this.state.type === 1 ?
                                        <input type="text" required className={formStyles.input}
                                               placeholder="Pays" value={this.state.country}
                                               onChange={(event) => this.setState({country: event.target.value})}/>
                                        :
                                        <select value={this.state.country} className={formStyles.input} onChange={(event) => this.setState({country: event.target.value})}>
                                            {
                                                this.state.countries?.map(country =>
                                                    <option value={country.label}>
                                                        {country.label}
                                                    </option>
                                                )
                                            }
                                        </select>
                                    }

                                </div>
                            </div>

                            <div className={formStyles.inputGroup}>
                                {this.state.type !== 1 &&
                                    <div>
                                        <label>
                                            Numéro de téléphone
                                            <FontAwesomeIcon
                                                className={formStyles.iconHelp}
                                                icon={faQuestionCircle}
                                                data-tip="Cela permettera de faciliter la livraison."
                                                tabIndex={-1}
                                            />
                                        </label>
                                        <div className={formStyles.inputField}>
                                            <div className={formStyles.inputIcon}>
                                                <FontAwesomeIcon icon={faPhone}/>
                                            </div>
                                            <input
                                                type="tel"
                                                required
                                                className={formStyles.input}
                                                placeholder="0600000000"
                                                value={this.state.phone}
                                                pattern={
                                                    this.state.countries?.map(country =>
                                                        country.phoneRegex
                                                    )?.join('|')
                                                }
                                                onChange={(event) => this.setState({phone: event.target.value, errors: {...this.state.errors, phone: (event.target.validity.patternMismatch && event.target.value !== '')}})}
                                            />
                                        </div>

                                        {this.state.errors.phone &&
                                            <span className={formStyles.textDanger}>Le numéro de téléphone doit être valide.</span>
                                        }
                                    </div>
                                }

                            </div>
                        </div>

                        <div className={formStyles.btnContainer} style={{marginTop: "20px"}}>
                            <button type="button" className={formValid() ? globalStyles.defaultBtn : formStyles.disabledBtn} onClick={() =>{this.create()}} disabled={!formValid()}>
                                Enregistrer
                            </button>
                        </div>

                    </div>
                </div>
                <ReactTooltip multiline={true}/>
            </div>
        );
    };

}

export default connect(mapStateToProps)(AddAddress);
