import React from "react"
import moment from "moment";
import LoggedComponent, { mapStateToProps } from "../../Services/ConnexionService";
import globalStyles from '../../Styles/Style.module.css';
import {
    faBan,
    faBox,
    faBoxOpen,
    faSpinner,
    faTruck
} from "@fortawesome/free-solid-svg-icons";
import CardTemplate from "../Card/CardTemplate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilAriane from "../Header_Footer/FilAriane";
import { connect } from "react-redux";
import Bandeau from "../Header_Footer/Bandeau";
import CardTemplateStatus from "./CardTemplateStatus";
import styles from "../Profile/Profile.module.css";
import CardTemplateDelivery from "./CardTemplateDelivery";
import { encode } from "../../Services/ObfuscatorService";
import CardTemplateContent from "./CardTemplateContent";

class Tracking extends LoggedComponent {

    constructor(props) {
        super(props);
        this.state = {
            tracking: undefined
        }
        this.loading = true;
    }

    doesMustLog = () => {
        this.mustLog = 1
    }

    getTracking() {
        let request = new Request(process.env.REACT_APP_API_URL + '/client/delivery/orderdelivery/' + this.props.id + "/tracking", { method: 'GET', credentials: 'include' });
        fetch(request).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({ tracking: data });
            this.loading = false;
            this.forceUpdate();
        })
    }

    displayContent() {
        let articles = [];
        for (let i = 0; i < this.state.tracking.articles.length; i++) {
            articles.push(
                <CardTemplateContent
                    img={this.state.tracking.articles[i].article.media[0]}
                    title={this.state.tracking.articles[i].article.name}
                    description={this.state.tracking.articles[i].article.description}
                    quantity={this.state.tracking.articles[i].quantity}
                />
            )
        }
        return articles
    }

    displayTracking() {
        let trackings = [];
        for (let i = 0; i < this.state.tracking.tracking.length; i++) {
            trackings.push(
                <div style={{ width: "100%", display: "flex", flexDirection: "row", margin: "10px" }}>
                    <CardTemplate
                        icon={
                            this.state.tracking.tracking[i].etatLetter === "LIV" ?
                                faBox :
                                this.state.tracking.tracking[i].etatLetter === "ANL" ?
                                    faBan : faTruck
                        }
                        title={moment(this.state.tracking.tracking[i].date.date).format("DD/MM/YYYY")}
                        infos={[
                            this.state.tracking.tracking[i].etat,
                            this.state.tracking.tracking[i].localisation,
                            this.state.tracking.tracking[i].text
                        ]}
                        links={[]}
                    />
                </div>
            )
        }

        if (this.state.tracking.tracking.length === 0) {
            trackings.push(
                <div style={{ width: "100%", display: "flex", flexDirection: "row", margin: "10px" }}>
                    <CardTemplate
                        icon={faSpinner}
                        title={"Patience ..."}
                        infos={[
                            "Ton colis a été expédié. Tu auras bientôt plus d'informations ici concernant son suivi."
                        ]}
                        links={[]}
                    />
                </div>
            )
        }

        return trackings
    }

    renderChild() {

        return (
            <div>
                <Bandeau title={this.state.tracking?.number ? "Colis " + this.state.tracking.number : "Colis"} />

                <FilAriane
                    links={[
                        [
                            "Ton espace",
                            "/"
                        ],
                        [
                            "Tes commandes",
                            "/commands"
                        ],
                        [
                            "Commande " + (this.state.tracking?.identifierOrder ? this.state.tracking.identifierOrder : ""),
                            "/command/" + encode(this.state.tracking?.idOrder) + "/view"
                        ],
                        [
                            "Suivi du colis",
                            ""
                        ],
                    ]}
                />

                {this.loading ? (
                    <>
                        {this.getTracking()}
                        <div className="loader"><div></div></div>
                    </>)
                    :

                    <div className={globalStyles.globalContainer} style={{ flexDirection: "column", padding: window.innerWidth > 960 ? "unset" : "10px" }}>

                        <div className={styles.cardContainer} style={{ flexWrap: "wrap", margin: window.innerWidth > 1020 ? "0 10px" : "10px 10px 0" }}>
                            <div style={{ flex: 1, marginRight: window.innerWidth > 1020 ? "10px" : 0, marginTop: "10px", marginBottom: "10px" }}>
                                <CardTemplateStatus
                                    title={"Enregistré"}
                                    color={"#52BAEC"}
                                    isDone={this.state.tracking.dateSaved !== null || ((7 > this.state.tracking.state) && (this.state.tracking.state >= 0))}
                                    bgcolor={this.state.tracking.dateSaved !== null || ((7 > this.state.tracking.state) && (this.state.tracking.state >= 0)) ? "white" : undefined}
                                    date={this.state.tracking.dateSaved ? this.state.tracking.dateSaved.date : undefined}
                                />
                            </div>
                            {window.innerWidth < 1020 && <div className={styles.break} />}
                            <div
                                style={{
                                    flex: 1,
                                    marginRight: window.innerWidth > 1020 ? "10px" : 0,
                                    marginLeft: window.innerWidth > 1020 ? "10px" : 0,
                                    marginTop: "10px", marginBottom: "10px"
                                }}>
                                <CardTemplateStatus
                                    title={"Préparé"}
                                    color={"#F4bc00"}
                                    isDone={this.state.tracking.datePrepared !== null || ((7 > this.state.tracking.state) && (this.state.tracking.state >= 1))}
                                    bgcolor={this.state.tracking.datePrepared !== null || ((7 > this.state.tracking.state) && (this.state.tracking.state >= 1)) ? "white" : undefined}
                                    date={this.state.tracking.datePrepared ? this.state.tracking.datePrepared.date : undefined}
                                />
                            </div>
                            {window.innerWidth < 1020 && <div className={styles.break} />}
                            <div
                                style={{
                                    flex: 1,
                                    marginLeft: window.innerWidth > 1020 ? "10px" : 0,
                                    marginTop: "10px", marginBottom: "10px"
                                }}>
                                <CardTemplateStatus
                                    title={"Expédié"}
                                    color={"#f4bc00"}
                                    isDone={this.state.tracking.dateShipped !== null || ((7 > this.state.tracking.state) && (this.state.tracking.state >= 2))}
                                    bgcolor={this.state.tracking.dateShipped !== null || ((7 > this.state.tracking.state) && (this.state.tracking.state >= 2)) ? "white" : undefined}
                                    date={this.state.tracking.dateShipped ? this.state.tracking.dateShipped.date : undefined}
                                />
                            </div>
                        </div>


                        {/*{this.displayTracking()}*/}
                        <div className={styles.cardContainer} style={{ flexWrap: "wrap", margin: "10px -10px" }}>
                            <CardTemplateDelivery
                                isDone={(this.state.tracking.carrier && this.state.tracking.carrier_reference) || this.state.tracking.dateDelivered}
                                title={this.state.tracking.dateDelivered || (this.state.tracking.state === 3) ? "Livré" : "Livraison"}
                                color={"#6fa939"}
                                date={this.state.tracking.dateDelivered ? this.state.tracking.dateDelivered.date : undefined}
                                tracking={this.state.tracking.tracking}
                                bgcolor={this.state.tracking.dateDelivered || (this.state.tracking.state === 3) ? "white" : undefined}
                                carrier={this.state.tracking.carrier}
                                carrierReference={this.state.tracking.carrier_reference}
                            />
                        </div>

                        <div className={styles.cardContainer}>
                            <div
                                className={globalStyles.card}
                                style={{
                                    padding: "30px",
                                    flex: 1,
                                    backgroundColor: "#f3f6f8",
                                    boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                    borderRadius: "5px",
                                    display: "flex",
                                    height: "100%",
                                    boxSizing: "border-box",
                                    flexDirection: "column",
                                }}
                            >
                                <div>
                                    <h2 style={{ margin: "auto 0", flex: 2 }}>
                                        <FontAwesomeIcon icon={faBoxOpen} style={{ marginRight: "15px" }} />
                                        Contenu du colis
                                    </h2>
                                </div>
                                {this.displayContent()}
                            </div>
                        </div>
                    </div>
                }
            </div>

        );


    }
}

export default connect(mapStateToProps)(Tracking);
